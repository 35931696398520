import React from 'react';
import { Skeleton, Space } from 'antd';
import { useIntl } from 'react-intl';

// Constants
import { AppColors } from '../../../constants/Utils/Colors';
import { roundUp } from '../../../constants/Utils/Math';

// Models
import { VisitorMonitorContent } from '../../../models/Visits/Dashboard/VisitorMonitorContent';

// Components
import { Flex } from '../../UI/Base';
import { Translated } from '../../UI/Core';
import { DashboardIcon } from '../../UI/Icon/DashboardIcon';
import { ButtonSkeleton } from '../../UI/Skeleton/ButtonSkeleton';
import { DashboardProgress } from '../../UI/Progress/DashboardProgress';
import { LineProgress } from '../../UI/Progress/LineProgress';

// Props
interface VisitorMonitorProps {
  content: VisitorMonitorContent | undefined;
}

// Component
export const VisitorMonitor = ({ content }: VisitorMonitorProps) => {
  // Hooks
  const intl = useIntl();

  // State
  const { total = 0, checkins = 0, checkouts = 0 } = content || {};
  const arrived = checkins + checkouts;
  const expected = total - arrived;

  // Components
  const getTooltipItem = (value: number, label: string, icon: string, color?: string) => (
    <Space>
      <DashboardIcon className={`icon icon-${icon}`} style={{ display: 'flex' }} />
      <span>
        <strong style={{ color }}>{roundUp(value)}</strong> {label}
      </span>
    </Space>
  );

  // Visitors
  const percentVisitors = total ? 100 : 0;
  const successVisitors = (arrived * 100) / total;
  const visitorsTooltip = (
    <Space direction="vertical">
      {getTooltipItem(
        expected,
        intl.formatMessage({ id: 'visitors.dashboard.monitor.expected' }),
        'calendar',
        AppColors.cyan
      )}
      {getTooltipItem(
        arrived,
        intl.formatMessage({ id: 'visitors.dashboard.monitor.arrived' }),
        'select',
        AppColors.lime
      )}
    </Space>
  );

  // Presence
  const percentCheckins = total ? 100 : 0;
  const successCheckins = (checkins * 100) / arrived;
  const checkingsTooltip = (
    <Space direction="vertical">
      {getTooltipItem(
        checkins,
        intl.formatMessage({ id: 'visitors.dashboard.monitor.present' }),
        'contacts',
        AppColors.orange
      )}
      {getTooltipItem(
        checkouts,
        intl.formatMessage({ id: 'visitors.dashboard.monitor.left' }),
        'signin',
        AppColors.lime
      )}
    </Space>
  );

  // Summary
  const percentSummary = (arrived * 100) / total;
  const successSummary = (checkouts * 100) / total;
  const expectedPercent = (expected * 100) / total;
  const checkinsPercent = (checkins * 100) / total;
  const summaryTooltip = (
    <Space direction="vertical">
      {getTooltipItem(
        expected,
        `${intl.formatMessage({ id: 'visitors.dashboard.monitor.expected' })} ~ ${roundUp(expectedPercent)}%`,
        'calendar',
        AppColors.cyan
      )}
      {getTooltipItem(
        checkins,
        `${intl.formatMessage({ id: 'visitors.dashboard.monitor.present' })} ~ ${roundUp(checkinsPercent)}%`,
        'contacts',
        AppColors.orange
      )}
      {getTooltipItem(
        checkouts,
        `${intl.formatMessage({ id: 'visitors.dashboard.monitor.left' })} ~ ${roundUp(successSummary)}%`,
        'signin',
        AppColors.lime
      )}
    </Space>
  );

  // Render Functions
  const renderCharts = () => (
    <Space direction="vertical">
      <Space size={12}>
        {renderVisitors()}
        {renderPresence()}
      </Space>
      <Flex alignItems="center">{renderTotal()}</Flex>
    </Space>
  );

  const renderVisitors = () => (
    <DashboardProgress
      label={intl.formatMessage({ id: 'visitors.dashboard.monitor.total' })}
      value={total.toString()}
      percent={percentVisitors}
      success={{ percent: successVisitors, strokeColor: AppColors.lime }}
      strokeColor={AppColors.cyan}
      tooltip={{ content: visitorsTooltip, placement: 'left' }}
    />
  );

  const renderPresence = () => (
    <DashboardProgress
      label={intl.formatMessage({ id: 'visitors.dashboard.monitor.present' })}
      value={arrived && checkins ? `${checkins}` : '0'}
      percent={percentCheckins}
      success={{ percent: successCheckins, strokeColor: AppColors.orange }}
      strokeColor={AppColors.lime}
      tooltip={{ content: checkingsTooltip, placement: 'right' }}
    />
  );

  const renderTotal = () => (
    <LineProgress
      percent={percentSummary}
      success={{ percent: successSummary, strokeColor: AppColors.lime }}
      strokeColor={AppColors.orange}
      trailColor={AppColors.cyan}
      tooltip={{ content: summaryTooltip, placement: 'bottom' }}
      empty={!total}
    />
  );

  const renderEmpty = () => (
    <Space direction="vertical">
      <Space size={12}>
        <Skeleton.Avatar active size={120} />
        <Skeleton.Avatar active size={120} />
      </Space>
      <Flex alignItems="center" className="gx-mt-1 gx-mb-1">
        <ButtonSkeleton width={250} height={14} />
      </Flex>
    </Space>
  );

  // Render
  return (
    <div className="ant-col gx-audi-col ant-col-xs-24 ant-col-sm-12 ant-col-md-12 ant-col-lg-12 ant-col-xl-6">
      <div className="gx-site-dash gx-mb-2 gx-pt-3 gx-pt-sm-0 gx-pt-xl-2">
        <h6 className="gx-text-uppercase gx-mb-2 gx-mb-sm-4">
          <Translated id="visitors.dashboard.monitor.title" />
        </h6>
        <ul className="gx-line-indicator">{content ? renderCharts() : renderEmpty()}</ul>
      </div>
    </div>
  );
};
