import thunk from 'redux-thunk';
import { History } from 'history';
import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import { reducers } from '../../store/Store.redux';
import { runSagas } from '../../store/Store.sagas';
import { configureReduxEvents } from '../../store/Store.signalr';
import { signalRMiddleware } from '../SignalR/SignalRMiddleware';
import { configureSignalR } from '../SignalR/SignalRService';

export const configureReduxStore = (history: History, preloadedState?: any) => {
  const sagaMiddleware = createSagaMiddleware();

  const store = configureStore({
    reducer: reducers,
    middleware: [thunk, sagaMiddleware, signalRMiddleware(history)],
    preloadedState,
  });

  runSagas(sagaMiddleware);

  configureSignalR({ store, history, configureReduxEvents });

  return store;
};
