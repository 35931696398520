/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Models
import { SignalRAction } from '../../models/SignalRAction';
import { ExecuteAction } from '../../models/Action';

// State
interface ActionsState {
  updating: boolean;
  error: boolean;
}

const initialState: ActionsState = {
  updating: false,
  error: false,
};

// Name
const storeName = '@ACTIONS';

// Redux Actions|Reducers
const actionsSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    // Execute Action
    executeAction: (state, _action: PayloadAction<ExecuteAction>) => {
      state.updating = true;
      state.error = false;
    },
    actionExecuted: (state, _action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    actionNotExecuted: (state, _action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },
  },
});

// Export Actions
export const {
  // Execute Action
  executeAction,
  actionExecuted,
  actionNotExecuted,
} = actionsSlice.actions;

// Export Reducer
export default actionsSlice.reducer;
