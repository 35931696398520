import React from 'react';
import { Col, Row } from 'antd';

// Hooks
import { useParams } from 'react-router';
import { useEmployeeDetail } from '../../hooks/Employees/useEmployeeDetail';

// Components
import { Container } from '../../components/UI/Base';
import { AccessProfiles, ContactCard, FormDrawer, Identifiers, ProfileHeader } from '../../components/Employees';
import { Spinner } from '../../components/UI/Spinner/Spinner';
import { BreadcrumbMenu } from '../../components/UI/Breadcrumb/BreadcrumbMenu';
import { ActionsCard } from '../../components/Employees/ActionsCard';

export const EmployeeDetailPage = () => {
  const { id } = useParams();
  const {
    updating,
    getBreadcrumbMenuProps,
    getProfileHeaderProps,
    getContactCardProps,
    getFormDrawerProps,
    getEmployeeProps,
    getActionsCardProps,
  } = useEmployeeDetail({ id });

  return (
    <Spinner spinning={updating}>
      <BreadcrumbMenu {...getBreadcrumbMenuProps()} />

      <ProfileHeader {...getProfileHeaderProps()} />

      <Container className="gx-profile-content">
        <Row>
          <Col xl={16} lg={14} md={14} sm={24} xs={24}>
            <Col>
              <Identifiers {...getEmployeeProps()} />
            </Col>
            <Col>
              <AccessProfiles {...getEmployeeProps()} />
            </Col>
          </Col>

          <Col xl={8} lg={10} md={10} sm={24} xs={24}>
            <Col>
              <ContactCard {...getContactCardProps()} />
            </Col>
            <Col>
              <ActionsCard {...getActionsCardProps()} />
            </Col>
          </Col>
        </Row>
      </Container>

      <FormDrawer {...getFormDrawerProps()} />
    </Spinner>
  );
};
