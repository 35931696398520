/* eslint-disable no-nested-ternary */
import React, {useMemo} from 'react';
import {Badge, Col, Empty, Row, Skeleton, Tabs} from 'antd';
import styled from 'styled-components';
import {isEmpty, orderBy} from 'lodash';

// Models
import {CheckCircleOutlined} from '@ant-design/icons';
import {Visitor} from '../../../models/Visits/Visitor';
import {IdentifierType} from '../../../models/IdentifierType';

// Hooks
import {useVisitorIdentifiers} from '../../../hooks/Visitors/VisitorIdentifiers/useVisitorIdentifiers';

// Components
import {Widget} from '../../UI/Widget/Widget';
import {Spinner} from '../../UI/Spinner/Spinner';
import {Translated} from '../../UI/Core';
import {AppColors} from '../../../constants/Utils/Colors';
import {VisitorIdentifierForm} from './VisitorIdentifierForm';
import {GuestStatus} from '../../../models/enums/GuestStatus';

// Styled
const StyledWidget = styled(Widget)`
  & .ant-card-body {
    padding: 0;
  }
`;
const StyledTabs = styled(Tabs)`
  & .ant-tabs-nav-wrap {
    margin-left: 24px;
  }
  & .ant-tabs-nav {
    height: 58px;
  }
  & .ant-tabs-content {
    padding: 0px 24px 16px;
  }
  & .ant-tabs-tab {
    & .ant-tabs-tab-btn {
      & .ant-badge {
        color: inherit;
      }
      & .is-checked-in {
        color: ${AppColors.orange};
        font-weight: 600;
      }
    }
  }
`;

// Props
interface VisitorIdentifierProps {
  visitor: Visitor | null;
  hasVisits: boolean;
  fetchVisitor: (params: any) => Promise<void>;
}

// Component
export const VisitorIdentifiers = ({ visitor, fetchVisitor, hasVisits }: VisitorIdentifierProps) => {
  // Identifiers Hook
  const { identifierTypes, loading, updating, defaultActiveKey } = useVisitorIdentifiers({
    visitor,
    fetchVisitor,
  });

  const isCheckedIn = useMemo(() => {
    const guests = visitor?.Visits.map((visit) => visit.Guests.find((x) => x.Id === visitor.Id));
    return guests?.some((x) => x?.Status === GuestStatus.CheckedIn) ?? false;
  }, [visitor]);

  // Count Helper
  const getIdentifierCount = (identifierType: IdentifierType) =>
    visitor?.Identifiers.filter((identifier) => identifier.IdentifierType.Id === identifierType.Id).length ?? 0;

  // Data
  const orderedTypes = orderBy(
    identifierTypes,
    (identifierType) =>
      (isCheckedIn && identifierType.DefaultForCheckIn) ||
      (getIdentifierCount(identifierType) && identifierType.DefaultForCheckIn),
    'desc'
  );

  // Render Methods
  const renderLoading = () => [
    {
      key: '0',
      label: <Translated id="app.loading" />,
      children: (
        <Row>
          <Col xl={8} lg={12} md={12} sm={12} xs={24}>
            <Skeleton active />
          </Col>
        </Row>
      ),
    },
  ];

  const renderEmpty = () => [
    {
      key: '0',
      label: <Translated id="identifiers.form.tab" />,
      children: (
        <Row>
          <Col xs={24}>
            <Empty
              description={
                <span>
                  <Translated id="identifiers.form.empty" />
                </span>
              }
            />
          </Col>
        </Row>
      ),
    },
  ];

  const renderForm = () =>
    orderedTypes.map((identifierType) => {
      const isIdentifierCheckedIn = identifierType.DefaultForCheckIn && isCheckedIn;
      const visitorIdentifier = visitor?.Identifiers.find((x) => x.IdentifierType.Id === identifierType.Id) ?? null;

      return {
        key: identifierType.Id,
        label: (
          <Badge color="green" size="small" count={getIdentifierCount(identifierType)} offset={[8, 0]}>
            <span className={isIdentifierCheckedIn ? 'is-checked-in' : undefined}>
              {isIdentifierCheckedIn && <CheckCircleOutlined />}
              {identifierType.Name}
            </span>
          </Badge>
        ),
        children: (
          <Spinner spinning={updating}>
            <VisitorIdentifierForm
              visitor={visitor}
              identifier={visitorIdentifier}
              identifierType={identifierType}
              hasVisits={hasVisits}
              isCheckedIn={isCheckedIn}
              updating={updating}
            />
          </Spinner>
        ),
      };
    });

  const renderItems = () => {
    if (loading) return renderLoading();
    if (!identifierTypes || isEmpty(identifierTypes)) return renderEmpty();
    return renderForm();
  };

  // Component Render
  return (
    <StyledWidget styleName="gx-card-widget">
      <StyledTabs items={renderItems()} defaultActiveKey={defaultActiveKey} />
    </StyledWidget>
  );
};
