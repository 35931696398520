import { useMemo } from 'react';

// Hooks
import { useReduxLocation } from './useLocation';

// Components
import { AppLocale } from '../../constants/AppLanguages/AppLocale';
import { useAppSelector } from './useRedux';

export const useAppSettings = () => {
  useReduxLocation();
  const locale = useAppSelector(({ appSettings }) => appSettings?.locale);
  const currentAppLocale = AppLocale[locale?.locale ?? 'en'];

  return useMemo(
    () => ({
      currentAppLocale,
    }),
    [currentAppLocale]
  );
};
