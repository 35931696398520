// Types
import { SignalREvent } from '../../models/SignalREvent';

// Actions
import {
  visitCreated,
  visitDeleted,
  visitEmailStatusNotUpdated,
  visitEmailStatusUpdated,
  visitNotCreated,
  visitNotDeleted,
  visitNotUpdated,
  visitsDeleted,
  visitsNotDeleted,
  visitStatusNotUpdated,
  visitStatusUpdated,
  visitUpdated,
} from './Visits.redux';

// Events
const SignalREvents = {
  visitCreated: `VisitCreatedIntegrationEvent`,
  visitUpdated: `VisitUpdatedIntegrationEvent`,
  visitDeleted: `VisitDeletedIntegrationEvent`,
  visitsDeleted: `VisitsDeletedIntegrationEvent`,
  visitStatusUpdated: `VisitStatusUpdatedIntegrationEvent`,
  visitEmailStatusUpdated: `VisitEmailStatusUpdatedIntegrationEvent`,
};

// Exceptions
const SignalRExceptions = {
  visitNotCreated: `VisitNotCreatedIntegrationException`,
  visitNotUpdated: `VisitNotUpdatedIntegrationException`,
  visitNotDeleted: `VisitNotDeletedIntegrationException`,
  visitsNotDeleted: `VisitsNotDeletedIntegrationException`,
  visitStatusNotUpdated: `VisitStatusNotUpdatedIntegrationException`,
  visitEmailStatusNotUpdated: `VisitEmailStatusNotUpdatedIntegrationException`,
};

// Events
export const configureSignalREvents = ({ hubConnection, store, history }: SignalREvent) => {
  // Create
  hubConnection.on(SignalREvents.visitCreated, (msg) => {
    store.dispatch(visitCreated({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.visitNotCreated, (msg) => {
    store.dispatch(visitNotCreated({ history, msg }));
  });

  // Update
  hubConnection.on(SignalREvents.visitUpdated, (msg) => {
    store.dispatch(visitUpdated({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.visitNotUpdated, (msg) => {
    store.dispatch(visitNotUpdated({ history, msg }));
  });

  // Delete
  hubConnection.on(SignalREvents.visitDeleted, (msg) => {
    store.dispatch(visitDeleted({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.visitNotDeleted, (msg) => {
    store.dispatch(visitNotDeleted({ history, msg }));
  });

  // Delete All
  hubConnection.on(SignalREvents.visitsDeleted, (msg) => {
    store.dispatch(visitsDeleted({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.visitsNotDeleted, (msg) => {
    store.dispatch(visitsNotDeleted({ history, msg }));
  });

  // Update Status
  hubConnection.on(SignalREvents.visitStatusUpdated, (msg) => {
    store.dispatch(visitStatusUpdated({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.visitStatusNotUpdated, (msg) => {
    store.dispatch(visitStatusNotUpdated({ history, msg }));
  });

  // Update Email Status
  hubConnection.on(SignalREvents.visitEmailStatusUpdated, (msg) => {
    store.dispatch(visitEmailStatusUpdated({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.visitEmailStatusNotUpdated, (msg) => {
    store.dispatch(visitEmailStatusNotUpdated({ history, msg }));
  });
};
