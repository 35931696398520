import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'antd';
import styled from 'styled-components';

// Props
import { AssignConfirmProps } from './useVisitorAccessProfilesReduxState';

// Models
import { AccessProfile } from '../../../models/AccessProfile';
import { Visitor } from '../../../models/Visits/Visitor';

// Components
import { Translated } from '../../../components/UI/Core';
import { MultiSelect, MultiSelectItem } from '../../../components/UI/MultiSelect/MultiSelect';

// Props
interface VisitorAccessProfilesFormProps {
  visitor: Visitor | null;
  accessProfiles: Array<AccessProfile> | null;
  showAssignConfirm: (props: AssignConfirmProps) => void;
}

// Styled
const StyledButton = styled(Button)`
  margin-bottom: 0;
`;

// Hook
export const useVisitorAccessProfilesForm = ({
  visitor,
  accessProfiles,
  showAssignConfirm,
}: VisitorAccessProfilesFormProps) => {
  // State
  const [accessProfileIds, setAccessProfileIds] = useState<Array<string>>([]);

  // Get the Keys (Id) from all Companies of a Location
  useEffect(() => {
    setAccessProfileIds(
      accessProfiles
        ?.filter((accessProfile) =>
          visitor?.AccessProfiles?.some((visitorAccessProfile) => visitorAccessProfile.Id === accessProfile.Id)
        )
        ?.map((x) => x.Id) ?? []
    );
  }, [accessProfiles, visitor]);

  // Components
  const VisitorAccessProfilesManagementForm = useCallback(() => {
    // Get all Companies as MultiSelectItems
    const dataSource =
      accessProfiles?.map<MultiSelectItem>((company) => ({
        key: company.Id,
        title: company.Name,
        description: '',
      })) ?? [];

    return (
      <>
        <MultiSelect dataSource={dataSource} targetKeys={accessProfileIds} setTargetKeys={setAccessProfileIds} />
        <StyledButton
          type="primary"
          onClick={() =>
            visitor &&
            showAssignConfirm({
              formValues: {
                PersonId: visitor?.Id,
                AccessProfileIds: accessProfileIds,
              },
              visitor,
            })
          }
        >
          <Translated id="form.confirmButton" />
        </StyledButton>
      </>
    );
  }, [accessProfiles, visitor, showAssignConfirm, accessProfileIds, setAccessProfileIds]);

  return useMemo(
    () => ({
      AccessProfilesManagementForm: VisitorAccessProfilesManagementForm,
    }),
    [VisitorAccessProfilesManagementForm]
  );
};
