import moment from 'moment';
import { MomentInput } from '../../constants/Utils/DateTime';
import { GuestStatus } from '../enums/GuestStatus';

import { GuestVisit } from './GuestVisit';
import { Host } from './Host';

export const defaultVisit: VisitFormValues = {
  Id: '',
  Title: '',
  Description: '',
  ValidFrom: moment().isAfter(moment().hour(17).minutes(0).seconds(0).local())
    ? moment()
    : moment().hour(9).minutes(0).seconds(0).local(),
  ValidTo: moment().isAfter(moment().hour(17).minutes(0).seconds(0).local())
    ? moment().add(4, 'hours')
    : moment().hour(17).minutes(0).seconds(0).local(),
  Hosts: [],
  Guests: [
    {
      Id: '',
      FullName: '',
      FirstName: '',
      Prefix: '',
      LastName: '',
      Email: '',
      Status: GuestStatus.Created,
      EmailSent: false,
      CompanyName: '',
      PhoneNumber: '',
      Identifiers: [],
    },
  ],
};

export interface VisitFormValues {
  Id: string;
  Title: string;
  Description: string;
  ValidFrom: MomentInput;
  ValidTo: MomentInput;
  Guests: Array<GuestVisit>;
  Hosts: Array<Host>;
}
