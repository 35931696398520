import React from 'react';
import { Skeleton, Space, StepProps, Steps, Tag, Tooltip } from 'antd';
import { CarryOutOutlined, CheckCircleOutlined, LogoutOutlined } from '@ant-design/icons';
import styled from 'styled-components';

// Models
import { Visit } from '../../../../models/Visits/Visit';
import { GuestStatus } from '../../../../models/enums/GuestStatus';

// Constants
import { AppColors } from '../../../../constants/Utils/Colors';
import { getLocalDateTimeString } from '../../../../constants/Utils/DateTime';

// Components
import { Translated } from '../../../UI/Core';
import { GuestVisit } from '../../../../models/Visits/GuestVisit';

// Redux

// Styled
const StepDescription = styled.span`
  font-size: 12px;
`;

// Props
interface VisitStepsProps {
  visit?: Visit;
  guest?: GuestVisit;
}

// Component
export const VisitSteps = ({ visit, guest }: VisitStepsProps) => {
  if (!visit) return <Skeleton />;
  if (!guest) return <Skeleton />;

  // Visit Steps
  const getVisitSteps: () => Array<StepProps & { key: GuestStatus }> = () => [
    // Created
    {
      key: GuestStatus.Created,
      title: (
        <span style={{ color: guest.Status === GuestStatus.Created ? AppColors.cyan : undefined }}>
          <Translated id="visits.info.createdTitle" />
        </span>
      ),
      description: (
        <StepDescription>
          {visit.Guests ? (
            <Space direction="vertical">
              <Translated id="visits.info.created" />
              {visit.Guests.map((x) => (
                <Space align="center" key={x.Id}>
                  <Tooltip
                    title={
                      <Translated id={x.EmailSent ? 'visits.info.invitationSent' : 'visits.info.invitationNotSent'} />
                    }
                  >
                    <Tag style={{ margin: 0 }} color={x.EmailSent ? 'success' : 'warning'}>
                      {x.Email}
                    </Tag>
                  </Tooltip>
                </Space>
              ))}
              {visit.Hosts.map((x) => (
                <Space align="center" key={x.Id}>
                  <Tooltip
                    title={
                      <Translated id={x.EmailSent ? 'visits.info.invitationSent' : 'visits.info.invitationNotSent'} />
                    }
                  >
                    <Tag style={{ margin: 0 }} color={x.EmailSent ? 'success' : 'warning'}>
                      {x.Email}
                    </Tag>
                  </Tooltip>
                </Space>
              ))}
            </Space>
          ) : (
            <Translated id="visits.info.noInvitationsSent" />
          )}
        </StepDescription>
      ),
      icon: <CarryOutOutlined style={{ color: guest.Status === GuestStatus.Created ? AppColors.cyan : undefined }} />,
    },

    // CheckedIn
    {
      key: GuestStatus.CheckedIn,
      title: (
        <span style={{ color: guest.Status === GuestStatus.CheckedIn ? AppColors.orange : undefined }}>
          <Translated id="visits.info.checkedInTitle" />
        </span>
      ),
      description: (
        <StepDescription>
          <Translated id="visits.info.checkedIn" /> {getLocalDateTimeString(visit.ValidFrom)}
        </StepDescription>
      ),
      icon: (
        <CheckCircleOutlined style={{ color: guest.Status === GuestStatus.CheckedIn ? AppColors.orange : undefined }} />
      ),
    },

    // CheckedOut
    {
      key: GuestStatus.CheckedOut,
      title: (
        <span style={{ color: guest.Status === GuestStatus.CheckedOut ? AppColors.green : undefined }}>
          <Translated id="visits.info.checkedOutTitle" />
        </span>
      ),
      description: (
        <StepDescription>
          <Translated id="visits.info.checkedOut" /> {getLocalDateTimeString(visit.ValidTo)}
        </StepDescription>
      ),
      icon: <LogoutOutlined style={{ color: guest.Status === GuestStatus.CheckedOut ? AppColors.green : undefined }} />,
    },
  ];

  // Get Step Items
  const visitSteps = getVisitSteps();

  // Render Steps
  return (
    <Steps
      direction="vertical"
      size="small"
      current={visitSteps.findIndex((x) => x.key === guest.Status)}
      items={visitSteps}
    />
  );
};
