import React from 'react';
import { Layout } from 'antd';
import { useDispatch } from 'react-redux';

// Redux
import { useAppSelector } from '../../../hooks/App/useRedux';
import { openSidebar } from '../../../store/AppSettings/AppSettings.redux';

// Hooks
import { useTenantData } from '../../../hooks/App/useTenantData';

// Components
import { LanguageSwitcher } from '../LanguageMenu/LanguageSwitcher';
import { UserMenu } from '../UserMenu/UserMenu';
import { Logo } from '../AppLogo/AppLogo';
import { Container } from '../../UI/Base';
import { useAuth } from '../../../hooks/App/useAuth';
import { AppMenu } from '../AppMenu/AppMenu';

export const AppHeader = () => {
  // Tenant Data Hook
  const { getTenantDataProps } = useTenantData();

  const dispatch = useDispatch();
  const { sidebarOpen } = useAppSelector(({ appSettings }) => appSettings);

  // Auth Hook
  const {
    authState: { isInitialized, isAuthenticated },
  } = useAuth();

  return (
    <div className="gx-header-horizontal gx-header-horizontal-dark gx-inside-header-horizontal">
      <div className="gx-header-horizontal-top">
        <Container>
          <div className="gx-header-horizontal-top-flex">
            {/* Logo */}
            <div className="gx-header-horizontal-top-left">
              <Logo {...getTenantDataProps()} />
            </div>

            {/* User Menu */}
            <ul className="gx-header-notifications gx-ml-auto">
              <UserMenu {...getTenantDataProps()} />
            </ul>
          </div>
        </Container>
      </div>

      {isInitialized && isAuthenticated && (
        <Layout.Header className="gx-header-horizontal-main">
          <Container>
            <div className="gx-header-horizontal-main-flex">
              {/* Mobile Menu Button */}
              <div className="gx-d-block gx-d-lg-none gx-linebar gx-mr-xs-3 6e">
                <i
                  className="gx-icon-btn icon icon-menu"
                  onClick={() => dispatch(openSidebar(!sidebarOpen))}
                  role="presentation"
                />
              </div>

              {/* Menu Options */}
              <div className="gx-header-horizontal-nav gx-header-horizontal-nav-curve gx-d-none gx-d-lg-block gx-ml-1">
                <AppMenu />
              </div>

              {/* Action Buttons */}
              <ul className="gx-header-notifications gx-ml-auto">
                <LanguageSwitcher />
              </ul>
            </div>
          </Container>
        </Layout.Header>
      )}
    </div>
  );
};
