import React, { useCallback, useEffect, useMemo } from 'react';
import { usePrevious } from 'react-use';

// Hooks
import { uniqueId } from 'lodash';
import { useAppSelector } from '../../App/useRedux';
import { useData } from '../../App/useData';

// Models
import { VisitorMonitorContent } from '../../../models/Visits/Dashboard/VisitorMonitorContent';
import { VisitorDashboardDataTypes } from '../../../models/Visits/Dashboard/VisitorDashboardDataTypes';

// Components
import { Translated } from '../../../components/UI/Core';
import { DashboardIcon } from '../../../components/UI/Icon/DashboardIcon';

// Data
import { ApiEndpoints } from '../../../data/ApiEndpoints';
import { VisitorStatsContent } from '../../../components/Visitors/Dashboard/VisitorStats';
import { VisitorStat } from '../../../models/Visits/Dashboard/VisitorDashboardResponse';
import { AppColors } from '../../../constants/Utils/Colors';

const getStatColor = (period: string) => {
  switch (period) {
    case VisitorDashboardDataTypes.ThisWeek:
      return AppColors.green;
    case VisitorDashboardDataTypes.NextWeek:
      return AppColors.cyan;
    case VisitorDashboardDataTypes.LastWeek:
      return AppColors.orange;
    default:
      return undefined;
  }
};
const getStatLabel = (period: string) => {
  switch (period) {
    case VisitorDashboardDataTypes.ThisWeek:
      return <Translated id="visitors.dashboard.stats.thisWeek" />;
    case VisitorDashboardDataTypes.NextWeek:
      return <Translated id="visitors.dashboard.stats.nextWeek" />;
    case VisitorDashboardDataTypes.LastWeek:
      return <Translated id="visitors.dashboard.stats.lastWeek" />;
    case VisitorDashboardDataTypes.ThisYear:
      return <Translated id="visitors.dashboard.stats.thisYear" />;
    default:
      return <span />;
  }
};
const getStatIcon = (period: string) => {
  switch (period) {
    case VisitorDashboardDataTypes.ThisWeek:
      return <DashboardIcon className="icon icon-contacts" />;
    case VisitorDashboardDataTypes.NextWeek:
      return <DashboardIcon className="icon icon-calendar" />;
    case VisitorDashboardDataTypes.LastWeek:
      return <DashboardIcon className="icon icon-select" />;
    case VisitorDashboardDataTypes.ThisYear:
      return <DashboardIcon className="icon icon-avatar" />;
    default:
      return <span />;
  }
};
const getStat: (item: VisitorStat) => VisitorStatsContent = (item) => {
  return {
    id: uniqueId(),
    value: item.Count,
    icon: getStatIcon(item.Period),
    label: getStatLabel(item.Period),
    color: getStatColor(item.Period),
  };
};

// Hook
export const useVisitorDashboard = () => {
  // Redux
  const { updating } = useAppSelector(({ visitors }) => visitors);
  const { updating: updatingVisits } = useAppSelector(({ visits }) => visits);
  const prevUpdating = usePrevious(updating);
  const prevUpdatingVisits = usePrevious(updatingVisits);

  // Data
  const { data: dashboardData, fetch, pending } = useData(ApiEndpoints.visitors.dashboard, null);
  const { VisitorMonitor, VisitorStats, VisitorMetrics } = dashboardData || {};

  // Props
  const getVisitorStatsProps = useCallback(
    () => ({
      content: VisitorStats?.map((item) => getStat(item)),
    }),
    [VisitorStats]
  );

  const getVisitorMonitorProps = useCallback(
    () => ({
      content: (!pending && VisitorMonitor
        ? {
            total: VisitorMonitor.Total,
            checkins: VisitorMonitor.Checkins,
            checkouts: VisitorMonitor.Checkouts,
          }
        : undefined) as VisitorMonitorContent,
    }),
    [pending, VisitorMonitor]
  );

  const getVisitorMetricsProps = useCallback(
    () => ({
      visitorMetrics: VisitorMetrics,
    }),
    [VisitorMetrics]
  );

  // Effects
  useEffect(() => {
    // Fetch on initializing
    fetch();
  }, [fetch]);

  useEffect(() => {
    // Fetch after updating a visitor
    if ((prevUpdating && !updating) || (prevUpdatingVisits && !updatingVisits)) {
      fetch();
    }
  }, [prevUpdating, updating, prevUpdatingVisits, updatingVisits, fetch]);

  return useMemo(
    () => ({
      getVisitorStatsProps,
      getVisitorMetricsProps,
      getVisitorMonitorProps,
    }),
    [getVisitorStatsProps, getVisitorMetricsProps, getVisitorMonitorProps]
  );
};
