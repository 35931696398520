import React, { useCallback, useEffect, useState } from 'react';

// Settings
import { LogoutActions } from '../../../constants/Settings/AuthSettings';

// Helpers
import { getReturnUrl, navigateToUrl } from '../../../constants/Utils/Urls';

// Services
import { AuthenticationResultStatus, authService } from '../../../services/ApiAuthorization/AuthorizeService';

// Components
import { AuthSpinner } from './AuthSpinner';

// Props
interface LoginProps {
  action: string;
}

// Component
export const Logout = ({ action }: LoginProps) => {
  const [message, setMessage] = useState<string>();

  const logout = useCallback(async (returnUrl: string) => {
    const result = await authService.signOut();

    switch (result.status) {
      case AuthenticationResultStatus.Redirect:
        break;

      case AuthenticationResultStatus.Success:
        navigateToUrl(returnUrl);
        break;

      case AuthenticationResultStatus.Fail:
        setMessage(result.data);
        break;

      default:
        throw new Error('Invalid authentication result status.');
    }
  }, []);

  const processLogoutCallback = useCallback(async () => {
    const url = window.location.href;
    const result = await authService.completeSignOut(url);

    switch (result.status) {
      case AuthenticationResultStatus.Redirect:
        throw new Error('Should not redirect.');

      case AuthenticationResultStatus.Success:
        navigateToUrl(getReturnUrl(result.data));
        break;

      case AuthenticationResultStatus.Fail:
        setMessage(result.data);
        break;

      default:
        throw new Error('Invalid authentication result status.');
    }
  }, []);

  // Init
  useEffect(() => {
    // Mount
    switch (action) {
      case LogoutActions.Logout:
        if (window.history.state.idx > 0) {
          logout(getReturnUrl());
        } else {
          setMessage('The logout was not initiated from within the page.');
        }
        break;

      case LogoutActions.LogoutCallback:
        processLogoutCallback();
        break;

      default:
        throw new Error(`Invalid action '${action}'`);
    }
  }, [action, logout, processLogoutCallback]);

  if (message) {
    return <AuthSpinner message={message} />;
  }

  switch (action) {
    case LogoutActions.Logout:
      return <AuthSpinner message="Logging out..." />;
    case LogoutActions.LogoutCallback:
      return <AuthSpinner message="Successful logout, redirecting..." />;
    default:
      throw new Error(`Invalid action '${action}'`);
  }
};
