/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */

import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Employee} from '../../../models/Employee';

// Models
import {IdentifierFormValues} from '../../../models/IdentifierFormValues';
import {SignalRAction} from '../../../models/SignalRAction';

// State
interface EmployeeIdentifiersState {
  updating: boolean;
  error: boolean;
}

const initialState: EmployeeIdentifiersState = {
  updating: false,
  error: false,
};

// Name
const storeName = '@EMPLOYEE_IDENTIFIERS';

// Redux Actions|Reducers
const employeeIdentifiersSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    // Assign
    assignIdentifier: (state, action: PayloadAction<{ formValues: IdentifierFormValues; employee: Employee }>) => {
      state.updating = true;
      state.error = false;
    },
    identifierAssigned: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    identifierNotAssigned: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },
    // Remove
    removeIdentifier: (state, action: PayloadAction<{ formValues: IdentifierFormValues; employee: Employee }>) => {
      state.updating = true;
      state.error = false;
    },
    identifierRemoved: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    identifierNotRemoved: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },
    identifiersNotRemoved: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },
    // Toggle
    toggleIdentifierStatus: (
      state,
      action: PayloadAction<{ formValues: IdentifierFormValues; employee: Employee }>
    ) => {
      state.updating = true;
      state.error = false;
    },
    identifierStatusToggled: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    identifierStatusNotToggled: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },
  },
});

// Export Actions
export const {
  // Assign
  assignIdentifier,
  identifierAssigned,
  identifierNotAssigned,
  // Remove
  removeIdentifier,
  identifierRemoved,
  identifierNotRemoved,
  identifiersNotRemoved,
  // Toggle
  toggleIdentifierStatus,
  identifierStatusToggled,
  identifierStatusNotToggled,
} = employeeIdentifiersSlice.actions;

// Export Reducer
export default employeeIdentifiersSlice.reducer;
