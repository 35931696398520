import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

// Services
import { ApiRequestType, apiService } from '../../../services/ApiService/ApiService';
import { notificationService } from '../../../services/Notifications/NotificationService';

// Models
import { SignalRAction } from '../../../models/SignalRAction';

// Redux
import {
  assignIdentifier,
  identifierAssigned,
  identifierNotAssigned,
  identifierNotRemoved,
  identifierRemoved,
  identifiersNotRemoved,
  identifierStatusNotToggled,
  identifierStatusToggled,
  removeIdentifier,
  toggleIdentifierStatus,
} from './VisitorIdentifiers.redux';
import { Visitor } from '../../../models/Visits/Visitor';
import { IdentifierFormValues } from '../../../models/IdentifierFormValues';

// **************************************************
// ********************* ASSIGN *********************

// Worker Sagas
function* assignIdentifierSaga() {
  yield takeEvery(assignIdentifier.type, assignIdentifierRequest);
}

function* identifierAssignedSaga() {
  yield takeLatest(identifierAssigned.type, assignIdentifierResponse);
}

function* identifierNotAssignedSaga() {
  yield takeLatest(identifierNotAssigned.type, assignIdentifierError);
}

// Request
function* assignIdentifierRequest(action: PayloadAction<{ formValues: IdentifierFormValues; visitor: Visitor }>) {
  try {
    const {
      payload: { formValues, visitor },
    } = action;
    yield apiService.execute({
      url: `Visitors/${visitor.Id}/Identifier`,
      method: ApiRequestType.POST,
      data: formValues,
    });
  } catch ({ message }) {
    yield put({ type: identifierNotAssigned.type, payload: { msg: { message } } });
  }
}

// Response
function assignIdentifierResponse() {
  notificationService.showSuccess('identifiers.notifications.assign');
}

// Error
function assignIdentifierError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('identifiers.notifications.assignFailed', action?.payload?.msg.message);
}

// **************************************************
// ********************* REMOVE *********************

// Worker Sagas
function* removeIdentifierSaga() {
  yield takeEvery(removeIdentifier.type, removeIdentifierRequest);
}

function* identifierRemovedSaga() {
  yield takeLatest(identifierRemoved.type, removeIdentifierResponse);
}

function* identifierNotRemovedSaga() {
  yield takeLatest(identifierNotRemoved.type, removeIdentifierError);
}

function* identifiersNotRemovedSaga() {
  yield takeLatest(identifiersNotRemoved.type, removeIdentifiersError);
}

// Request
function* removeIdentifierRequest(action: PayloadAction<{ formValues: IdentifierFormValues; visitor: Visitor }>) {
  try {
    const {
      payload: { visitor, formValues },
    } = action;
    yield apiService.execute({
      url: `Visitors/${visitor.Id}/Identifier/${formValues.Identifier.IdentifierId}`,
      method: ApiRequestType.DELETE,
      data: formValues,
    });
  } catch ({ message }) {
    yield put({ type: identifierNotAssigned.type, payload: { msg: { message } } });
  }
}

// Response
function removeIdentifierResponse() {
  notificationService.showSuccess('identifiers.notifications.remove');
}

// Error
function removeIdentifierError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('identifiers.notifications.removeFailed', action?.payload?.msg.message);
}

function removeIdentifiersError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('identifiers.notifications.removeFailed', action?.payload?.msg.message);
}

// ********************* TOGGLE *********************

// Worker Sagas
function* toggleIdentifierStatusSaga() {
  yield takeEvery(toggleIdentifierStatus.type, toggleIdentifierRequest);
}

function* identifierStatusToggledSaga() {
  yield takeLatest(identifierStatusToggled.type, toggleIdentifierResponse);
}

function* identifierStatusNotToggledSaga() {
  yield takeLatest(identifierStatusNotToggled.type, toggleIdentifierError);
}

// Request
function* toggleIdentifierRequest(action: PayloadAction<{ formValues: IdentifierFormValues; visitor: Visitor }>) {
  try {
    const {
      payload: { visitor, formValues },
    } = action;
    yield apiService.execute({
      url: `Visitors/${visitor.Id}/Identifier/${formValues.Identifier.IdentifierId}`,
      method: ApiRequestType.PUT,
      data: formValues,
    });
  } catch ({ message }) {
    yield put({ type: identifierNotAssigned.type, payload: { msg: { message } } });
  }
}

// Response
function toggleIdentifierResponse() {
  notificationService.showSuccess('identifiers.notifications.toggleStatus');
}

// Error
function toggleIdentifierError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent(
    'identifiers.notifications.toggleStatusFailed',
    action?.payload?.msg.message
  );
}

// **************************************************
// ********************* EXPORT SAGAS ***************

export default function* sagas() {
  yield all([
    // Assign
    assignIdentifierSaga(),
    identifierAssignedSaga(),
    identifierNotAssignedSaga(),
    // Remove
    removeIdentifierSaga(),
    identifierRemovedSaga(),
    identifierNotRemovedSaga(),
    identifiersNotRemovedSaga(),
    // Toggle
    toggleIdentifierStatusSaga(),
    identifierStatusToggledSaga(),
    identifierStatusNotToggledSaga(),
  ]);
}
