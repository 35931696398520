import React from 'react';
import { CellProps } from 'react-table';

// Types
import { TableColumn } from '../../types/Table';

// Models
import { Visitor } from '../../models/Visits/Visitor';
import { Visit } from '../../models/Visits/Visit';

// Components
import { Translated } from '../../components/UI/Core';
import { TimeframeCell } from '../../components/Visitors/Table/TimeframeCell';
import { StatusCell } from '../../components/Visitors/Table/StatusCell';
import { StatusButton } from '../../components/Visitors/StatusButton';

// Helpers
const getFirstVisit = (visits?: CellProps<Visitor, Visit[]>) => {
  const { value } = visits || {};
  const [firstVisit] = value ?? [];
  return firstVisit;
};
const getVisitor = (visits?: CellProps<Visitor, Visit[]>) => {
  const { row } = visits || {};
  const visitor = row?.values ?? [];
  return visitor as Visitor;
};

// Table Columns
export const visitorTableColumns: Array<TableColumn<Visitor>> = [
  {
    Header: <Translated id="visits.info.status" />,
    id: 'GuestVisits[0].Status',
    accessor: 'Visits',
    disableFilters: true,
    Cell: (value) => <StatusCell visit={getFirstVisit(value)} visitor={getVisitor(value)} />,
  },
  {
    Header: <Translated id="visitors.id" />,
    id: 'Id',
    accessor: 'Id',
    isVisible: false,
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: <Translated id="visitors.fullName" />,
    id: 'FullName',
    accessor: 'FullName',
  },
  {
    Header: <Translated id="visitors.email" />,
    id: 'Email',
    accessor: 'Email',
    isVisible: false,
  },
  {
    Header: <Translated id="visitors.phone" />,
    id: 'PhoneNumber',
    accessor: 'PhoneNumber',
    disableSortBy: true,
  },
  {
    Header: <Translated id="visitors.companyName" />,
    id: 'CompanyName',
    accessor: 'CompanyName',
  },
  {
    Header: <Translated id="visits.info.time" />,
    id: 'GuestVisits[0].Visit.ValidFrom',
    accessor: 'Visits',
    disableFilters: true,
    width: 275,
    Cell: (value) => <TimeframeCell visit={getFirstVisit(value)} />,
  },
  {
    Header: <Translated id="visits.info.options" />,
    id: 'Visits.info.options',
    accessor: 'Visits',
    disableFilters: true,
    disableSortBy: true,
    Cell: (value) => <StatusButton size="small" visit={getFirstVisit(value)} visitor={getVisitor(value)} />,
  },
];
