import React, { useCallback, useEffect, useMemo } from 'react';
import { usePrevious } from 'react-use';

// Hooks
import { useData } from '../App/useData';
import { useEmployeeRedux } from './useEmployeeRedux';
import { useFormDrawer } from '../Drawer/useFormDrawer';
import { useEmployeeImport } from './EmployeeImport/useEmployeeImport';

// Data
import { ApiEndpoints } from '../../data/ApiEndpoints';
import { employeeTableColumns } from '../../data/Table/EmployeeTableColumns';

// Constants
import { LocalStorageKeys } from '../../constants/Utils/LocalStorage';
import { getTableColumns, saveHiddenTableColumns } from '../../constants/Utils/UIFunctions';

// Components
import { Translated } from '../../components/UI/Core';
import { BasicIcon } from '../../components/UI/Icon/BasicIcon';
import { appBreadcrumbs } from '../../data/Breadcrumbs/Breadcrumbs';

// Hook
export const useEmployeesList = <T extends object>() => {
  // Data
  const { data: employees, pageCount, totalCount, fetch, pending } = useData(ApiEndpoints.employees.list, null);

  // Redux
  const { formOptions, updating, error, showDeleteAllConfirm } = useEmployeeRedux<T>();

  // Form Drawer
  const { AddButton, getFormDrawerProps } = useFormDrawer<T>({ formOptions, updating, error });

  // Employee Import
  const { UploadModal, UploadButton, updating: importUpdating } = useEmployeeImport();
  const prevImportUpdating = usePrevious(importUpdating);

  // Props
  const getBreadcrumbMenuProps = useCallback(() => ({ breadcrumbs: appBreadcrumbs.employeees.list }), []);
  const getEmployeesDataProps = useCallback(
    () => ({
      storageKey: LocalStorageKeys.Employees.table.hiddenColumns,
      title: (
        <>
          <BasicIcon className="icon icon-auth-screen" style={{ marginRight: 8 }} />
          <Translated id="appMenu.employees" />
        </>
      ),
      data: employees ?? [],
      columns: getTableColumns(employeeTableColumns, LocalStorageKeys.Employees.table.hiddenColumns),
      fetchData: fetch,
    }),
    [fetch, employees]
  );
  const getEmployeesTableProps = useCallback(
    () => ({
      pending,
      updating,
      pageCount,
      totalCount,
    }),
    [pending, updating, pageCount, totalCount]
  );
  const getEmployeesCrudProps = useCallback(
    () => ({
      AddButton,
      UploadButton,
      endpoint: getFormDrawerProps().endpoint,
      showDeleteAllConfirm,
      saveHiddenTableColumns,
    }),
    [AddButton, UploadButton, getFormDrawerProps, showDeleteAllConfirm]
  );

  // Effects
  useEffect(() => {
    // Fetch after updating
    if (prevImportUpdating === true && importUpdating === false && !error) {
      fetch({});
    }
  }, [prevImportUpdating, importUpdating, error, fetch]);

  return useMemo(
    () => ({
      updating,
      UploadModal,
      getBreadcrumbMenuProps,
      getEmployeesDataProps,
      getEmployeesTableProps,
      getEmployeesCrudProps,
      getFormDrawerProps,
    }),
    [
      updating,
      UploadModal,
      getBreadcrumbMenuProps,
      getEmployeesDataProps,
      getEmployeesTableProps,
      getFormDrawerProps,
      getEmployeesCrudProps,
    ]
  );
};
