import React, { useCallback, useMemo } from 'react';
import { Modal } from 'antd';
import { IdcardOutlined } from '@ant-design/icons';

import { useIntl } from 'react-intl';

import { Visitor } from '../../../models/Visits/Visitor';
import { AccessProfileFormValues } from '../../../models/AccessProfileFormValues';

// Hooks
import { useAppDispatch, useAppSelector } from '../../App/useRedux';
import { assignAccessProfiles } from '../../../store/Visitors/VisitorAccessProfiles/VisitorAccessProfiles.redux';

// Props
export interface AssignConfirmProps {
  formValues: AccessProfileFormValues;
  visitor: Visitor;
}

// Components
const { confirm } = Modal;

// Hook
export const useVisitorAccessProfilesReduxState = () => {
  // Intl
  const intl = useIntl();

  // Redux
  const dispatch = useAppDispatch();
  const updating = useAppSelector(({ visitorsAccessProfiles }) => visitorsAccessProfiles?.updating ?? false);
  const error = useAppSelector(({ visitorsAccessProfiles }) => visitorsAccessProfiles?.error ?? false);

  // Callbacks
  const showAssignConfirm = useCallback(
    ({ formValues, visitor }: AssignConfirmProps) => {
      if (formValues) {
        confirm({
          title: intl.formatMessage({
            id: 'accessProfiles.form.confirm.assign',
          }),
          icon: <IdcardOutlined />,
          content: intl.formatMessage({
            id: 'accessProfiles.form.confirm.assignSub',
          }),
          okText: intl.formatMessage({
            id: 'app.yes',
            defaultMessage: 'Yes',
          }),
          cancelText: intl.formatMessage({
            id: 'app.no',
            defaultMessage: 'No',
          }),
          okType: 'primary',
          onOk: () => dispatch(assignAccessProfiles({ formValues, visitor })),
          onCancel: () => null,
        });
      }
    },
    [dispatch, intl]
  );

  return useMemo(
    () => ({
      updating,
      error,
      showAssignConfirm,
    }),
    [updating, error, showAssignConfirm]
  );
};
