import React, { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

// Hooks
import { useAuth } from '../../../hooks/App/useAuth';

// Constants
import { getRedirectUrl } from '../../../constants/Utils/Urls';

// Components
import { AuthSpinner } from './AuthSpinner';
import { setUserEmail, setUserRoles } from '../../../store/Account/Account.redux';
import { useAppDispatch } from '../../../hooks/App/useRedux';

// Props
interface AuthorizedRouteProps {
  path: string;
}

// Component
export const AuthorizedRoute = ({ path }: AuthorizedRouteProps) => {
  const dispatch = useAppDispatch();
  const {
    authState: { isInitialized, isAuthenticated, user, userRoles },
  } = useAuth();

  useEffect(() => {
    dispatch(setUserEmail(user?.profile?.name ?? ''));
    dispatch(setUserRoles(userRoles));
  }, [dispatch, user, userRoles]);

  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  if (!isInitialized) return <AuthSpinner message="Loading..." />;

  if (!isAuthenticated) return <Navigate to={getRedirectUrl(path)} />;

  return <Outlet />;
};
