import { useEffect, useMemo } from 'react';
import { usePrevious } from 'react-use';

// Models
import { isEmpty } from 'lodash';
import { Employee } from '../../../models/Employee';

// Hooks
import { useEmployeeIdentifiersForm } from './useEmployeeIdentifiersForm';
import { useEmployeeIdentifiersReduxState } from './useEmployeeIdentifiersReduxState';
import { useData } from '../../App/useData';

// Data
import { ApiEndpoints } from '../../../data/ApiEndpoints';

// Props
interface EmployeeIdentifiersProps {
  employee: Employee | null;
  fetchEmployee: (params: any) => Promise<void>;
  updatingEmployee: boolean;
}

export const useEmployeeIdentifiers = ({ employee, fetchEmployee, updatingEmployee }: EmployeeIdentifiersProps) => {
  // Redux State
  const { updating, error, showAssignConfirm, showRemoveConfirm, toggleIdentifier } =
    useEmployeeIdentifiersReduxState();
  const prevUpdating = usePrevious(updating);

  // Data
  const { data: identifierTypes, fetch, pending } = useData(ApiEndpoints.tenant.identifierTypes, null);

  // Submit Form
  const { IdentifierManagementForm } = useEmployeeIdentifiersForm({
    updating,
    employee,
    showAssignConfirm,
    showRemoveConfirm,
    toggleIdentifier,
  });

  // Component State
  const loading = pending || !employee;
  const defaultActiveKey = loading || !identifierTypes || isEmpty(identifierTypes) ? '0' : identifierTypes[0].Id;

  // Effects
  useEffect(() => {
    // Fetch on initializing
    fetch();
  }, [fetch]);

  useEffect(() => {
    // Fetch after updating
    if (employee && prevUpdating === true && updating === false && !error) {
      fetchEmployee({ id: employee.Id });
    }
  }, [prevUpdating, updating, error, fetchEmployee, employee]);

  // Return Hook
  return useMemo(
    () => ({
      identifierTypes,
      IdentifierManagementForm,
      loading,
      updating: updating || updatingEmployee,
      defaultActiveKey,
    }),
    [identifierTypes, IdentifierManagementForm, loading, updating, updatingEmployee, defaultActiveKey]
  );
};
