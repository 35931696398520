/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */

import {createSlice, PayloadAction} from '@reduxjs/toolkit';

// Models
import {AccessProfileFormValues} from '../../../models/AccessProfileFormValues';
import {Employee} from '../../../models/Employee';
import {SignalRAction} from '../../../models/SignalRAction';

// State
interface EmployeeAccessProfilesState {
  updating: boolean;
  error: boolean;
}

const initialState: EmployeeAccessProfilesState = {
  updating: false,
  error: false,
};

// Name
const storeName = '@EMPLOYEE_ACCESS_PROFILES';

// Redux Actions|Reducers
const employeeAccessProfilesSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    // Assign
    assignAccessProfiles: (
      state,
      action: PayloadAction<{ formValues: AccessProfileFormValues; employee: Employee }>
    ) => {
      state.updating = true;
      state.error = false;
    },
    accessProfilesAssigned: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    accessProfilesNotAssigned: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },
  },
});

// Export Actions
export const {
  // Assign
  assignAccessProfiles,
  accessProfilesAssigned,
  accessProfilesNotAssigned,
} = employeeAccessProfilesSlice.actions;

// Export Reducer
export default employeeAccessProfilesSlice.reducer;
