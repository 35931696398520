import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { uniq } from 'lodash';

// Services
import { ApiRequestType, apiService } from '../../services/ApiService/ApiService';
import { notificationService } from '../../services/Notifications/NotificationService';

// Models
import { Employee } from '../../models/Employee';
import { SignalRAction } from '../../models/SignalRAction';

// Redux
import {
  createEmployee,
  deleteEmployee,
  deleteEmployees,
  employeeCreated,
  employeeDeleted,
  employeeNotCreated,
  employeeNotDeleted,
  employeeNotUpdated,
  employeesDeleted,
  employeesNotDeleted,
  employeeUpdated,
  updateEmployee,
} from './Employees.redux';

// **************************************************
// ********************* CREATE *********************

// Worker Sagas
function* createEmployeeSaga() {
  yield takeEvery(createEmployee.type, createEmployeeRequest);
}

function* employeeCreatedSaga() {
  yield takeLatest(employeeCreated.type, createEmployeeResponse);
}

function* employeeNotCreatedSaga() {
  yield takeLatest(employeeNotCreated.type, createEmployeeError);
}

// Request
function* createEmployeeRequest(action: PayloadAction<Employee>) {
  try {
    const { payload: employee } = action;
    yield apiService.execute({
      url: 'Employees',
      method: ApiRequestType.POST,
      data: employee,
    });
  } catch ({ message }) {
    yield put({ type: employeeNotCreated.type, payload: { msg: { message } } });
  }
}

// Response
function createEmployeeResponse(action: PayloadAction<SignalRAction>) {
  notificationService.showSuccess('employees.notifications.create');
  if (!action.payload.msg.imported) {
    action.payload.history.push(`/Employees/${action.payload.msg.entityId}`);
  }
}

// Error
function createEmployeeError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('employees.notifications.createFailed', action?.payload?.msg.message);
}

// **************************************************
// ********************* UPDATE *********************

// Worker Sagas
function* updateEmployeeSaga() {
  yield takeEvery(updateEmployee.type, updateEmployeeRequest);
}

function* employeeUpdatedSaga() {
  yield takeLatest(employeeUpdated.type, updateEmployeeResponse);
}

function* employeeNotUpdatedSaga() {
  yield takeLatest(employeeNotUpdated.type, updateEmployeeError);
}

// Request
function* updateEmployeeRequest(action: PayloadAction<Employee>) {
  try {
    const { payload: employee } = action;
    yield apiService.execute({
      url: `Employees/${employee.Id}`,
      method: ApiRequestType.PUT,
      data: employee,
    });
  } catch ({ message }) {
    yield put({ type: employeeNotUpdated.type, payload: { msg: { message } } });
  }
}

// Response
function updateEmployeeResponse() {
  notificationService.showSuccess('employees.notifications.update');
}

// Error
function updateEmployeeError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('employees.notifications.updateFailed', action?.payload?.msg.message);
}

// **************************************************
// ********************* DELETE *********************

// Worker Sagas
function* deleteEmployeeSaga() {
  yield takeEvery(deleteEmployee.type, deleteEmployeeRequest);
}

function* employeeDeletedSaga() {
  yield takeLatest(employeeDeleted.type, deleteEmployeeResponse);
}

function* employeeNotDeletedSaga() {
  yield takeLatest(employeeNotDeleted.type, deleteEmployeeError);
}

// Request
function* deleteEmployeeRequest(action: PayloadAction<Employee>) {
  try {
    const { payload: employee } = action;
    yield apiService.execute({
      url: `Employees/${employee.Id}`,
      method: ApiRequestType.DELETE,
      data: employee,
    });
  } catch ({ message }) {
    yield put({ type: employeeNotDeleted.type, payload: { msg: { message } } });
  }
}

// Response
function deleteEmployeeResponse(action: PayloadAction<SignalRAction>) {
  notificationService.showSuccess('employees.notifications.delete');
  action.payload.history.push(`/Employees`);
}

// Error
function deleteEmployeeError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('employees.notifications.deleteFailed', action?.payload?.msg.message);
}

// **************************************************
// ********************* DELETE All *****************

// Worker Sagas
function* deleteEmployeesSaga() {
  yield takeEvery(deleteEmployees.type, deleteEmployeesRequest);
}

function* employeesDeletedSaga() {
  yield takeLatest(employeesDeleted.type, deleteEmployeesResponse);
}

function* employeesNotDeletedSaga() {
  yield takeLatest(employeesNotDeleted.type, deleteEmployeesError);
}

// Request
function* deleteEmployeesRequest(action: PayloadAction<Array<Employee>>) {
  try {
    const { payload: data } = action;
    yield apiService.execute({
      url: `Employees`,
      method: ApiRequestType.DELETE,
      data: { ids: uniq(data.map((item) => item.Id)) },
    });
  } catch ({ message }) {
    yield put({ type: employeesNotDeleted.type, payload: { msg: { message } } });
  }
}

// Response
function deleteEmployeesResponse() {
  notificationService.showSuccess('employees.notifications.deleteAll');
}

// Error
function deleteEmployeesError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('employees.notifications.deleteAllFailed', action?.payload?.msg.message);
}

// **************************************************
// ********************* EXPORT SAGAS ***************

export default function* sagas() {
  yield all([
    // Create
    createEmployeeSaga(),
    employeeCreatedSaga(),
    employeeNotCreatedSaga(),
    // Update
    updateEmployeeSaga(),
    employeeUpdatedSaga(),
    employeeNotUpdatedSaga(),
    // Delete
    deleteEmployeeSaga(),
    employeeDeletedSaga(),
    employeeNotDeletedSaga(),
    // Delete All
    deleteEmployeesSaga(),
    employeesDeletedSaga(),
    employeesNotDeletedSaga(),
  ]);
}
