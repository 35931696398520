import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

// Services
import { ApiRequestType, apiService } from '../../services/ApiService/ApiService';

// Models
import { Visitor } from '../../models/Visits/Visitor';

// Redux
import {
  createVisit,
  createVisitor,
  deleteVisit,
  deleteVisitor,
  updateVisit,
  updateVisitor,
  visitCreated,
  visitDeleted,
  visitNotCreated,
  visitNotDeleted,
  visitNotUpdated,
  visitorCreated,
  visitorDeleted,
  visitorNotCreated,
  visitorNotDeleted,
  visitorNotUpdated,
  visitorUpdated,
  visitUpdated,
} from './Reservations.redux';
import { Visit } from '../../models/Visits/Visit';

// RESPONSE
function emptyReservationResponse() {
  // The visitors/visits sagas publish all notifications
}

// **************************************************
// ********************* CREATE VISIT *********************

// Worker Sagas
function* createVisitSaga() {
  yield takeEvery(createVisit.type, createVisitRequest);
}

function* visitCreatedSaga() {
  yield takeLatest(visitCreated.type, emptyReservationResponse);
}

function* visitNotCreatedSaga() {
  yield takeLatest(visitNotCreated.type, emptyReservationResponse);
}

// Request
function* createVisitRequest(action: PayloadAction<Visit>) {
  try {
    const { payload: data } = action;

    yield apiService.execute({
      url: 'Reservations/visit',
      method: ApiRequestType.POST,
      data,
    });
  } catch ({ message }) {
    yield put({ type: visitNotCreated.type, payload: { msg: { message } } });
  }
}

// **************************************************
// ********************* UPDATE VISIT *********************

// Worker Sagas
function* updateVisitSaga() {
  yield takeEvery(updateVisit.type, updateVisitRequest);
}

function* visitUpdatedSaga() {
  yield takeLatest(visitUpdated.type, emptyReservationResponse);
}

function* visitNotUpdatedSaga() {
  yield takeLatest(visitNotUpdated.type, emptyReservationResponse);
}

// Request
function* updateVisitRequest(action: PayloadAction<{ visit: Visit; sendEmail: boolean }>) {
  try {
    const {
      payload: { visit, sendEmail },
    } = action;

    yield apiService.execute({
      url: `Reservations/Visit/${visit.Id}`,
      method: ApiRequestType.PUT,
      data: {
        ...visit,
        SendConfirmationEmail: sendEmail,
      },
    });
  } catch ({ message }) {
    yield put({ type: visitNotUpdated.type, payload: { msg: { message } } });
  }
}

// **************************************************
// ********************* DELETE VISIT *********************

// Worker Sagas
function* deleteVisitSaga() {
  yield takeEvery(deleteVisit.type, deleteVisitRequest);
}

function* visitDeletedSaga() {
  yield takeLatest(visitDeleted.type, emptyReservationResponse);
}

function* visitNotDeletedSaga() {
  yield takeLatest(visitNotDeleted.type, emptyReservationResponse);
}

// Request
function* deleteVisitRequest(action: PayloadAction<Visit>) {
  try {
    const { payload: data } = action;

    yield apiService.execute({
      url: `Reservations/Visit/${data.Id}`,
      method: ApiRequestType.DELETE,
      data,
    });
  } catch ({ message }) {
    yield put({ type: visitNotDeleted.type, payload: { msg: { message } } });
  }
}

// **************************************************
// ********************* CREATE VISITOR *********************

// Worker Sagas
function* createVisitorSaga() {
  yield takeEvery(createVisitor.type, createVisitorRequest);
}

function* visitorCreatedSaga() {
  yield takeLatest(visitorCreated.type, emptyReservationResponse);
}

function* visitorNotCreatedSaga() {
  yield takeLatest(visitorNotCreated.type, emptyReservationResponse);
}

// Request
function* createVisitorRequest(action: PayloadAction<Visitor>) {
  try {
    const { payload: data } = action;

    yield apiService.execute({
      url: `Reservations`,
      method: ApiRequestType.POST,
      data,
    });
  } catch ({ message }) {
    yield put({ type: visitorNotCreated.type, payload: { msg: { message } } });
  }
}

// **************************************************
// ********************* UPDATE VISITOR **************

// Worker Sagas
function* updateVisitorSaga() {
  yield takeEvery(updateVisitor.type, updateVisitorRequest);
}

function* visitorUpdatedSaga() {
  yield takeLatest(visitorUpdated.type, emptyReservationResponse);
}

function* visitorNotUpdatedSaga() {
  yield takeLatest(visitorNotUpdated.type, emptyReservationResponse);
}

// Request
function* updateVisitorRequest(action: PayloadAction<Visitor>) {
  try {
    const { payload: data } = action;
    yield apiService.execute({
      url: `Reservations/${data.Id}`,
      method: ApiRequestType.PUT,
      data,
    });
  } catch ({ message }) {
    yield put({ type: visitorNotUpdated.type, payload: { msg: { message } } });
  }
}

// **************************************************
// ********************* DELETE *********************

// Worker Sagas
function* deleteVisitorSaga() {
  yield takeEvery(deleteVisitor.type, deleteVisitorRequest);
}

function* visitorDeletedSaga() {
  yield takeLatest(visitorDeleted.type, emptyReservationResponse);
}

function* visitorNotDeletedSaga() {
  yield takeLatest(visitorNotDeleted.type, emptyReservationResponse);
}

// Request
function* deleteVisitorRequest(action: PayloadAction<Visitor>) {
  try {
    const { payload: data } = action;
    yield apiService.execute({
      url: `Reservations/${data.Id}`,
      method: ApiRequestType.DELETE,
      data,
    });
  } catch ({ message }) {
    yield put({ type: visitorNotDeleted.type, payload: { msg: { message } } });
  }
}

// **************************************************
// ********************* EXPORT SAGAS ***************

export default function* sagas() {
  yield all([
    // Create Visit
    createVisitSaga(),
    visitCreatedSaga(),
    visitNotCreatedSaga(),
    // Update Visit
    updateVisitSaga(),
    visitUpdatedSaga(),
    visitNotUpdatedSaga(),
    // Delete Visit
    deleteVisitSaga(),
    visitDeletedSaga(),
    visitNotDeletedSaga(),
    // Create Visitor
    createVisitorSaga(),
    visitorCreatedSaga(),
    visitorNotCreatedSaga(),
    // Update Visitor
    updateVisitorSaga(),
    visitorUpdatedSaga(),
    visitorNotUpdatedSaga(),
    // Delete Visitor
    deleteVisitorSaga(),
    visitorDeletedSaga(),
    visitorNotDeletedSaga(),
  ]);
}
