import { useEffect, useMemo } from 'react';
import { usePrevious } from 'react-use';

// Models
import { Visitor } from '../../../models/Visits/Visitor';

// Hooks
import { useVisitorAccessProfilesForm } from './useVisitorAccessProfilesForm';
import { useVisitorAccessProfilesReduxState } from './useVisitorAccessProfilesReduxState';
import { useData } from '../../App/useData';

// Data
import { ApiEndpoints } from '../../../data/ApiEndpoints';

// Props
interface VisitorAccessProfilesProps {
  visitor: Visitor | null;
  fetchVisitor: (params: any) => Promise<void>;
  updatingVisitor: boolean;
}

export const useVisitorAccessProfiles = ({ visitor, fetchVisitor, updatingVisitor }: VisitorAccessProfilesProps) => {
  // Redux State
  const { updating, error, showAssignConfirm } = useVisitorAccessProfilesReduxState();
  const prevUpdating = usePrevious(updating);

  // Data
  const { data: accessProfiles, fetch, pending } = useData(ApiEndpoints.tenant.accessProfiles, null);

  // Submit Form
  const { AccessProfilesManagementForm } = useVisitorAccessProfilesForm({
    visitor,
    accessProfiles,
    showAssignConfirm,
  });

  // Component State
  const loading = pending || !visitor;

  // Effects
  useEffect(() => {
    // Fetch on initializing
    fetch();
  }, [fetch]);

  useEffect(() => {
    // Fetch after updating
    if (visitor && prevUpdating === true && updating === false && !error) {
      fetchVisitor({ id: visitor.Id });
    }
  }, [prevUpdating, updating, error, fetchVisitor, visitor]);

  // Return Hook
  return useMemo(
    () => ({
      accessProfiles,
      AccessProfilesManagementForm,
      loading,
      updating: updating || updatingVisitor,
    }),
    [accessProfiles, AccessProfilesManagementForm, loading, updating, updatingVisitor]
  );
};
