import React from 'react';
import { Avatar, Col, Divider, Form, FormInstance, Input, Typography } from 'antd';
import { AuditOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import { Moment } from 'moment';
import { isArray } from 'lodash';

// eslint-disable-next-line import/no-extraneous-dependencies
import { RangeValue } from 'rc-picker/lib/interface';

// Hooks
import { useVisitHostSelect } from '../../../../hooks/Visitors/useVisitHostSelect';

// Models
import { VisitFormValues } from '../../../../models/Visits/VisitFormValues';
import { Host } from '../../../../models/Visits/Host';

// Components
import { Flex } from '../../../UI/Base';
import { Translated } from '../../../UI/Core';
import { FormColumn } from '../../../UI/Form/FormColumn';
import { SearchSelect } from '../../../UI/Select/SearchSelect';
import { DateTimePicker } from '../../../UI/RangePicker/DateTimePicker';

// Components
const { Title } = Typography;

// Props
export interface VisitFormFieldsProps<T> {
  form: FormInstance<T>;
  initialValues?: VisitFormValues;
}

// Component
export const VisitFormFields = <T extends object>({ form, initialValues }: VisitFormFieldsProps<T>) => {
  // Intl
  const intl = useIntl();

  // Visit Form
  const renderDetails = () => (
    <>
      <Col>
        <Form.Item
          name="Title"
          label={<Translated id="visits.form.title" />}
          rules={[{ required: true, message: intl.formatMessage({ id: 'visits.form.title.warning' }) }]}
        >
          <Input placeholder={intl.formatMessage({ id: 'visits.form.title.placeholder' })} />
        </Form.Item>
      </Col>
      <Col>
        <Form.Item name="Description" label={<Translated id="visits.form.description" />}>
          <Input placeholder={intl.formatMessage({ id: 'visits.form.description.placeholder' })} />
        </Form.Item>
      </Col>
    </>
  );

  const renderHosts = () => (
    <Col>
      <Form.Item name="Hosts" label={<Translated id="visits.form.host" />}>
        <SearchSelect
          selectOptionsProvider={useVisitHostSelect}
          initialValue={initialValues?.Hosts?.map((host: Host) => ({
            value: host.Id,
            label: host.FullName,
          }))}
          onValueChange={(value: unknown) =>
            form.setFields([{ name: 'Hosts', value: isArray(value) ? value.map((Id) => ({ Id })) : value }])
          }
        />
      </Form.Item>
    </Col>
  );

  const renderTimeframe = () => (
    <Col>
      <Form.Item name="ValidFrom" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="ValidTo" hidden>
        <Input />
      </Form.Item>

      <Form.Item label={<Translated id="visitors.Timeframe" />}>
        <DateTimePicker
          initialValues={[initialValues?.ValidFrom, initialValues?.ValidTo]}
          onValueChange={(values: RangeValue<Moment>) => {
            const [from, to] = values || [];
            form.setFields([
              { name: 'ValidFrom', value: from },
              { name: 'ValidTo', value: to },
            ]);
          }}
        />
      </Form.Item>
    </Col>
  );

  // Render Form
  return (
    <FormColumn padLeftNone xs={24} md={10}>
      <Flex alignItems="center">
        <Avatar className="avatar-border-color" shape="square" icon={<AuditOutlined />} style={{ marginRight: 8 }} />
        <Title level={4} className="gx-mb-0" style={{ fontWeight: 500 }}>
          <Translated id="visits.form.details" />
        </Title>
      </Flex>
      <Divider />
      {renderDetails()}
      {renderHosts()}
      {renderTimeframe()}
    </FormColumn>
  );
};
