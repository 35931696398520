// Types
import { SignalREvent } from '../../models/SignalREvent';

// Actions
import {
  employeeCreated,
  employeeDeleted,
  employeeNotCreated,
  employeeNotDeleted,
  employeeNotUpdated,
  employeesDeleted,
  employeesNotDeleted,
  employeeUpdated,
} from './Employees.redux';

// Events
const SignalREvents = {
  employeeCreated: `employeeCreatedIntegrationEvent`,
  employeeUpdated: `employeeUpdatedIntegrationEvent`,
  employeeDeleted: `employeeDeletedIntegrationEvent`,
  employeesDeleted: `employeesDeletedIntegrationEvent`,
};

// Exceptions
const SignalRExceptions = {
  employeeNotCreated: `employeeNotCreatedIntegrationException`,
  employeeNotUpdated: `employeeNotUpdatedIntegrationException`,
  employeeNotDeleted: `employeeNotDeletedIntegrationException`,
  employeesNotDeleted: `employeesNotDeletedIntegrationException`,
};

// Events
export const configureSignalREvents = ({ hubConnection, store, history }: SignalREvent) => {
  // Create
  hubConnection.on(SignalREvents.employeeCreated, (msg) => {
    store.dispatch(employeeCreated({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.employeeNotCreated, (msg) => {
    store.dispatch(employeeNotCreated({ history, msg }));
  });

  // Update
  hubConnection.on(SignalREvents.employeeUpdated, (msg) => {
    store.dispatch(employeeUpdated({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.employeeNotUpdated, (msg) => {
    store.dispatch(employeeNotUpdated({ history, msg }));
  });

  // Delete
  hubConnection.on(SignalREvents.employeeDeleted, (msg) => {
    store.dispatch(employeeDeleted({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.employeeNotDeleted, (msg) => {
    store.dispatch(employeeNotDeleted({ history, msg }));
  });

  // Delete All
  hubConnection.on(SignalREvents.employeesDeleted, (msg) => {
    store.dispatch(employeesDeleted({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.employeesNotDeleted, (msg) => {
    store.dispatch(employeesNotDeleted({ history, msg }));
  });
};
