// Types
import { SignalREvent } from '../models/SignalREvent';

// Generic
import { configureSignalREvents as configureGenericActions } from './Generic/Generic.signalr';
// Account
import { configureSignalREvents as configureAccountsActions } from './Account/Account.signalr';
// Employees
import { configureSignalREvents as configureEmployeesActions } from './Employees/Employees.signalr';
import { configureSignalREvents as configureEmployeeIdentifiersActions } from './Employees/EmployeeIdentifiers/EmployeeIdentifiers.signalr';
import { configureSignalREvents as configureEmployeeAccessProfilesActions } from './Employees/EmployeeAccessProfiles/EmployeeAccessProfiles.signalr';
import { configureSignalREvents as configureEmployeeImportActions } from './Employees/EmployeeImport/EmployeeImport.signalr';
// Visitors
import { configureSignalREvents as configureVisitorsActions } from './Visitors/Visitors.signalr';
import { configureSignalREvents as configureVisitorIdentifiersActions } from './Visitors/VisitorIdentifiers/VisitorIdentifiers.signalr';
import { configureSignalREvents as configureVisitorAccessProfilesActions } from './Visitors/VisitorAccessProfiles/VisitorAccessProfiles.signalr';
// Visits
import { configureSignalREvents as configureVisitsActions } from './Visits/Visits.signalr';
// Reservations
import { configureSignalREvents as configureReservationsActions } from './Reservations/Reservations.signalr';
import { configureSignalREvents as configureReservationIdentifiersActions } from './Reservations/ReservationIdentifiers/ReservationIdentifiers.signalr';
// Actions
import { configureSignalREvents as configureActions } from './Actions/Actions.signalr';

// Endpoint Configuration
export const configureReduxEvents = ({ hubConnection, store, history }: SignalREvent) => {
  // Generic
  configureGenericActions({ hubConnection, store, history });
  // Account
  configureAccountsActions({ hubConnection, store, history });
  // Employees
  configureEmployeesActions({ hubConnection, store, history });
  configureEmployeeIdentifiersActions({ hubConnection, store, history });
  configureEmployeeAccessProfilesActions({ hubConnection, store, history });
  configureEmployeeImportActions({ hubConnection, store, history });
  // Visitors
  configureVisitorsActions({ hubConnection, store, history });
  configureVisitorIdentifiersActions({ hubConnection, store, history });
  configureVisitorAccessProfilesActions({ hubConnection, store, history });
  // Visits
  configureVisitsActions({ hubConnection, store, history });
  // Reservations
  configureReservationsActions({ hubConnection, store, history });
  configureReservationIdentifiersActions({ hubConnection, store, history });
  // Actions
  configureActions({ hubConnection, store, history });
};
