import { History } from 'history';

export enum OperationType {
  Other = 0,
  Create = 1,
  Update = 2,
  Delete = 3,
}

export type SignalRAction = {
  history: History;
  msg: any;
};
