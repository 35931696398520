import { useEffect, useMemo } from 'react';
import { usePrevious } from 'react-use';

// Models
import { isEmpty } from 'lodash';
import { Visitor } from '../../../models/Visits/Visitor';

// Hooks
import { useData } from '../../App/useData';

// Data
import { ApiEndpoints } from '../../../data/ApiEndpoints';
import { useAppSelector } from '../../App/useRedux';

// Props
interface VisitorIdentifiersProps {
  visitor: Visitor | null;
  fetchVisitor: (params: any) => Promise<void>;
}

export const useVisitorIdentifiers = ({ visitor, fetchVisitor }: VisitorIdentifiersProps) => {
  // Redux State
  const error = useAppSelector(({ visitorsIdentifiers }) => visitorsIdentifiers?.error ?? false);
  const updating = useAppSelector(({ visitorsIdentifiers }) => visitorsIdentifiers?.updating ?? false);
  const prevUpdating = usePrevious(updating);

  // Data
  const { data: identifierTypes, fetch, pending } = useData(ApiEndpoints.tenant.identifierTypes, null);

  // Component State
  const loading = pending || !visitor;
  const defaultActiveKey = loading || !identifierTypes || isEmpty(identifierTypes) ? '0' : identifierTypes[0].Id;

  // Effects
  useEffect(() => {
    // Fetch on initializing
    fetch();
  }, [fetch]);

  useEffect(() => {
    // Fetch after updating
    if (visitor && prevUpdating === true && updating === false && !error) {
      fetchVisitor({ id: visitor.Id });
    }
  }, [prevUpdating, updating, error, fetchVisitor, visitor]);

  // Return Hook
  return useMemo(
    () => ({
      identifierTypes,
      loading,
      updating,
      defaultActiveKey,
    }),
    [identifierTypes, loading, updating, defaultActiveKey]
  );
};
