import React from 'react';
import { Button } from 'antd';
import { ButtonProps } from 'antd/es/button';
import styled from 'styled-components';

// Constants
import { AppColor, getColor, getDarkerColor, getLighterColor } from '../../../constants/Utils/Colors';

// Props
interface ColorButtonProps extends ButtonProps {
  color: AppColor;
  width?: number;
  xl?: boolean;
}

// Styled Component
const StyledColorButton = styled(
  ({ color: _color, width: _width, size, xl: _xl, children, ...rest }: ColorButtonProps) => (
    <Button {...rest} size={size}>
      {children}
    </Button>
  )
)`
  color: ${(props) => getColor(props.color)};
  border-color: ${(props) => getColor(props.color)};
  width: ${(props) => props.width && `${props.width}px`};

  /* XL Button */
  height: ${(props) => props.xl && `60px`};
  line-height: ${(props) => props.xl && `60px`};
  font-size: ${(props) => props.xl && `1.25em`};

  &:active,
  &:hover,
  &:focus {
    background-color: ${(props) => getLighterColor(props.color)};
    color: ${(props) => getDarkerColor(props.color)};
    border-color: ${(props) => getDarkerColor(props.color)};
  }
`;

export const ColorButton = ({ ...rest }: ColorButtonProps) => <StyledColorButton {...rest} />;
