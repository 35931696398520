import React, { useCallback, useMemo } from 'react';

// Hooks
import { useData } from '../App/useData';
import { useVisitorForm } from './useVisitorForm';
import { useFormDrawer } from '../Drawer/useFormDrawer';
import { useVisitForm } from './useVisitForm';
import { useVisitorsTableFilter } from './useVisitorsTableFilter';

// Data
import { ApiEndpoints } from '../../data/ApiEndpoints';
import { visitorTableColumns } from '../../data/Table/VisitorTableColumns';
import { appBreadcrumbs } from '../../data/Breadcrumbs/Breadcrumbs';

// Constants
import { LocalStorageKeys } from '../../constants/Utils/LocalStorage';
import { getTableColumns, saveHiddenTableColumns } from '../../constants/Utils/UIFunctions';

// Components
import { Translated } from '../../components/UI/Core';
import { BasicIcon } from '../../components/UI/Icon/BasicIcon';

export const useVisitorsList = <T extends object>() => {
  // Data
  const { data: visitors, pageCount, totalCount, fetch, pending } = useData(ApiEndpoints.visitors.list, null);

  // Form Hooks
  const {
    formOptions: visitorFormOptions,
    updating: visitorUpdating,
    error: visitorError,
    showDeleteAllConfirm,
  } = useVisitorForm<T>({});
  const {
    formOptions: visitFormOptions,
    updating: visitUpdating,
    lastEventId: visitLastEventId,
    error: visitError,
  } = useVisitForm<T>({});

  // Form Drawer(s)
  const { getFormDrawerProps: getVisitorFormDrawerProps } = useFormDrawer<T>({
    formOptions: visitorFormOptions,
    updating: visitorUpdating,
    error: visitorError,
  });
  const { AddButton: AddVisitButton, getFormDrawerProps: getVisitFormDrawerProps } = useFormDrawer<T>({
    formOptions: visitFormOptions,
    updating: visitUpdating,
    error: visitError,
    size: 'large',
  });

  // Table Filter
  const { FilterSelect, customTableFilters } = useVisitorsTableFilter();

  // Form Status
  const updating = visitorUpdating || visitUpdating;
  const lastEventId = visitLastEventId;

  // Fetch + Append Filter Data
  const fetchData = useCallback((args: any) => fetch({ ...args, ...customTableFilters }), [fetch, customTableFilters]);

  // Helper to obtain Visitors with Visit(s) subRows
  const getVisitors = useCallback(
    () =>
      visitors?.map((visitor) => ({
        ...visitor,
        subRows:
          visitor.Visits.length > 1 &&
          visitor.Visits.slice(1).map((visit) => ({
            ...visitor,
            Visits: [visit],
          })),
      })),
    [visitors]
  );

  // Props
  const getBreadcrumbMenuProps = useCallback(() => ({ breadcrumbs: appBreadcrumbs.visitors.list }), []);
  const getVisitorsDataProps = useCallback(
    () => ({
      storageKey: LocalStorageKeys.Visitors.table.hiddenColumns,
      title: (
        <>
          <BasicIcon className="icon icon-avatar" style={{ marginRight: 8 }} />
          <Translated id="appMenu.visitors" />
        </>
      ),
      data: getVisitors() ?? [],
      columns: getTableColumns(visitorTableColumns, LocalStorageKeys.Visitors.table.hiddenColumns),
      fetchData,
    }),
    [fetchData, getVisitors]
  );
  const getVisitorsTableProps = useCallback(
    () => ({
      showExpandableRows: true,
      height: 320,
      pending,
      updating,
      lastEventId,
      pageCount,
      totalCount,
    }),
    [pending, updating, lastEventId, pageCount, totalCount]
  );
  const getVisitorsCrudProps = useCallback(
    () => ({
      AddButton: AddVisitButton,
      endpoint: getVisitorFormDrawerProps().endpoint,
      showDeleteAllConfirm,
      saveHiddenTableColumns,
      FilterSelect,
    }),
    [AddVisitButton, getVisitorFormDrawerProps, showDeleteAllConfirm, FilterSelect]
  );

  return useMemo(
    () => ({
      updating,
      getBreadcrumbMenuProps,
      getVisitorsDataProps,
      getVisitorsTableProps,
      getVisitorsCrudProps,
      getVisitorFormDrawerProps,
      getVisitFormDrawerProps,
    }),
    [
      updating,
      getBreadcrumbMenuProps,
      getVisitorsDataProps,
      getVisitorsTableProps,
      getVisitorsCrudProps,
      getVisitorFormDrawerProps,
      getVisitFormDrawerProps,
    ]
  );
};
