/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Models
import { AccountFormValues } from '../../models/AccountFormValues';
import { SignalRAction } from '../../models/SignalRAction';

// State
interface AccountState {
  updating: boolean;
  error: boolean;
  userEmail: string;
  userRoles: string[];
}

const initialState: AccountState = {
  updating: false,
  error: false,
  userEmail: '',
  userRoles: [],
};

// Name
const storeName = '@ACCOUNT';

// Redux Actions|Reducers
const accountSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    // Change
    changeAccount: (state, action: PayloadAction<{ formValues: AccountFormValues }>) => {
      state.updating = true;
      state.error = false;
    },
    accountChanged: (state) => {
      state.updating = false;
      state.error = false;
    },
    accountNotChanged: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },
    setUserEmail: (state, action: PayloadAction<string>) => {
      state.userEmail = action.payload;
    },
    setUserRoles: (state, action: PayloadAction<string[]>) => {
      state.userRoles = action.payload;
    },
  },
});

// Export Actions
export const {
  // Assign
  changeAccount,
  accountChanged,
  accountNotChanged,
  setUserEmail,
  setUserRoles,
} = accountSlice.actions;

// Export Reducer
export default accountSlice.reducer;
