/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */

import {createSlice, PayloadAction} from '@reduxjs/toolkit';

// Models
import {Visit} from '../../models/Visits/Visit';
import {SignalRAction} from '../../models/SignalRAction';
import {VisitStatus} from '../../models/Visits/VisitStatus';
import {ResendInvitationToGuest} from '../../models/Visits/ResendInvitationToGuest';
import {ResendInvitationToHost} from '../../models/Visits/ResendInvitationToHost';

// State
interface VisitsState {
  lastEventId?: string;
  updating: boolean;
  error: boolean;
}

const initialState: VisitsState = {
  lastEventId: undefined,
  updating: false,
  error: false,
};

// Name
const storeName = '@VISITS';

// Redux Actions|Reducers
const visitsSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    // Create
    createVisit: (state, _action: PayloadAction<Visit>) => {
      state.updating = true;
      state.error = false;
    },
    visitCreated: (state, action: PayloadAction<SignalRAction>) => {
      state.lastEventId = action.payload.msg.id;
      state.updating = false;
      state.error = false;
    },
    visitNotCreated: (state, _action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Update
    updateVisit: (state, _action: PayloadAction<{ visit: Visit; sendEmail: boolean }>) => {
      state.updating = true;
      state.error = false;
    },
    visitUpdated: (state, _action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    visitNotUpdated: (state, _action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Delete
    deleteVisit: (state, _action: PayloadAction<Visit>) => {
      state.updating = true;
      state.error = false;
    },
    visitDeleted: (state, _action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    visitNotDeleted: (state, _action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Delete All
    deleteVisits: (state, _action: PayloadAction<Array<Visit>>) => {
      state.updating = true;
      state.error = false;
    },
    visitsDeleted: (state, _action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    visitsNotDeleted: (state, _action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Update
    updateVisitStatus: (state, _action: PayloadAction<VisitStatus>) => {
      state.updating = true;
      state.error = false;
    },
    visitStatusUpdated: (state, action: PayloadAction<SignalRAction>) => {
      state.lastEventId = action.payload.msg.id;
      state.updating = false;
      state.error = false;
    },
    visitStatusNotUpdated: (state, _action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },
    visitEmailStatusUpdated: (state, action: PayloadAction<SignalRAction>) => {
      state.lastEventId = action.payload.msg.id;
      state.updating = false;
      state.error = false;
    },
    visitEmailStatusNotUpdated: (state, _action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },
    resendInvitationToGuest: (state, _action: PayloadAction<ResendInvitationToGuest>) => {
      state.updating = false;
      state.error = false;
    },
    resendInvitationToHost: (state, _action: PayloadAction<ResendInvitationToHost>) => {
      state.updating = false;
      state.error = false;
    },
  },
});

// Export Actions
export const {
  // Create
  createVisit,
  visitCreated,
  visitNotCreated,
  // Update
  updateVisit,
  visitUpdated,
  visitNotUpdated,
  // Delete
  deleteVisit,
  visitDeleted,
  visitNotDeleted,
  // Delete All
  deleteVisits,
  visitsDeleted,
  visitsNotDeleted,
  // Update Status
  updateVisitStatus,
  visitStatusUpdated,
  visitStatusNotUpdated,
  visitEmailStatusUpdated,
  visitEmailStatusNotUpdated,
  resendInvitationToGuest,
  resendInvitationToHost,
} = visitsSlice.actions;

// Export Reducer
export default visitsSlice.reducer;
