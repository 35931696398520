// Types
import { PersonType } from '../../../models/enums/PersonType';
import { SignalREvent } from '../../../models/SignalREvent';

// Actions
import {
  identifierAssigned,
  identifierNotAssigned,
  identifierNotRemoved,
  identifierRemoved,
  identifiersNotRemoved,
  identifierStatusNotToggled,
  identifierStatusToggled,
} from './VisitorIdentifiers.redux';

// Events
const SignalREvents = {
  PersonIdentifierAssigned: `PersonIdentifierAssignedIntegrationEvent`,
  PersonIdentifierStatusToggled: `PersonIdentifierStatusToggledIntegrationEvent`,
  PersonIdentifierRemoved: `PersonIdentifierRemovedIntegrationEvent`,
};

// Exceptions
const SignalRExceptions = {
  PACSIdentifierNotAssigned: `PACSIdentifierNotAssignedIntegrationException`,
  PACSIdentifierNotRemoved: `PACSIdentifierNotRemovedIntegrationException`,
  PACSIdentifiersNotRemoved: `PACSIdentifiersNotRemovedIntegrationException`,
  PACSIdentifierStatusNotToggled: `PACSIdentifierStatusNotToggledIntegrationException`,
};

// Events
export const configureSignalREvents = ({ hubConnection, store, history }: SignalREvent) => {
  // Assign
  hubConnection.on(SignalREvents.PersonIdentifierAssigned, (msg) => {
    if (msg.personType === PersonType.Visitor) {
      store.dispatch(identifierAssigned({ history, msg }));
    }
  });
  hubConnection.on(SignalRExceptions.PACSIdentifierNotAssigned, (msg) => {
    if (msg.personType === PersonType.Visitor) {
      store.dispatch(identifierNotAssigned({ history, msg }));
    }
  });

  // Remove
  hubConnection.on(SignalREvents.PersonIdentifierRemoved, (msg) => {
    if (msg.personType === PersonType.Visitor) {
      store.dispatch(identifierRemoved({ history, msg }));
    }
  });
  hubConnection.on(SignalRExceptions.PACSIdentifierNotRemoved, (msg) => {
    if (msg.personType === PersonType.Visitor) {
      store.dispatch(identifierNotRemoved({ history, msg }));
    }
  });
  hubConnection.on(SignalRExceptions.PACSIdentifiersNotRemoved, (msg) => {
    if (msg.personType === PersonType.Visitor) {
      store.dispatch(identifiersNotRemoved({ history, msg }));
    }
  });

  // Toggle
  hubConnection.on(SignalREvents.PersonIdentifierStatusToggled, (msg) => {
    if (msg.personType === PersonType.Visitor) {
      store.dispatch(identifierStatusToggled({ history, msg }));
    }
  });
  hubConnection.on(SignalRExceptions.PACSIdentifierStatusNotToggled, (msg) => {
    if (msg.personType === PersonType.Visitor) {
      store.dispatch(identifierStatusNotToggled({ history, msg }));
    }
  });
};
