import React from 'react';
import { Skeleton } from 'antd';
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

// Constants
import { AppColors } from '../../../constants/Utils/Colors';

// Models
import { VisitorMetricsResponse } from '../../../models/Visits/Dashboard/VisitorDashboardResponse';

// Components
import { Translated } from '../../UI/Core';
import { DarkTooltip } from '../../UI/Chart/DarkTooltip';
import { WeekdayTick } from '../../UI/Chart/WeekdayTick';

// Props
interface VisitorMetricsProps {
  visitorMetrics?: VisitorMetricsResponse;
}

// Component
export const VisitorMetrics = ({ visitorMetrics }: VisitorMetricsProps) => (
  <div className="ant-col gx-visit-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 ant-col-xl-13">
    <div className="gx-site-dash gx-pr-xl-5 gx-pt-3 gx-pt-xl-0 gx-pt-xl-2">
      <h6 className="gx-text-uppercase gx-mb-2 gx-mb-xl-4">
        <Translated id="visitors.dashboard.chart.title" />
      </h6>
      {visitorMetrics ? (
        <ResponsiveContainer width="100%" height={162}>
          <AreaChart data={visitorMetrics} margin={{ top: 4, right: 8, left: 16, bottom: 0 }}>
            <CartesianGrid horizontal={false} strokeDasharray="3 3" />
            <XAxis tick={<WeekdayTick intlId="visitors.dashboard.short." />} interval={0} dataKey="Day" />
            <YAxis width={12} fontSize={12} fontWeight={600} allowDecimals={false} />
            <Tooltip content={<DarkTooltip intlId="visitors.dashboard." />} />

            <Area
              // LastWeek
              dataKey="LastWeek"
              type="monotone"
              stroke={AppColors.orange}
              fill={AppColors.orange}
            />
            <Area
              // NextWeek
              dataKey="NextWeek"
              type="monotone"
              stroke={AppColors.cyan}
              fill={AppColors.cyan}
            />
            <Area
              // ThisWeek
              dataKey="ThisWeek"
              type="monotone"
              stroke={AppColors.lime}
              fill={AppColors.lime}
            />
          </AreaChart>
        </ResponsiveContainer>
      ) : (
        <Skeleton />
      )}
    </div>
  </div>
);
