import React from 'react';
import { Space, Tooltip } from 'antd';

// Constants
import { getLocalDateTimeString } from '../../../constants/Utils/DateTime';

// Models
import { Visit } from '../../../models/Visits/Visit';
import { Translated } from '../../UI/Core';
import { TimeframeTags } from '../../UI/Tag/TimeframeTags';

// Props
interface TimeframeCellProps {
  visit: Visit;
}

// Component
export const TimeframeCell = ({ visit }: TimeframeCellProps) => {
  // Helper Methods
  const start = getLocalDateTimeString(visit?.ValidFrom);
  const end = getLocalDateTimeString(visit?.ValidTo);

  // Component content
  const tooltipTitle = (
    <Space direction="vertical">
      <span>
        <span style={{ fontWeight: 600 }}>
          <Translated id="app.start" />
        </span>
        : {start}
      </span>
      <span>
        <span style={{ fontWeight: 600 }}>
          <Translated id="app.end" />
        </span>
        : {end}
      </span>
    </Space>
  );
  const tooltipContent = <TimeframeTags validFrom={visit.ValidFrom} validTo={visit.ValidTo} />;

  return (
    <Tooltip title={tooltipTitle} placement="left">
      {tooltipContent}
    </Tooltip>
  );
};
