import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

// Services
import { ApiRequestType, apiService } from '../../services/ApiService/ApiService';
import { notificationService } from '../../services/Notifications/NotificationService';

// Models
import { ExecuteAction } from '../../models/Action';
import { SignalRAction } from '../../models/SignalRAction';

// Redux
import { actionExecuted, actionNotExecuted, executeAction } from './Actions.redux';

// **************************************************
// ********************* Execute Actions  *********************

// Worker Sagas
function* executeActionSaga() {
  yield takeEvery(executeAction.type, actionExecuteRequest);
}

function* actionExecutedSaga() {
  yield takeLatest(actionExecuted.type, actionExecuteResponse);
}

function* actionNotExecutedSaga() {
  yield takeLatest(actionNotExecuted.type, actionExecuteError);
}

// Request
function* actionExecuteRequest(action: PayloadAction<ExecuteAction>) {
  try {
    const { payload: executeActionData } = action;
    yield apiService.execute({
      url: `Actions/${executeActionData.ActionId}/Execute`,
      method: ApiRequestType.PUT,
      data: executeActionData,
    });
  } catch ({ message }) {
    yield put({ type: actionNotExecuted.type, payload: { msg: { message } } });
  }
}

// Response
function actionExecuteResponse() {
  notificationService.showSuccess('action.notifications.execute');
}

// Error
function actionExecuteError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('action.notifications.executeFailed', action?.payload?.msg.message);
}

// **************************************************
// ********************* EXPORT SAGAS ***************

export default function* sagas() {
  yield all([
    // Execute Actions
    executeActionSaga(),
    actionExecutedSaga(),
    actionNotExecutedSaga(),
  ]);
}
