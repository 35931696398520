/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Models
import { Employee } from '../../models/Employee';
import { SignalRAction } from '../../models/SignalRAction';

// State
interface EmployeesState {
  updating: boolean;
  error: boolean;
}

const initialState: EmployeesState = {
  updating: false,
  error: false,
};

// Name
const storeName = '@EMPLOYEES';

// Redux Actions|Reducers
const employeesSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    // Create
    createEmployee: (state, _action: PayloadAction<Employee>) => {
      state.updating = true;
      state.error = false;
    },
    employeeCreated: (state, _action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    employeeNotCreated: (state, _action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Update
    updateEmployee: (state, _action: PayloadAction<Employee>) => {
      state.updating = true;
      state.error = false;
    },
    employeeUpdated: (state, _action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    employeeNotUpdated: (state, _action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Delete
    deleteEmployee: (state, _action: PayloadAction<Employee>) => {
      state.updating = true;
      state.error = false;
    },
    employeeDeleted: (state, _action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    employeeNotDeleted: (state, _action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Delete All
    deleteEmployees: (state, _action: PayloadAction<Array<Employee>>) => {
      state.updating = true;
      state.error = false;
    },
    employeesDeleted: (state, _action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    employeesNotDeleted: (state, _action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },
  },
});

// Export Actions
export const {
  // Create
  createEmployee,
  employeeCreated,
  employeeNotCreated,
  // Update
  updateEmployee,
  employeeUpdated,
  employeeNotUpdated,
  // Delete
  deleteEmployee,
  employeeDeleted,
  employeeNotDeleted,
  // Delete All
  deleteEmployees,
  employeesDeleted,
  employeesNotDeleted,
} = employeesSlice.actions;

// Export Reducer
export default employeesSlice.reducer;
