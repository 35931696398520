import React, { useCallback, useMemo } from 'react';
import { Modal } from 'antd';
import { IdcardOutlined } from '@ant-design/icons';

import { useIntl } from 'react-intl';
import { IdentifierFormValues } from '../../../models/IdentifierFormValues';

import { Employee } from '../../../models/Employee';

// Hooks
import { useAppDispatch, useAppSelector } from '../../App/useRedux';
import {
  assignIdentifier,
  removeIdentifier,
  toggleIdentifierStatus,
} from '../../../store/Employees/EmployeeIdentifiers/EmployeeIdentifiers.redux';

// Props
export interface AssignConfirmProps {
  formValues: IdentifierFormValues;
  employee: Employee;
}

export interface RemoveConfirmProps {
  formValues: IdentifierFormValues;
  employee: Employee;
}

export interface ToggleIdentifierStatusProps {
  formValues: IdentifierFormValues;
  employee: Employee;
}

// Components
const { confirm } = Modal;

// Hook
export const useEmployeeIdentifiersReduxState = () => {
  // Intl
  const intl = useIntl();

  // Redux
  const dispatch = useAppDispatch();
  const updating = useAppSelector(({ employeeIdentifiers }) => employeeIdentifiers?.updating ?? false);
  const error = useAppSelector(({ employeeIdentifiers }) => employeeIdentifiers?.error ?? false);

  // Callbacks
  const showAssignConfirm = useCallback(
    ({ formValues, employee }: AssignConfirmProps) => {
      if (formValues) {
        confirm({
          title: intl.formatMessage({
            id: 'identifiers.form.confirm.assign',
          }),
          icon: <IdcardOutlined />,
          content: intl.formatMessage({
            id: 'identifiers.form.confirm.assignSub',
          }),
          okText: intl.formatMessage({
            id: 'app.yes',
            defaultMessage: 'Yes',
          }),
          cancelText: intl.formatMessage({
            id: 'app.no',
            defaultMessage: 'No',
          }),
          okType: 'primary',
          onOk: () => dispatch(assignIdentifier({ formValues, employee })),
          onCancel: () => null,
        });
      }
    },
    [dispatch, intl]
  );

  const showRemoveConfirm = useCallback(
    ({ formValues, employee }: RemoveConfirmProps) => {
      if (formValues) {
        confirm({
          title: intl.formatMessage({
            id: 'identifiers.form.confirm.remove',
          }),
          icon: <IdcardOutlined />,
          content: intl.formatMessage({
            id: 'identifiers.form.confirm.removeSub',
          }),
          okText: intl.formatMessage({
            id: 'app.yes',
            defaultMessage: 'Yes',
          }),
          cancelText: intl.formatMessage({
            id: 'app.no',
            defaultMessage: 'No',
          }),
          okType: 'primary',
          onOk: () => dispatch(removeIdentifier({ formValues, employee })),
          onCancel: () => null,
        });
      }
    },
    [dispatch, intl]
  );

  const toggleIdentifier = useCallback(
    ({ formValues, employee }: ToggleIdentifierStatusProps) => {
      if (formValues) {
        dispatch(toggleIdentifierStatus({ formValues, employee }));
      }
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      updating,
      error,
      showAssignConfirm,
      showRemoveConfirm,
      toggleIdentifier,
    }),
    [updating, error, showAssignConfirm, showRemoveConfirm, toggleIdentifier]
  );
};
