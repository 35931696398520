import React from 'react';
import { Collapse, FormInstance, FormListFieldData } from 'antd';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

// eslint-disable-next-line import/no-extraneous-dependencies
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';

// Models
import { VisitFormValues } from '../../../../models/Visits/VisitFormValues';

// Components
import { VisitFormGuestsFields } from './VisitFormGuestsFields';
import { VisitFormGuestsActions } from './VisitFormGuestsActions';
import { Visitor } from '../../../../models/Visits/Visitor';

// Components
const { Panel } = Collapse;

// Styled
const StyledCollapse = styled(Collapse)`
  & > .ant-collapse-item > .ant-collapse-header {
    align-items: center;
  }
`;

// Props
export interface VisitFormGuestsCollapseProps<T> {
  form: FormInstance<T>;
  formData: ValidateErrorEntity<unknown> | undefined;
  fields: FormListFieldData[];
  activeKey?: number;
  setActiveKey: React.Dispatch<React.SetStateAction<number | undefined>>;
  onRemove: (key: number) => void;
}

// Component
export const VisitFormGuestsCollapse = <T extends object>({
  form,
  formData,
  fields,
  activeKey,
  setActiveKey,
  onRemove,
}: VisitFormGuestsCollapseProps<T>) => {
  // Intl
  const intl = useIntl();

  // Event Handlers
  const onCollapse = (key: string | number | (string | number)[]) => {
    setActiveKey(key as number);
  };
  const onGuestSearch = (value: unknown, data: Visitor[] | null, index: number) => {
    const selectedVisitor = data?.find((x) => x.Id === value);
    form.setFields([
      { name: ['Guests', index, 'Id'], value: selectedVisitor?.Id },
      { name: ['Guests', index, 'FirstName'], value: selectedVisitor?.FirstName },
      { name: ['Guests', index, 'Prefix'], value: selectedVisitor?.Prefix },
      { name: ['Guests', index, 'LastName'], value: selectedVisitor?.LastName },
      { name: ['Guests', index, 'Email'], value: selectedVisitor?.Email },
      { name: ['Guests', index, 'PhoneNumber'], value: selectedVisitor?.PhoneNumber },
      { name: ['Guests', index, 'CompanyName'], value: selectedVisitor?.CompanyName },
    ]);
    setActiveKey(undefined);
  };

  return (
    <StyledCollapse accordion expandIconPosition="end" className="gx-mb-3" activeKey={activeKey} onChange={onCollapse}>
      {fields.map(({ name: index }) => {
        const visitor = (form.getFieldsValue() as VisitFormValues)?.Guests?.[index];
        const { FirstName = '', Prefix = '', LastName = '' } = visitor ?? {};

        const guestName =
          FirstName || Prefix || LastName
            ? `${FirstName} ${Prefix} ${LastName}`
            : `${intl.formatMessage({ id: 'visits.form.guest' })} ${index + 1}`;

        return (
          <Panel
            header={guestName}
            key={index}
            forceRender
            extra={
              <VisitFormGuestsActions
                visitorId={visitor && visitor.Id}
                showWarning={formData?.errorFields.some((e) => e.name[1] === index) ?? false}
                removeDisabled={fields.length <= 1}
                onRemove={() => onRemove(index)}
                onGuestSearch={(value, data) => onGuestSearch(value, data, index)}
              />
            }
          >
            <VisitFormGuestsFields index={index} isReadOnly={!!visitor?.Id} />
          </Panel>
        );
      })}
    </StyledCollapse>
  );
};
