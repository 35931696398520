import moment, { Moment, unitOfTime } from 'moment';

export type MomentInput = Moment | Date | string | number | undefined;

export const dateTimeISOFormat = 'YYYY-MM-DDTHH:mm:ss';
export const dateTimeStringFormat = 'DD MMM YYYY HH:mm';
export const minDate = moment('0001-01-01T00:00:00', dateTimeISOFormat);

export const getUtcDateTime = (dateTime: MomentInput) => moment.utc(dateTime, dateTimeISOFormat);
export const getLocalDateTime = (dateTime: MomentInput) => getUtcDateTime(dateTime).local();

export const getLocalDateTimeString = (dateTime: MomentInput) => moment(dateTime).local().format(dateTimeStringFormat);

export const isMinDate = (dateTime: Moment) => dateTime.isSame(minDate, 'date');

export const isInThePast = (dateTime: MomentInput, amount = 1, unit: unitOfTime.DurationConstructor = 'd') => {
  const pastDate = moment().hour(23).minute(59).second(59).subtract(amount, unit);
  return getLocalDateTime(dateTime).isBefore(pastDate);
};

export const configureMoment = () => {
  moment.fn.toJSON = function toJSON() {
    return this.format(); // Default serialisation doesn't preserve offset, but format does
  };
};
