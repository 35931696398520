import React, { useCallback, useMemo } from 'react';
import { Select } from 'antd';

// Models
import { useDispatch } from 'react-redux';
import { Timeframe } from '../../models/enums/Timeframe';

// Components
import { Translated } from '../../components/UI/Core';
import { useAppSelector } from '../App/useRedux';
import { updateCustomTableFilters } from '../../store/Visitors/Visitors.redux';
import { VisitType } from '../../models/enums/VisitType';

// Data
const timeframeOptions = [
  {
    value: Timeframe.Today,
    label: <Translated id="visitors.dashboard.today" />,
  },
  {
    value: Timeframe.Tomorrow,
    label: <Translated id="visitors.dashboard.tomorrow" />,
  },
  {
    value: Timeframe.ThisWeek,
    label: <Translated id="visitors.dashboard.thisWeek" />,
  },
  {
    value: Timeframe.NextWeek,
    label: <Translated id="visitors.dashboard.nextWeek" />,
  },
  {
    value: Timeframe.LastWeek,
    label: <Translated id="visitors.dashboard.lastWeek" />,
  },
  {
    value: Timeframe.ThisMonth,
    label: <Translated id="visitors.dashboard.thisMonth" />,
  },
  {
    value: Timeframe.ThisYear,
    label: <Translated id="visitors.dashboard.thisYear" />,
  },
  {
    value: Timeframe.AllTime,
    label: <Translated id="visitors.dashboard.allTime" />,
  },
];

const visitTypeOptions = [
  {
    value: VisitType.All,
    label: <Translated id="visitors.visitType.all" />,
  },
  {
    value: VisitType.Default,
    label: <Translated id="visitors.visitType.default" />,
  },
  {
    value: VisitType.Reservation,
    label: <Translated id="visitors.visitType.reservation" />,
  },
];

// Hook
export const useVisitorsTableFilter = () => {
  // State
  const dispatch = useDispatch();
  const { customTableFilters } = useAppSelector(({ visitors }) => visitors);

  // Components
  const FilterSelect = useCallback(
    () => (
      <div>
        <Select
          value={customTableFilters.visitType}
          options={visitTypeOptions}
          onChange={(value) => dispatch(updateCustomTableFilters({ ...customTableFilters, visitType: value }))}
          style={{ width: 140, marginRight: 16 }}
        />
        <Select
          value={customTableFilters.timeframe}
          options={timeframeOptions}
          onChange={(value) => dispatch(updateCustomTableFilters({ ...customTableFilters, timeframe: value }))}
          style={{ width: 140, marginRight: 16 }}
        />
      </div>
    ),
    [dispatch, customTableFilters]
  );

  // Hook response
  return useMemo(
    () => ({
      FilterSelect,
      customTableFilters,
    }),
    [FilterSelect, customTableFilters]
  );
};
