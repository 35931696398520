import React from 'react';
import { Col, ColProps } from 'antd';
import styled from 'styled-components';

// Props
interface StyledColProps extends ColProps {
  padLeftNone?: boolean;
  padRightNone?: boolean;
}

// Component
export const FormColumn = styled(
  ({ padLeftNone: _padLeftNone, padRightNone: _padRightNone, ...rest }: StyledColProps) => <Col {...rest} />
)<StyledColProps>`
  padding-left: ${(props) => (props.padLeftNone ? 0 : undefined)};
  padding-right: ${(props) => (props.padRightNone ? 0 : undefined)};

  &:first-child {
    padding-left: ${(props) => (props.padLeftNone ? undefined : '1px')};
  }
  &:last-child {
    padding-right: ${(props) => (props.padRightNone ? undefined : 0)};
  }
`;
