import React from 'react';
import { Button, Space } from 'antd';
import { DeleteOutlined, UserOutlined, WarningFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';

// Models
import { Visitor } from '../../../../models/Visits/Visitor';

// Components
import { StyledTooltip } from '../../../UI/Tooltip/StyledTooltip';
import { VisitFormGuestsSearch } from './VisitFormGuestsSearch';
import { SelectedOption } from '../../../../models/SelectedOption';
import { Translated } from '../../../UI/Core/Translated';

// Props
export interface VisitFormGuestsActionsProps {
  visitorId?: string;
  showWarning: boolean;
  removeDisabled: boolean;
  onRemove: () => void;
  onGuestSearch: (value: SelectedOption[], data: Visitor[] | null) => void;
}

// Component
export const VisitFormGuestsActions = ({
  visitorId,
  removeDisabled,
  showWarning,
  onGuestSearch,
  onRemove,
}: VisitFormGuestsActionsProps) => (
  <Space>
    {/* Warning */}
    {showWarning && (
      <StyledTooltip title={<Translated id="visitors.missingInfo" />} placement="top">
        <WarningFilled className="gx-text-warning gx-fs-xl" style={{ marginTop: 2, height: 20 }} />
      </StyledTooltip>
    )}

    {/* Search */}
    <VisitFormGuestsSearch onGuestSearch={onGuestSearch} />

    {/* Profile Link */}
    {visitorId && (
      <Link target="_blank" to={`/Visitors/${visitorId}`}>
        <StyledTooltip title={<Translated id="visitors.viewDetails" />} placement="top">
          <Button
            ghost
            size="small"
            type="primary"
            shape="circle"
            style={{ marginBottom: 0 }}
            icon={<UserOutlined />}
            onClick={(e) => e.stopPropagation()}
          />
        </StyledTooltip>
      </Link>
    )}

    {/* Remove */}
    <StyledTooltip title={<Translated id="visitors.remove" />} placement="top">
      <Button
        danger
        size="small"
        shape="circle"
        disabled={removeDisabled}
        style={{ marginBottom: 0 }}
        icon={<DeleteOutlined />}
        onClick={(e) => {
          e.stopPropagation();
          onRemove();
        }}
      />
    </StyledTooltip>
  </Space>
);
