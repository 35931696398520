import React from 'react';
import { Skeleton } from 'antd';

interface ButtonSkeletonProps {
  width: number;
  height: number;
}

export const ButtonSkeleton = ({ width, height }: ButtonSkeletonProps) => (
  <Skeleton.Button active style={{ width: `${width}px`, height: `${height}px`, lineHeight: `${height}px` }} />
);
