// Types
import { SignalREvent } from '../../models/SignalREvent';

// Actions
import { validationFailed } from './Generic.redux';

// Exceptions
const SignalRExceptions = {
  ValidationFailed: `ValidationFailedIntegrationException`,
};

// Events
export const configureSignalREvents = ({ hubConnection, store, history }: SignalREvent) => {
  hubConnection.on(SignalRExceptions.ValidationFailed, (msg) => {
    store.dispatch(validationFailed({ history, msg }));
  });
};
