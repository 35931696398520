import React from 'react';

import { TableColumn } from '../../types/Table';
import { Employee } from '../../models/Employee';
import { Translated } from '../../components/UI/Core';

export const employeeTableColumns: Array<TableColumn<Employee>> = [
  {
    Header: <Translated id="employees.id" />,
    id: 'Id',
    accessor: 'Id',
    isVisible: false,
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: <Translated id="employees.fullName" />,
    id: 'FullName',
    accessor: 'FullName',
  },
  {
    Header: <Translated id="employees.email" />,
    id: 'Email',
    accessor: 'Email',
  },
  {
    Header: <Translated id="employees.phone" />,
    id: 'PhoneNumber',
    accessor: 'PhoneNumber',
    disableSortBy: true,
  },
  {
    Header: <Translated id="employees.employeeId" />,
    id: 'EmployeeId',
    accessor: 'EmployeeId',
    isVisible: false,
  },
];
