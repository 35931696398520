import React from 'react';
import { Empty, Select, Spin } from 'antd';
import styled from 'styled-components';

// Hooks
import { OptionsProviderProps } from '../../../models/OptionsProviderProps';
import { SelectedOption } from '../../../models/SelectedOption';

// Styled
const StyledSpin = styled(Spin)`
  display: flex;
  justify-content: center;
  margin: 4px;
  align-items: center;
`;

// Props
export interface SearchSelectProps<T> {
  initialValue?: Array<SelectedOption>;
  selectOptionsProvider: (initialValue?: Array<SelectedOption>) => OptionsProviderProps<T>;
  onValueChange: (value: SelectedOption[], data: Array<T> | null) => void;
}

// Component
export const SearchSelect = <T extends object>({
  initialValue,
  selectOptionsProvider,
  onValueChange,
}: SearchSelectProps<T>) => {
  // Options Hook
  const { data, selected, options, search, mode, pending, locales, setSelected, setSearch } =
    selectOptionsProvider(initialValue);

  // Event Handlers
  const onChange = (value: SelectedOption[]) => {
    setSelected(value);
    onValueChange(value, data);
    setSearch('');
  };
  const onSelect = () => {
    setSearch('');
  };

  // Render
  const renderEmpty = () => {
    if (pending) return <StyledSpin size="small" />;
    if (!search || search.length < 1) return <Empty description={locales.description} />;
    return <Empty description={locales.empty} />;
  };

  return (
    <Select
      mode={mode}
      // Basic Props
      options={options}
      value={selected}
      onChange={onChange}
      onSelect={onSelect}
      onDropdownVisibleChange={onSelect}
      allowClear
      placeholder={locales.placeholder}
      // Search Props
      showSearch
      filterOption={false}
      onSearch={setSearch}
      searchValue={search}
      // Loading Props
      notFoundContent={renderEmpty()}
    />
  );
};
