import React from 'react';
import { message } from 'antd';
import { Slide, toast, ToastContent, ToastOptions } from 'react-toastify';
import { NotificationCollapse } from '../../components/UI/Collapse/NotificationCollapse';
import { Translated } from '../../components/UI/Core';

export enum NotificationType {
  INFO = 'info',
  SUCCESS = 'success',
  WARN = 'warn',
  ERROR = 'error',
}

const defaultToastOptions: ToastOptions = {
  position: 'bottom-right',
  transition: Slide,
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  style: {
    padding: '10px 12px',
  },
};
const priorityToastOptions: ToastOptions = {
  autoClose: 21000,
};

class NotificationService {
  static notify = (content: ToastContent, type: NotificationType) => {
    switch (type) {
      case NotificationType.INFO:
        toast.info(content, defaultToastOptions);
        break;
      case NotificationType.SUCCESS:
        toast.success(content, defaultToastOptions);
        break;
      case NotificationType.WARN:
        toast.warn(content, defaultToastOptions);
        break;
      case NotificationType.ERROR:
        toast.error(content, { ...defaultToastOptions, ...priorityToastOptions });
        break;
      default:
        toast(content, defaultToastOptions);
        break;
    }
  };

  static message = (content: React.ReactNode | string, type: NotificationType) => {
    switch (type) {
      case NotificationType.INFO:
        message.info(content);
        break;
      case NotificationType.SUCCESS:
        message.success(content);
        break;
      case NotificationType.WARN:
        message.warn(content);
        break;
      case NotificationType.ERROR:
        message.error(content, 12000);
        break;
      default:
        message.info(content);
        break;
    }
  };

  // Success helper methods
  static showSuccess = (id: string) => this.notify(<Translated id={id} />, NotificationType.SUCCESS);

  static showSuccessWithContent = (id: string, content: string) =>
    this.notify(<NotificationCollapse header={<Translated id={id} />} content={content} />, NotificationType.SUCCESS);

  static showSuccessWithValues = (id: string, values: object) =>
    this.notify(<Translated id={id} values={values} />, NotificationType.SUCCESS);

  static showSuccessWithContentAndValues = (id: string, content: string, values: object) =>
    this.notify(
      <NotificationCollapse header={<Translated id={id} values={values} />} content={content} />,
      NotificationType.SUCCESS
    );

  // Error helper methods
  static showError = (id: string) => this.notify(<Translated id={id} />, NotificationType.ERROR);

  static showErrorWithContent = (id: string, content: string) =>
    this.notify(<NotificationCollapse header={<Translated id={id} />} content={content} />, NotificationType.ERROR);

  static showErrorWithValues = (id: string, values: object) =>
    this.notify(<Translated id={id} values={values} />, NotificationType.ERROR);

  static showErrorWithContentAndValues = (id: string, content: string, values: object) =>
    this.notify(
      <NotificationCollapse header={<Translated id={id} values={values} />} content={content} />,
      NotificationType.ERROR
    );
}

export const notificationService = NotificationService;
