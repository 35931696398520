import React from 'react';
import { Employee } from '../../models/Employee';
import { Translated } from '../UI/Core';
import { ButtonSkeleton } from '../UI/Skeleton/ButtonSkeleton';

import { Widget } from '../UI/Widget/Widget';

interface ContactCardProps {
  employee: Employee | null;
  pending: boolean;
}

export const ContactCard = ({ employee, pending }: ContactCardProps) => {
  return (
    <Widget title={<Translated id="app.contact" />} styleName="gx-card-profile-sm">
      {/* Name */}
      <div className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list">
        <div className="gx-mr-3">
          <i className="icon icon-avatar gx-fs-xxl gx-text-grey" />
        </div>
        <div className="gx-media-body">
          <span className="gx-mb-0 gx-text-grey gx-fs-sm">
            <Translated id="employees.fullName" />
          </span>
          <div className="gx-mb-0">
            <span>{pending ? <ButtonSkeleton width={120} height={10} /> : employee?.FullName}</span>
          </div>
        </div>
      </div>

      {/* Email */}
      <div className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list">
        <div className="gx-mr-3">
          <i className="icon icon-email gx-fs-xxl gx-text-grey" />
        </div>
        <div className="gx-media-body">
          <span className="gx-mb-0 gx-text-grey gx-fs-sm">
            <Translated id="employees.email" />
          </span>
          <div className="gx-mb-0">
            <span className="gx-link">{pending ? <ButtonSkeleton width={120} height={10} /> : employee?.Email}</span>
          </div>
        </div>
      </div>

      {/* Phone */}
      <div className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list">
        <div className="gx-mr-3">
          <i className="icon icon-phone gx-fs-xxl gx-text-grey" />
        </div>
        <div className="gx-media-body">
          <span className="gx-mb-0 gx-text-grey gx-fs-sm">
            <Translated id="employees.phone" />
          </span>
          <div className="gx-mb-0">
            <span className="gx-link">
              {pending ? <ButtonSkeleton width={120} height={10} /> : employee?.PhoneNumber}
            </span>
          </div>
        </div>
      </div>
    </Widget>
  );
};
