/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Models
import { IdentifierFormValues } from '../../../models/IdentifierFormValues';
import { SignalRAction } from '../../../models/SignalRAction';

// State
interface ReservationIdentifiersState {
  updating: boolean;
  error: boolean;
}

const initialState: ReservationIdentifiersState = {
  updating: false,
  error: false,
};

// Name
const storeName = '@RESERVATION_IDENTIFIERS';

// Redux Actions|Reducers
const reservationIdentifiersSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    // Assign
    assignIdentifier: (state, _action: PayloadAction<{ formValues: IdentifierFormValues }>) => {
      state.updating = true;
      state.error = false;
    },
    identifierAssigned: (state, _action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    identifierNotAssigned: (state, _action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },
    // Remove
    removeIdentifier: (state, _action: PayloadAction<{ formValues: IdentifierFormValues }>) => {
      state.updating = true;
      state.error = false;
    },
    identifierRemoved: (state, _action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    identifierNotRemoved: (state, _action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },
    identifiersNotRemoved: (state, _action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },
  },
});

// Export Actions
export const {
  // Assign
  assignIdentifier,
  identifierAssigned,
  identifierNotAssigned,
  // Remove
  removeIdentifier,
  identifierRemoved,
  identifierNotRemoved,
  identifiersNotRemoved,
} = reservationIdentifiersSlice.actions;

// Export Reducer
export default reservationIdentifiersSlice.reducer;
