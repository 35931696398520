import { useCallback, useEffect, useMemo } from 'react';
import { usePrevious } from 'react-use';

// Hooks
import { useData } from './useData';

// Data
import { ApiEndpoints } from '../../data/ApiEndpoints';
import { notificationService } from '../../services/Notifications/NotificationService';

export const useTenantData = () => {
  // Data
  const { data, fetch, pending, error } = useData(ApiEndpoints.tenant.data, null);

  // Validation
  const currentId = useMemo(() => data?.Id, [data]);
  const previousId = usePrevious(data?.Id);

  // Props
  const getTenantDataProps = useCallback(
    () => ({
      tenantData: data,
      loading: pending,
    }),
    [data, pending]
  );

  // Effects
  useEffect(() => {
    // Fetch on initializing
    fetch();
  }, [fetch]);

  // Effects
  useEffect(() => {
    // Show error when current tenant is empty
    if (
      currentId === '00000000-0000-0000-0000-000000000000' &&
      currentId !== previousId &&
      pending === false &&
      !error
    ) {
      notificationService.showError('appSettings.notifications.invalidEnvironment');
    }
  }, [currentId, previousId, pending, error]);

  // Return Hook
  return useMemo(
    () => ({
      getTenantDataProps,
    }),
    [getTenantDataProps]
  );
};
