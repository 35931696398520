import React, { useState } from 'react';
import { Avatar, Button, Divider, Form, FormInstance, Typography } from 'antd';
import { PlusOutlined, TeamOutlined } from '@ant-design/icons';

// eslint-disable-next-line import/no-extraneous-dependencies
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';

// Models
import { VisitFormValues } from '../../../../models/Visits/VisitFormValues';

// Components
import { Translated } from '../../../UI/Core';
import { Flex } from '../../../UI/Base';
import { FormColumn } from '../../../UI/Form/FormColumn';
import { VisitFormGuestsCollapse } from './VisitFormGuestsCollapse';

// Components
const { Title } = Typography;

// Props
export interface VisitFormGuestsProps<T> {
  form: FormInstance<T>;
  formData: ValidateErrorEntity<unknown> | undefined;
  initialValues?: VisitFormValues;
}

// Component
export const VisitFormGuests = <T extends object>({ form, formData, initialValues }: VisitFormGuestsProps<T>) => {
  // Collapse State
  const [activeKey, setActiveKey] = useState<number | undefined>(initialValues?.Id === '' ? 0 : undefined);

  // Event Handlers
  const onAdd = (add: () => void, key: number) => {
    add();
    setActiveKey(key);
  };
  const onRemove = (remove: (index: number) => void, key: number) => {
    remove(key);
    setActiveKey(undefined);
  };

  // Render Guests Form
  return (
    <FormColumn padRightNone xs={24} md={13}>
      {/* Header */}
      <Flex alignItems="center" className="gx-mb-2">
        <Avatar className="avatar-border-color" shape="square" icon={<TeamOutlined />} style={{ marginRight: 8 }} />
        <Title level={4} className="gx-mb-0" style={{ fontWeight: 500 }}>
          <Translated id="visits.form.guests" />
        </Title>
      </Flex>
      <Divider />

      <Form.List name="Guests">
        {(fields, { add, remove }) => (
          <>
            {/* Add Button */}
            <Form.Item>
              <Button
                className="gx-mb-0"
                type="dashed"
                onClick={() => onAdd(add, fields.length)}
                block
                icon={<PlusOutlined />}
              >
                <Translated id="app.add" />
              </Button>
            </Form.Item>

            {/* Collapse */}
            <VisitFormGuestsCollapse
              form={form}
              formData={formData}
              fields={fields}
              activeKey={activeKey}
              setActiveKey={setActiveKey}
              onRemove={(key) => onRemove(remove, key)}
            />
          </>
        )}
      </Form.List>
    </FormColumn>
  );
};
