import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'antd';
import styled from 'styled-components';

// Props
import { AssignConfirmProps } from './useEmployeeAccessProfilesReduxState';

// Models
import { AccessProfile } from '../../../models/AccessProfile';
import { Employee } from '../../../models/Employee';

// Components
import { Translated } from '../../../components/UI/Core';
import { MultiSelect, MultiSelectItem } from '../../../components/UI/MultiSelect/MultiSelect';

// Props
interface EmployeeAccessProfilesFormProps {
  employee: Employee | null;
  accessProfiles: Array<AccessProfile> | null;
  showAssignConfirm: (props: AssignConfirmProps) => void;
}

// Styled
const StyledButton = styled(Button)`
  margin-bottom: 0;
`;

// Hook
export const useEmployeeAccessProfilesForm = ({
  employee,
  accessProfiles,
  showAssignConfirm,
}: EmployeeAccessProfilesFormProps) => {
  // State
  const [accessProfileIds, setAccessProfileIds] = useState<Array<string>>([]);

  // Get the Keys (Id) from all Companies of a Location
  useEffect(() => {
    setAccessProfileIds(
      accessProfiles
        ?.filter((accessProfile) =>
          employee?.AccessProfiles?.some((employeeAccessProfile) => employeeAccessProfile.Id === accessProfile.Id)
        )
        ?.map((x) => x.Id) ?? []
    );
  }, [accessProfiles, employee]);

  // Components
  const EmployeeAccessProfilesManagementForm = useCallback(() => {
    // Get all Companies as MultiSelectItems
    const dataSource =
      accessProfiles?.map<MultiSelectItem>((company) => ({
        key: company.Id,
        title: company.Name,
        description: '',
      })) ?? [];

    return (
      <>
        <MultiSelect dataSource={dataSource} targetKeys={accessProfileIds} setTargetKeys={setAccessProfileIds} />
        <StyledButton
          type="primary"
          onClick={() =>
            employee &&
            showAssignConfirm({
              formValues: {
                PersonId: employee?.Id,
                AccessProfileIds: accessProfileIds,
              },
              employee,
            })
          }
        >
          <Translated id="form.confirmButton" />
        </StyledButton>
      </>
    );
  }, [accessProfiles, employee, showAssignConfirm, accessProfileIds, setAccessProfileIds]);

  return useMemo(
    () => ({
      AccessProfilesManagementForm: EmployeeAccessProfilesManagementForm,
    }),
    [EmployeeAccessProfilesManagementForm]
  );
};
