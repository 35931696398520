import React from 'react';
import { Col, Row } from 'antd';
import styled from 'styled-components';

// Hooks
import { useVisitorsList } from '../../hooks/Visitors/useVisitorsList';
import { useVisitorDashboard } from '../../hooks/Visitors/Dashboard/useVisitorDashboard';

// Components
import { Container } from '../../components/UI/Base';
import { Table } from '../../components/UI/Table/Table';
import { FormDrawer } from '../../components/Visitors';
import { Spinner } from '../../components/UI/Spinner/Spinner';
import { BreadcrumbMenu } from '../../components/UI/Breadcrumb/BreadcrumbMenu';
import { Widget } from '../../components/UI/Widget/Widget';
import { VisitorStats } from '../../components/Visitors/Dashboard/VisitorStats';
import { VisitorMetrics } from '../../components/Visitors/Dashboard/VisitorMetrics';
import { VisitorMonitor } from '../../components/Visitors/Dashboard/VisitorMonitor';

// Styled
const VisitorsWidget = styled(Widget)`
  margin-top: 20px;
  margin-bottom: 0px;

  & .ant-card-body {
    padding: 16px 24px;
  }
`;

// Component
export const VisitorsPage = () => {
  const {
    updating,
    getBreadcrumbMenuProps,
    getVisitorsDataProps,
    getVisitorsTableProps,
    getVisitorsCrudProps,
    getVisitorFormDrawerProps,
    getVisitFormDrawerProps,
  } = useVisitorsList();

  const { getVisitorStatsProps, getVisitorMetricsProps, getVisitorMonitorProps } = useVisitorDashboard();

  return (
    <Spinner spinning={updating}>
      <BreadcrumbMenu {...getBreadcrumbMenuProps()} />

      <Container>
        {/* Visitor Dashboard */}
        <VisitorsWidget styleName="gx-card-widget">
          <Row>
            <VisitorStats {...getVisitorStatsProps()} />
            <VisitorMetrics {...getVisitorMetricsProps()} />
            <VisitorMonitor {...getVisitorMonitorProps()} />
          </Row>
        </VisitorsWidget>

        {/* Visitor Table */}
        <Row>
          <Col span={24}>
            <Table {...getVisitorsDataProps()} {...getVisitorsTableProps()} {...getVisitorsCrudProps()} />
          </Col>
        </Row>
      </Container>

      {/* Visitor & Visit Form */}
      <FormDrawer {...getVisitorFormDrawerProps()} />
      <FormDrawer {...getVisitFormDrawerProps()} />
    </Spinner>
  );
};
