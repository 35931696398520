import { useEffect, useMemo, useState } from 'react';
import { DefaultOptionType } from 'antd/lib/select';
import { useDebounce, usePrevious } from 'react-use';

// Components
import { useIntl } from 'react-intl';
import { useData } from '../App/useData';
import { ApiEndpoints } from '../../data/ApiEndpoints';
import { SelectedOption } from '../../models/SelectedOption';
import { OptionsProviderProps, SelectMode } from '../../models/OptionsProviderProps';
import { Visitor } from '../../models/Visits/Visitor';

// Hook
export const useVisitGuestSelect = (defaultValue?: Array<SelectedOption>, mode: SelectMode = undefined) => {
  // Intl
  const intl = useIntl();

  // State
  const [selected, setSelected] = useState<Array<SelectedOption>>(defaultValue ?? []);
  const [options, setOptions] = useState<DefaultOptionType[]>([]);
  const [pending, setPending] = useState(true);
  const [search, setSearch] = useState('');
  const prevSearch = usePrevious(search);

  // Data
  const { data: visitors, fetch: fetchData } = useData(ApiEndpoints.visitors.list, null);
  const locales = useMemo(
    () => ({
      placeholder: intl.formatMessage({ id: 'visits.form.guests.placeholder' }),
      description: intl.formatMessage({ id: 'visits.form.guests.description' }),
      empty: intl.formatMessage({ id: 'visits.form.guests.empty' }),
    }),
    [intl]
  );

  // Validation
  const isValid = (value: string) => value && value.length >= 1;

  // Fetch data with debounce
  useDebounce(
    () =>
      isValid(search) &&
      fetchData({ pageSize: 5, sortedBy: 'FullName', search: JSON.stringify([{ id: 'FullName', value: search }]) }),
    500,
    [search]
  );

  // Effects
  useEffect(() => {
    // When search changes, set pending to true and clear the options list
    if (search !== prevSearch && pending === false) {
      if (isValid(search)) setPending(true);
      setOptions([]);
    }
  }, [search, prevSearch, pending]);

  useEffect(() => {
    // When visitors change, update the select options and set pending to false
    setOptions(
      visitors?.map((visitor) => ({
        label: visitor.FullName,
        value: visitor.Id,
      })) ?? []
    );
    setPending(false);
  }, [visitors]);

  // Hook response
  return useMemo<OptionsProviderProps<Visitor>>(
    () => ({
      data: visitors,
      selected,
      options,
      search,
      mode,
      pending,
      locales,
      setSelected,
      setSearch,
    }),
    [visitors, selected, options, search, mode, pending, locales, setSelected, setSearch]
  );
};
