import React from 'react';
import { CellProps } from 'react-table';
import { Button } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';

export const TableExpandableCell = <T extends object>({ row }: CellProps<T>) => {
  if (row.canExpand)
    return (
      <Button
        size="small"
        className="gx-mb-1"
        {...row.getToggleRowExpandedProps({ style: { paddingLeft: `${row.depth * 2}rem` } })}
        icon={row.isExpanded ? <MinusOutlined /> : <PlusOutlined />}
        onClick={(e) => {
          e.stopPropagation();
          row.toggleRowExpanded();
        }}
      />
    );

  if (row.depth)
    return (
      <Button
        disabled
        size="small"
        className="gx-mb-1"
        icon="↳"
        {...row.getToggleRowExpandedProps({ style: { paddingLeft: `${(row.depth - 1) * 2}rem` } })}
      />
    );

  return null;
};
