import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

// Services
import { ApiRequestType, apiService } from '../../../services/ApiService/ApiService';
import { notificationService } from '../../../services/Notifications/NotificationService';

// Models
import { SignalRAction } from '../../../models/SignalRAction';

// Redux
import {
  downloadEmployeeTemplate,
  employeeNotUploaded,
  employeeTemplateDownloaded,
  employeeTemplateNotDownloaded,
  employeeUploaded,
  setResultCsvData,
  uploadEmployee,
} from './EmployeeImport.redux';

// **************************************************
// ********************* DOWNLOAD *******************

// Worker Sagas
function* downloadEmployeeTemplateSaga() {
  yield takeEvery(downloadEmployeeTemplate.type, downloadEmployeeTemplateRequest);
}

function* employeeTemplateDownloadedSaga() {
  yield takeLatest(employeeTemplateDownloaded.type, downloadEmployeeTemplateResponse);
}

function* employeeTemplateNotDownloadedSaga() {
  yield takeLatest(employeeTemplateNotDownloaded.type, downloadEmployeeTemplateError);
}

// Request
function* downloadEmployeeTemplateRequest() {
  try {
    const payload: unknown = yield apiService.execute({ method: 'GET', url: 'Employees/Import' });
    yield put({ type: employeeTemplateDownloaded.type, payload });
  } catch ({ message }) {
    yield put({ type: employeeTemplateNotDownloaded.type, payload: { msg: { message } } });
  }
}

// Response
function* downloadEmployeeTemplateResponse(action: PayloadAction<{ data: string }>) {
  const {
    payload: { data },
  } = action;
  if (data) {
    // Create blob link to download
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `Template.csv`);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode?.removeChild(link);

    // Show notification
    if (data.length <= 50) {
      notificationService.showSuccess('import.downloadTemplateSuccess');
    }
  } else {
    yield put({ type: employeeTemplateNotDownloaded.type, payload: { msg: { message: 'No data downloaded' } } });
  }
}

// Error
function downloadEmployeeTemplateError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('import.downloadTemplateFailed', action?.payload?.msg.message);
}

// **************************************************
// ********************* UPLOAD *********************

// Worker Sagas
function* uploadEmployeeSaga() {
  yield takeEvery(uploadEmployee.type, uploadEmployeeRequest);
}

function* employeeUploadedSaga() {
  yield takeLatest(employeeUploaded.type, uploadEmployeeResponse);
}

function* employeeNotUploadedSaga() {
  yield takeLatest(employeeNotUploaded.type, uploadEmployeeError);
}

// Request
function* uploadEmployeeRequest(action: PayloadAction<{ formData: FormData }>) {
  try {
    const {
      payload: { formData },
    } = action;
    yield apiService.execute({
      url: 'Employees/Import',
      method: ApiRequestType.POST,
      data: formData,
    });
  } catch ({ message }) {
    const msg = message as string;

    if (msg.includes('400')) {
      notificationService.showErrorWithContent('employees.notifications.importFailed', msg);
    }
    yield put({ type: employeeNotUploaded.type, payload: { msg: { message } } });
  }
}

// Response
function* uploadEmployeeResponse(action: PayloadAction<SignalRAction>) {
  const csvData = action?.payload?.msg?.csvResultBase64;

  if (csvData !== null && csvData !== undefined) {
    yield put({ type: setResultCsvData.type, payload: { data: csvData } });
    notificationService.showSuccess('employees.notifications.importSuccess');
  }
}

// Error
function* uploadEmployeeError(action: PayloadAction<SignalRAction>) {
  const csvData = action?.payload?.msg?.csvResultBase64;

  if (csvData !== null && csvData !== undefined) {
    yield put({ type: setResultCsvData.type, payload: { data: csvData } });
    notificationService.showErrorWithContent('employees.notifications.importFailed', action?.payload?.msg.message);
  }
}

// **************************************************
// ********************* EXPORT SAGAS ***************

export default function* sagas() {
  yield all([
    // Download
    downloadEmployeeTemplateSaga(),
    employeeTemplateDownloadedSaga(),
    employeeTemplateNotDownloadedSaga(),
    // Upload
    uploadEmployeeSaga(),
    employeeUploadedSaga(),
    employeeNotUploadedSaga(),
  ]);
}
