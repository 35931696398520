import React, { useCallback, useMemo } from 'react';
import { Button, Divider, Form, Input, Space, Switch } from 'antd';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { useIntl } from 'react-intl';

// Props
import FormItemLabel from 'antd/lib/form/FormItemLabel';
import {
  AssignConfirmProps,
  RemoveConfirmProps,
  ToggleIdentifierStatusProps,
} from './useEmployeeIdentifiersReduxState';

// Models
import { Identifier } from '../../../models/Identifier';
import { FormAccessProfile, IdentifierFormValues } from '../../../models/IdentifierFormValues';
import { Employee } from '../../../models/Employee';
import { IdentifierType } from '../../../models/IdentifierType';

// Components
import { Translated } from '../../../components/UI/Core';

// Props
interface EmployeeIdentifiersFormProps {
  updating: boolean;
  employee: Employee | null;
  showAssignConfirm: (props: AssignConfirmProps) => void;
  showRemoveConfirm: (props: RemoveConfirmProps) => void;
  toggleIdentifier: (props: ToggleIdentifierStatusProps) => void;
}

interface EmployeeIdentifiersManagementFormProps {
  identifierType: IdentifierType;
}

// Components
const { Item } = Form;

// Styled
const NoSidePaddingFormItem = styled(Item)`
  margin-bottom: 8px;

  & .ant-form-item-control {
    padding-left: 0;
    padding-right: 0;
  }
`;
const NoBottomMarginFormItem = styled(Item)`
  margin-bottom: 0;
`;
const StyledButton = styled(Button)`
  margin-bottom: 0;
`;
const StyledSpace = styled(Space)`
  width: 100%;
  margin-bottom: 0px;

  & .ant-space-item {
    width: 100%;
  }
`;

// Hook
export const useEmployeeIdentifiersForm = ({
  updating,
  employee,
  showAssignConfirm,
  showRemoveConfirm,
  toggleIdentifier,
}: EmployeeIdentifiersFormProps) => {
  // Intl
  const intl = useIntl();

  // Components
  const EmployeeIdentifiersManagementForm = useCallback(
    ({ identifierType }: EmployeeIdentifiersManagementFormProps) => {
      // Input Labels
      const displayLabel = isEmpty(identifierType.DisplayLabel) ? 'Name' : identifierType.DisplayLabel;
      const valueLabel = isEmpty(identifierType.ValueLabel) ? 'Value' : identifierType.ValueLabel;

      // Employee Identifiers
      const employeeIdentifier = employee?.Identifiers.find(
        (identifier: Identifier) => identifier.IdentifierType.Id === identifierType.Id
      );

      // Form Values
      const initialValues: IdentifierFormValues = {
        PersonId: employee?.Id,
        Identifier: {
          IdentifierId: employeeIdentifier?.Id,
          IdentifierTypeId: identifierType.Id,
          Name: employeeIdentifier?.Name,
          Value: employeeIdentifier?.Value,
          Enabled: employeeIdentifier?.Active ?? true,
        },
        AccessProfiles:
          employee?.AccessProfiles.map<FormAccessProfile>((ap) => ({
            InternalId: ap.Id,
            Name: ap.Name,
            Enabled: ap.Enabled,
            ValidFrom: ap.ValidFrom,
            ValidTo: ap.ValidTo,
          })) ?? [],
      };

      return (
        <Form
          layout="vertical"
          initialValues={initialValues}
          onFinish={(values: IdentifierFormValues) =>
            employee && showAssignConfirm({ formValues: { ...initialValues, ...values }, employee })
          }
        >
          {/* Hidden Values */}
          <Item name="PersonId" hidden>
            <Input />
          </Item>
          <Item name={['Identifier', 'IdentifierId']} hidden>
            <Input />
          </Item>
          <Item name={['Identifier', 'IdentifierTypeId']} hidden>
            <Input />
          </Item>
          <Item name={['Identifier', 'Enabled']} hidden>
            <Input />
          </Item>

          {/* Form Values */}
          <StyledSpace align="start">
            <NoSidePaddingFormItem
              name={['Identifier', 'Value']}
              label={valueLabel}
              rules={[
                {
                  required: true,
                  message: intl.formatMessage(
                    { id: 'identifiers.form.warnings.value' },
                    { label: valueLabel.toLowerCase() }
                  ),
                },
              ]}
            >
              <Input />
            </NoSidePaddingFormItem>

            <NoSidePaddingFormItem
              name={['Identifier', 'Name']}
              label={displayLabel}
              rules={[
                {
                  required: true,
                  message: intl.formatMessage(
                    { id: 'identifiers.form.warnings.name' },
                    { label: displayLabel.toLowerCase() }
                  ),
                },
              ]}
            >
              <Input />
            </NoSidePaddingFormItem>
          </StyledSpace>

          <Divider />

          {employeeIdentifier && (
            <>
              <Space>
                <FormItemLabel label={<Translated id="identifiers.form.status" />} prefixCls="cls" />
                <Switch
                  loading={updating}
                  checked={employeeIdentifier.Active}
                  onClick={() => employee && toggleIdentifier({ formValues: initialValues, employee })}
                  checkedChildren={<Translated id="identifiers.form.active" />}
                  unCheckedChildren={<Translated id="identifiers.form.disabled" />}
                />
              </Space>

              <Divider />
            </>
          )}

          {/* Action Buttons */}
          <NoBottomMarginFormItem>
            <StyledButton type="primary" htmlType="submit">
              <Translated id="form.confirmButton" />
            </StyledButton>

            {employeeIdentifier && (
              <StyledButton
                danger
                onClick={() => employee && showRemoveConfirm({ formValues: initialValues, employee })}
              >
                <Translated id="form.removeButton" />
              </StyledButton>
            )}
          </NoBottomMarginFormItem>
        </Form>
      );
    },
    [updating, employee, intl, showAssignConfirm, showRemoveConfirm, toggleIdentifier]
  );

  return useMemo(
    () => ({
      IdentifierManagementForm: EmployeeIdentifiersManagementForm,
    }),
    [EmployeeIdentifiersManagementForm]
  );
};
