// Types
import { SignalREvent } from '../../models/SignalREvent';

// Actions
import {
  pacsVisitorDeleted,
  pacsVisitorNotDeleted,
  pacsVisitorNotUpdated,
  pacsVisitorUpdated,
  visitorCreated,
  visitorDeleted,
  visitorNotCreated,
  visitorNotDeleted,
  visitorNotUpdated,
  visitorsDeleted,
  visitorsNotDeleted,
  visitorUpdated,
} from './Visitors.redux';

// Events
const SignalREvents = {
  visitorCreated: `VisitorCreatedIntegrationEvent`,
  visitorUpdated: `VisitorUpdatedIntegrationEvent`,
  visitorDeleted: `VisitorDeletedIntegrationEvent`,
  visitorsDeleted: `VisitorsDeletedIntegrationEvent`,
  pacsVisitorDeleted: `PACSVisitorDeletedIntegrationEvent`,
  pacsVisitorUpdated: `PACSVisitorUpdatedIntegrationEvent`,
};

// Exceptions
const SignalRExceptions = {
  visitorNotCreated: `VisitorNotCreatedIntegrationException`,
  visitorNotUpdated: `VisitorNotUpdatedIntegrationException`,
  visitorNotDeleted: `VisitorNotDeletedIntegrationException`,
  visitorsNotDeleted: `VisitorsNotDeletedIntegrationException`,
  pacsVisitorNotDeleted: `PACSVisitorNotDeletedIntegrationException`,
  pacsVisitorNotUpdated: `PACSVisitorNotUpdatedIntegrationException`,
};

// Events
export const configureSignalREvents = ({ hubConnection, store, history }: SignalREvent) => {
  // Create
  hubConnection.on(SignalREvents.visitorCreated, (msg) => {
    store.dispatch(visitorCreated({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.visitorNotCreated, (msg) => {
    store.dispatch(visitorNotCreated({ history, msg }));
  });

  // Update
  hubConnection.on(SignalREvents.visitorUpdated, (msg) => {
    store.dispatch(visitorUpdated({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.visitorNotUpdated, (msg) => {
    store.dispatch(visitorNotUpdated({ history, msg }));
  });

  hubConnection.on(SignalREvents.pacsVisitorUpdated, (msg) => {
    store.dispatch(pacsVisitorUpdated({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.pacsVisitorNotUpdated, (msg) => {
    store.dispatch(pacsVisitorNotUpdated({ history, msg }));
  });

  // Delete
  hubConnection.on(SignalREvents.visitorDeleted, (msg) => {
    store.dispatch(visitorDeleted({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.visitorNotDeleted, (msg) => {
    store.dispatch(visitorNotDeleted({ history, msg }));
  });

  hubConnection.on(SignalREvents.pacsVisitorDeleted, (msg) => {
    store.dispatch(pacsVisitorDeleted({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.pacsVisitorNotDeleted, (msg) => {
    store.dispatch(pacsVisitorNotDeleted({ history, msg }));
  });

  // Delete All
  hubConnection.on(SignalREvents.visitorsDeleted, (msg) => {
    store.dispatch(visitorsDeleted({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.visitorsNotDeleted, (msg) => {
    store.dispatch(visitorsNotDeleted({ history, msg }));
  });
};
