/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */

import {createSlice, PayloadAction} from '@reduxjs/toolkit';

// Models
import {SignalRAction} from '../../models/SignalRAction';

// State
interface AccountState {
  updating: boolean;
  error: boolean;
}

const initialState: AccountState = {
  updating: false,
  error: false,
};

// Name
const storeName = '@GENERIC';

// Redux Actions|Reducers
const accountSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    validationFailed: (state, _action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },
  },
});

// Export Actions
export const {
  // Assign
  validationFailed,
} = accountSlice.actions;

// Export Reducer
export default accountSlice.reducer;
