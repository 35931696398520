// Types
import { SignalREvent } from '../../models/SignalREvent';
import { OperationType } from '../../models/SignalRAction';

// Actions
import { actionExecuted, actionNotExecuted } from './Actions.redux';

// Events
const SignalREvents = {
  ActionOperation: `ActionOperationIntegrationEvent`,
};

// Exceptions
const SignalRExceptions = {
  ActionOperationFailed: `ActionOperationFailedIntegrationException`,
};

// Events
export const configureSignalREvents = ({ hubConnection, store, history }: SignalREvent) => {
  // Update
  hubConnection.on(SignalREvents.ActionOperation, (msg) => {
    const op = msg.operation as OperationType;
    if (op === OperationType.Other) {
      store.dispatch(actionExecuted({ history, msg }));
    }
  });

  hubConnection.on(SignalRExceptions.ActionOperationFailed, (msg) => {
    const op = msg.operation as OperationType;
    if (op === OperationType.Other) {
      store.dispatch(actionNotExecuted({ history, msg }));
    }
  });
};
