import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

// Services
import { ApiRequestType, apiService } from '../../services/ApiService/ApiService';
import { notificationService } from '../../services/Notifications/NotificationService';

// Models
import { SignalRAction } from '../../models/SignalRAction';

// Redux
import { accountChanged, accountNotChanged, changeAccount } from './Account.redux';
import { AccountFormValues } from '../../models/AccountFormValues';
import { authService } from '../../services/ApiAuthorization/AuthorizeService';
import { navigateToUrl } from '../../constants/Utils/Urls';

// **************************************************
// ********************* ASSIGN *********************

// Worker Sagas
function* changeAccountSaga() {
  yield takeEvery(changeAccount.type, assignAccountRequest);
}

function* accountChangedSaga() {
  yield takeLatest(accountChanged.type, assignAccountResponse);
}

function* accountNotChangedSaga() {
  yield takeLatest(accountNotChanged.type, assignAccountError);
}

// Request
function* assignAccountRequest(action: PayloadAction<{ formValues: AccountFormValues }>) {
  try {
    const {
      payload: { formValues },
    } = action;
    yield apiService.execute({
      url: `IdentityUsers/Tenants/Logon`,
      method: ApiRequestType.POST,
      data: formValues,
    });
  } catch ({ message }) {
    yield put({ type: accountNotChanged.type, payload: { msg: { message } } });
  }
}

// Response
async function assignAccountResponse() {
  // Refreshes the token and navigates to the home page
  if (await authService.getAccessToken(true)) {
    notificationService.showSuccess('account.notifications.tenantUpdated');
    navigateToUrl('/', 1000);
  } else {
    notificationService.showErrorWithContent(
      'account.notifications.updateTenantFailed',
      'Error: could not refresh access token'
    );
  }
}

// Error
function assignAccountError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('account.notifications.updateTenantFailed', action?.payload?.msg.message);
}

// **************************************************

// ********************* EXPORT SAGAS ***************

export default function* sagas() {
  yield all([
    // Assign
    changeAccountSaga(),
    accountChangedSaga(),
    accountNotChangedSaga(),
  ]);
}
