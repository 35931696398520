/* eslint-disable no-nested-ternary */
import React from 'react';
import {Col, Empty, Row, Skeleton, Tabs} from 'antd';
import styled from 'styled-components';
import {isEmpty} from 'lodash';

// Models
import {Employee} from '../../models/Employee';
import {IdentifierType} from '../../models/IdentifierType';

// Hooks
import {useEmployeeIdentifiers} from '../../hooks/Employees/EmployeeIdentifiers/useEmployeeIdentifiers';

// Components
import {Widget} from '../UI/Widget/Widget';
import {Spinner} from '../UI/Spinner/Spinner';
import {Translated} from '../UI/Core';

// Styled
const StyledWidget = styled(Widget)`
  & .ant-card-body {
    padding: 0;
  }
`;
const StyledTabs = styled(Tabs)`
  & .ant-tabs-nav-wrap {
    margin-left: 24px;
  }
  & .ant-tabs-nav {
    height: 58px;
  }
  & .ant-tabs-content {
    padding: 0px 24px 16px;
  }
`;

// Props
interface IdentifierProps {
  employee: Employee | null;
  fetchEmployee: (params: any) => Promise<void>;
  updatingEmployee: boolean;
}

// Component
export const Identifiers = ({ employee, fetchEmployee, updatingEmployee }: IdentifierProps) => {
  // Identifiers Hook
  const { identifierTypes, loading, updating, defaultActiveKey, IdentifierManagementForm } = useEmployeeIdentifiers({
    employee,
    fetchEmployee,
    updatingEmployee,
  });

  // Render Methods
  const renderLoading = () => [
    {
      key: '0',
      label: <Translated id="app.loading" />,
      children: (
        <Row>
          <Col xl={8} lg={12} md={12} sm={12} xs={24}>
            <Skeleton active />
          </Col>
        </Row>
      ),
    },
  ];

  const renderEmpty = () => [
    {
      key: '0',
      label: <Translated id="identifiers.form.tab" />,
      children: (
        <Row>
          <Col xs={24}>
            <Empty
              description={
                <span>
                  <Translated id="identifiers.form.empty" />
                </span>
              }
            />
          </Col>
        </Row>
      ),
    },
  ];

  const renderForm = (types: IdentifierType[]) =>
    types.map((identifierType) => ({
      key: identifierType.Id,
      label: identifierType.Name,
      children: (
        <Spinner spinning={updating}>
          <IdentifierManagementForm identifierType={identifierType} />
        </Spinner>
      ),
    }));

  const renderItems = () => {
    if (loading) return renderLoading();
    if (!identifierTypes || isEmpty(identifierTypes)) return renderEmpty();
    return renderForm(identifierTypes);
  };

  // Component Render
  return (
    <StyledWidget styleName="gx-card-widget">
      <StyledTabs items={renderItems()} defaultActiveKey={defaultActiveKey} />
    </StyledWidget>
  );
};
