// Types
import { SignalREvent } from '../../models/SignalREvent';

// Actions
import { accountChanged, accountNotChanged } from './Account.redux';

// Events
const SignalREvents = {
  CurrentIdentityUserTenantChanged: `CurrentIdentityUserTenantChangedIntegrationEvent`,
};

// Exceptions
const SignalRExceptions = {
  CurrentIdentityUserTenantNotChanged: `CurrentIdentityUserTenantNotChangedIntegrationException`,
};

// Events
export const configureSignalREvents = ({ hubConnection, store, history }: SignalREvent) => {
  // Change
  hubConnection.on(SignalREvents.CurrentIdentityUserTenantChanged, () => {
    store.dispatch(accountChanged());
  });
  hubConnection.on(SignalRExceptions.CurrentIdentityUserTenantNotChanged, (msg) => {
    store.dispatch(accountNotChanged({ history, msg }));
  });
};
