import React from 'react';
import { v4 as uuid } from 'uuid';
import { Route } from 'react-router';

// Routes
import { getAuthorizationRoutes } from './AuthorizationRoutes';

// Components
import { AuthorizedRoute } from '../components/UI/ApiAuthorization/AuthorizedRoute';

// Pages
import { NotFoundPage } from '../pages/App/NotFoundPage';

import { EmployeesPage } from '../pages/Employees/EmployeesPage';
import { EmployeeDetailPage } from '../pages/Employees/EmployeeDetailPage';
import { VisitorsPage } from '../pages/Visitors/VisitorsPage';
import { VisitorDetailPage } from '../pages/Visitors/VisitorDetailPage';
import { NewVisitorPage } from '../pages/Reservations/NewVisitorPage';
import { ReservationsPage } from '../pages/Reservations/ReservationsPage';

export const routes = [
  <Route key={uuid()} path="/" element={<AuthorizedRoute path="/" />}>
    {/* Dashboard */}
    <Route path="/" key={uuid()} element={<VisitorsPage />} />
    {/* Employees */}
    <Route path="/Employees" key={uuid()} element={<EmployeesPage />} />
    <Route path="/Employees/:id" key={uuid()} element={<EmployeeDetailPage />} />
    {/* Visitors */}
    <Route path="/Visitors" key={uuid()} element={<VisitorsPage />} />
    <Route path="/Visitors/:id" key={uuid()} element={<VisitorDetailPage />} />
    {/* Reservation */}
    <Route path="/Reservations" key={uuid()} element={<ReservationsPage />} />
    <Route path="/Reservations/New_visitor" key={uuid()} element={<NewVisitorPage />} />
    {/* default */}
    <Route path="*" key={uuid()} element={<NotFoundPage />} />
  </Route>,

  getAuthorizationRoutes(),
];
