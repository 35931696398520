/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Models
import { Visitor } from '../../models/Visits/Visitor';
import { SignalRAction } from '../../models/SignalRAction';
import { Timeframe } from '../../models/enums/Timeframe';
import { VisitType } from '../../models/enums/VisitType';

// State
interface VisitorsState {
  updating: boolean;
  error: boolean;
  customTableFilters: CustomFilters;
}

const initialState: VisitorsState = {
  updating: false,
  error: false,
  customTableFilters: { timeframe: Timeframe.Today, visitType: VisitType.All },
};

interface CustomFilters {
  timeframe: Timeframe;
  visitType: VisitType;
}

// Name
const storeName = '@VISITORS';

// Redux Actions|Reducers
const visitorsSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    // Create
    createVisitor: (state, _action: PayloadAction<Visitor>) => {
      state.updating = true;
      state.error = false;
    },
    visitorCreated: (state, _action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    visitorNotCreated: (state, _action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Update
    updateVisitor: (state, _action: PayloadAction<Visitor>) => {
      state.updating = true;
      state.error = false;
    },
    visitorUpdated: (state, _action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    visitorNotUpdated: (state, _action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },
    pacsVisitorUpdated: (state, _action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    pacsVisitorNotUpdated: (state, _action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },

    // Delete
    deleteVisitor: (state, _action: PayloadAction<Visitor>) => {
      state.updating = true;
      state.error = false;
    },
    visitorDeleted: (state, _action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    visitorNotDeleted: (state, _action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },
    pacsVisitorDeleted: (state, _action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    pacsVisitorNotDeleted: (state, _action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },

    // Delete All
    deleteVisitors: (state, _action: PayloadAction<Array<Visitor>>) => {
      state.updating = true;
      state.error = false;
    },
    visitorsDeleted: (state, _action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    visitorsNotDeleted: (state, _action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Table custom filters
    updateCustomTableFilters: (state, action: PayloadAction<CustomFilters>) => {
      state.customTableFilters = action.payload;
    },
  },
});

// Export Actions
export const {
  // Create
  createVisitor,
  visitorCreated,
  visitorNotCreated,
  // Update
  updateVisitor,
  visitorUpdated,
  visitorNotUpdated,
  pacsVisitorUpdated,
  pacsVisitorNotUpdated,
  // Delete
  deleteVisitor,
  visitorDeleted,
  visitorNotDeleted,
  pacsVisitorDeleted,
  pacsVisitorNotDeleted,
  // Delete All
  deleteVisitors,
  visitorsDeleted,
  visitorsNotDeleted,
  // Table custom filters
  updateCustomTableFilters,
} = visitorsSlice.actions;

// Export Reducer
export default visitorsSlice.reducer;
