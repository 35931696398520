import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { usePrevious } from 'react-use';
import { Store } from 'antd/lib/form/interface';

// Hooks
import { useData } from '../App/useData';
import { useEmployeeRedux } from './useEmployeeRedux';
import { useFormDrawer } from '../Drawer/useFormDrawer';

// Services
import { notificationService } from '../../services/Notifications/NotificationService';

// Data
import { ApiEndpoints } from '../../data/ApiEndpoints';

// Components
import { appBreadcrumbs } from '../../data/Breadcrumbs/Breadcrumbs';

// Props
interface EmployeeDetailProps {
  id?: string;
}

// Hook
export const useEmployeeDetail = <T extends object>({ id }: EmployeeDetailProps) => {
  // Navigation Hook
  const navigate = useNavigate();

  // Data
  const { data: employee, fetch, pending } = useData(ApiEndpoints.employees.detail, null);
  const prevPending = usePrevious(pending);

  const { data: actions, fetch: fetchActions, pending: pendingAction } = useData(ApiEndpoints.Actions.list, null);

  // Redux
  const { formOptions, updating, error, showDeleteConfirm } = useEmployeeRedux<T>({
    isEditing: true,
    initialValues: employee as Store,
  });
  const prevUpdating = usePrevious(updating);

  // Form Drawer
  const { getFormDrawerProps } = useFormDrawer<T>({ formOptions, updating, error });

  // Props
  const getBreadcrumbMenuProps = useCallback(() => ({ breadcrumbs: appBreadcrumbs.employeees.detail }), []);
  const getProfileHeaderProps = useCallback(
    () => ({
      employee,
      pending,
      setOpen: getFormDrawerProps().setOpen,
      showDeleteConfirm,
    }),
    [employee, pending, getFormDrawerProps, showDeleteConfirm]
  );
  const getContactCardProps = useCallback(
    () => ({
      employee,
      pending,
    }),
    [employee, pending]
  );
  const getEmployeeProps = useCallback(
    () => ({
      employee,
      fetchEmployee: fetch,
      updatingEmployee: pending,
    }),
    [employee, fetch, pending]
  );
  const getActionsCardProps = useCallback(
    () => ({
      actionsData: actions ?? [],
      pendingAction,
      employee,
      fetchEmployee: fetch,
    }),
    [actions, pendingAction, employee, fetch]
  );

  // Effects
  useEffect(() => {
    // Fetch on initializing
    fetch({ id });
    fetchActions();
  }, [fetch, id, fetchActions]);

  useEffect(() => {
    // Navigates to the index if not found
    if (prevPending === true && pending === false && !employee) {
      notificationService.showError('employees.notFound');
      navigate('/Employees');
    }
  }, [prevPending, pending, employee, navigate]);

  useEffect(() => {
    // Fetch after updating
    if (prevUpdating === true && updating === false && !error) {
      fetch({ id });
    }
  }, [prevUpdating, updating, error, fetch, id]);

  return useMemo(
    () => ({
      updating,
      getBreadcrumbMenuProps,
      getProfileHeaderProps,
      getContactCardProps,
      getFormDrawerProps,
      getEmployeeProps,
      getActionsCardProps,
    }),
    [
      updating,
      getBreadcrumbMenuProps,
      getProfileHeaderProps,
      getContactCardProps,
      getFormDrawerProps,
      getEmployeeProps,
      getActionsCardProps,
    ]
  );
};
