import React, { useState } from 'react';
import { Col, Divider, Form, FormInstance, Input, Row } from 'antd';
import styled from 'styled-components';

// eslint-disable-next-line import/no-extraneous-dependencies
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';

// Constants
import { isInThePast } from '../../../../constants/Utils/DateTime';

// Models
import { VisitFormValues } from '../../../../models/Visits/VisitFormValues';

// Components
import { VisitFormGuests } from './VisitFormGuests';
import { Flex } from '../../../UI/Base';
import { Spinner } from '../../../UI/Spinner/Spinner';
import { VisitFormFields } from './VisitFormFields';

// Styled
const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ColNoPadding = styled(Col)`
  padding: 0;
`;

// Props
export interface VisitFormProps<T> {
  form: FormInstance<T>;
  initialValues?: VisitFormValues;
  onSubmit: (values: unknown) => void;
  updating: boolean;
}

// Component
export const VisitForm = <T extends object>({ form, initialValues, onSubmit, updating }: VisitFormProps<T>) => {
  // State
  const [formData, setFormData] = useState<ValidateErrorEntity<unknown>>();

  // Render Form
  return (
    <StyledForm
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      onFinishFailed={setFormData}
      initialValues={initialValues}
      disabled={isInThePast(initialValues?.ValidTo)}
    >
      <Spinner spinning={updating}>
        <Form.Item name="Id" hidden>
          <Input />
        </Form.Item>

        <Row style={{ flexDirection: 'row' }}>
          {/* Guests */}
          <VisitFormGuests form={form} formData={formData} initialValues={initialValues} />

          {/* Responsive Dividers */}
          <ColNoPadding xs={0} md={1}>
            <Flex justifyContent="center" style={{ height: '100%' }}>
              <Divider type="vertical" style={{ height: '100%' }} />
            </Flex>
          </ColNoPadding>
          <ColNoPadding xs={24} md={0}>
            <Flex justifyContent="center" style={{ width: '100%' }}>
              <Divider type="horizontal" style={{ width: '100%' }} />
            </Flex>
          </ColNoPadding>

          {/* Visit Fields */}
          <VisitFormFields form={form} initialValues={initialValues} />
        </Row>
      </Spinner>
    </StyledForm>
  );
};
