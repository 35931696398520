import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

// Services
import { ApiRequestType, apiService } from '../../../services/ApiService/ApiService';

// Models
// Redux
import {
  assignIdentifier,
  identifierAssigned,
  identifierNotAssigned,
  identifierNotRemoved,
  identifierRemoved,
  identifiersNotRemoved,
  removeIdentifier,
} from './ReservationIdentifiers.redux';
import { IdentifierFormValues } from '../../../models/IdentifierFormValues';

// RESPONSE
function emptyReservationIdentifierResponse() {
  // The visitors identifiers saga publishes all notifications
}

// **************************************************
// ********************* ASSIGN *********************

// Worker Sagas
function* assignIdentifierSaga() {
  yield takeEvery(assignIdentifier.type, assignIdentifierRequest);
}

function* identifierAssignedSaga() {
  yield takeLatest(identifierAssigned.type, emptyReservationIdentifierResponse);
}

function* identifierNotAssignedSaga() {
  yield takeLatest(identifierNotAssigned.type, emptyReservationIdentifierResponse);
}

// Request
function* assignIdentifierRequest(action: PayloadAction<{ formValues: IdentifierFormValues }>) {
  try {
    const {
      payload: { formValues },
    } = action;
    yield apiService.execute({
      url: `Reservations/Identifier`,
      method: ApiRequestType.POST,
      data: formValues,
    });
  } catch ({ message }) {
    yield put({ type: identifierNotAssigned.type, payload: { msg: { message } } });
  }
}

// **************************************************
// ********************* REMOVE *********************

// Worker Sagas
function* removeIdentifierSaga() {
  yield takeEvery(removeIdentifier.type, removeIdentifierRequest);
}

function* identifierRemovedSaga() {
  yield takeLatest(identifierRemoved.type, emptyReservationIdentifierResponse);
}

function* identifierNotRemovedSaga() {
  yield takeLatest(identifierNotRemoved.type, emptyReservationIdentifierResponse);
}

function* identifiersNotRemovedSaga() {
  yield takeLatest(identifiersNotRemoved.type, emptyReservationIdentifierResponse);
}

// Request
function* removeIdentifierRequest(action: PayloadAction<{ formValues: IdentifierFormValues }>) {
  try {
    const {
      payload: { formValues },
    } = action;
    yield apiService.execute({
      url: `Reservations/Identifier/${formValues.Identifier.IdentifierId}`,
      method: ApiRequestType.DELETE,
      data: formValues,
    });
  } catch ({ message }) {
    yield put({ type: identifierNotAssigned.type, payload: { msg: { message } } });
  }
}

// **************************************************
// ********************* EXPORT SAGAS ***************

export default function* sagas() {
  yield all([
    // Assign
    assignIdentifierSaga(),
    identifierAssignedSaga(),
    identifierNotAssignedSaga(),
    // Remove
    removeIdentifierSaga(),
    identifierRemovedSaga(),
    identifierNotRemovedSaga(),
    identifiersNotRemovedSaga(),
  ]);
}
