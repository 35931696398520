import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

// Assets
import wideLogo from '../../../assets/images/bf_logo.png';
import iconLogo from '../../../assets/images/bf_logo_icon.png';

// Models
import { TenantData } from '../../../models/TenantData';

// Hooks
import { ButtonSkeleton } from '../../UI/Skeleton/ButtonSkeleton';

// Props
interface LogoProps {
  tenantData: TenantData | null;
  loading: boolean;
}

// Styled
const StyledLogoImg = styled.img`
  height: 30px;
  margin-left: 6px;
`;

export const Logo = ({ tenantData, loading }: LogoProps) => {
  // Component Render
  return (
    <>
      <Link to="/" className="gx-d-none gx-d-lg-block gx-pointer gx-mr-xs-5 gx-logo">
        {loading ? (
          <ButtonSkeleton width={80} height={21} />
        ) : (
          <StyledLogoImg alt="" src={tenantData?.LogoUrl ? tenantData.LogoUrl : wideLogo} />
        )}
      </Link>
      <Link to="/" className="gx-d-block gx-d-lg-none gx-pointer gx-mr-xs-3 gx-pt-xs-1 gx-w-logo">
        {loading ? (
          <ButtonSkeleton width={40} height={21} />
        ) : (
          <StyledLogoImg alt="" src={tenantData?.IconLogoUrl ? tenantData.IconLogoUrl : iconLogo} />
        )}
      </Link>
    </>
  );
};
