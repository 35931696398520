import React from 'react';

// Constants
import { BaseColors } from '../../../constants/Utils/Colors';
import { toCamelCase } from '../../../constants/Utils/String';

// Components
import { Translated } from '../Core';

// Props
interface WeekdayTickProps {
  intlId: string;
  x?: number;
  y?: number;
  payload?: {
    value: any;
  };
}

// Component
export const WeekdayTick = ({ x, y, payload, intlId }: WeekdayTickProps) => (
  <g>
    <text x={x} y={y} dy={16} textAnchor="middle" fill={BaseColors.light}>
      <Translated id={intlId + toCamelCase(payload?.value)} defaultMessage={payload?.value} />
    </text>
  </g>
);
