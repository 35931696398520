import React, { useState } from 'react';
import { Popover } from 'antd';
import styled from 'styled-components';

// Components
import { LanguageMenu } from './LanguageMenu';
import { useAppSelector } from '../../../hooks/App/useRedux';

// Styled
const Li = styled.li`
  margin-right: 4px;
`;

export const LanguageSwitcher = () => {
  const locale = useAppSelector(({ appSettings }) => appSettings?.locale);
  const [open, setOpen] = useState(false);

  const hide = () => setOpen(false);
  const handleOpenChange = (value: boolean) => setOpen(value);

  return (
    <Li className="gx-language">
      <Popover
        open={open}
        onOpenChange={handleOpenChange}
        overlayClassName="gx-popover-horizantal"
        placement="bottomRight"
        content={<LanguageMenu onClick={hide} />}
        trigger="click"
      >
        <span className="gx-pointer gx-flex-row gx-align-items-center">
          <i className={`flag flag-24 flag-${locale?.icon}`} />
        </span>
      </Popover>
    </Li>
  );
};
