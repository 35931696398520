// Types
import { SignalREvent } from '../../../models/SignalREvent';

// Actions
import { employeeUploaded } from './EmployeeImport.redux';

// Events
const SignalREvents = {
  employeeUploaded: `UserImportCompletedIntegrationEvent`,
};

// Events
export const configureSignalREvents = ({ hubConnection, store, history }: SignalREvent) => {
  // Success
  hubConnection.on(SignalREvents.employeeUploaded, (msg) => {
    store.dispatch(employeeUploaded({ history, msg }));
  });
};
