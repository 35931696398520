import React from 'react';
import { Button, Result } from 'antd';

// Hooks
import { useNavigate } from 'react-router-dom';

// Components
import { Translated } from '../../components/UI/Core';

export const NotFoundPage = () => {
  const navigate = useNavigate();
  const goToHomePage = () => {
    navigate('/');
  };

  return (
    <Result
      status="404"
      title={<Translated id="app.pages.notFound" />}
      subTitle={<Translated id="app.pages.notFoundSub" />}
      extra={
        <Button onClick={goToHomePage} type="primary">
          <Translated id="app.goBack" />
        </Button>
      }
    />
  );
};
