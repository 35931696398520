import React, { useState } from 'react';
import { Button, Col, Form, Popover } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

// Hooks
import { useVisitGuestSelect } from '../../../../hooks/Visitors/useVisitGuestSelect';

// Components
import { SearchSelect } from '../../../UI/Select/SearchSelect';
import { SelectedOption } from '../../../../models/SelectedOption';
import { Visitor } from '../../../../models/Visits/Visitor';

// Props
interface VisitFormGuestsSearchProps {
  onGuestSearch: (value: SelectedOption[], data: Visitor[] | null) => void;
}

// Component
export const VisitFormGuestsSearch = ({ onGuestSearch }: VisitFormGuestsSearchProps) => {
  // State
  const [popOverOpen, setPopOverOpen] = useState<boolean>();

  // Content
  const popoverContent = (
    <Col style={{ width: 200, height: 35, margin: '0px 8px' }} onClick={(e) => e.stopPropagation()}>
      <Form.Item name="Visitors">
        <SearchSelect
          selectOptionsProvider={useVisitGuestSelect}
          onValueChange={(value, data) => {
            setPopOverOpen(false);
            onGuestSearch(value, data);
          }}
        />
      </Form.Item>
    </Col>
  );

  // Render Popover
  return (
    <Popover
      content={popoverContent}
      open={popOverOpen}
      onOpenChange={setPopOverOpen}
      trigger="click"
      placement="bottomRight"
    >
      <Button
        size="small"
        shape="circle"
        className="gx-mb-0"
        icon={<SearchOutlined />}
        onClick={(e) => {
          e.stopPropagation();
          setPopOverOpen(true);
        }}
      />
    </Popover>
  );
};
