import { useCallback, useRef, useState } from 'react';
import useMountedState from 'react-use/lib/useMountedState';

import { ApiResponse } from '../../models/ApiResponse';
import { redirectToApiAuthorizationPath } from '../../constants/Utils/Urls';
import { ApplicationPaths } from '../../constants/Settings/AuthSettings';

interface DataState<T> {
  data: T;
  pageCount: number;
  totalCount: number;
  pending: boolean;
  fetched: boolean;
  error: Error | null | unknown;
}

export const useData = <T, A extends any[]>(apiCallFn: (...a: A) => Promise<ApiResponse<T>>, initialData: T) => {
  const [state, setState] = useState<DataState<T | typeof initialData>>({
    // Api Data
    data: initialData,
    // UI Data
    pageCount: 0,
    totalCount: 0,
    pending: false,
    fetched: false,
    error: null,
  });
  const isMounted = useMountedState();
  const lastCallId = useRef(0);

  const fetch = useCallback(
    async (...args: A) => {
      lastCallId.current += 1;
      const callId = lastCallId.current;
      setState((prevState) => ({ ...prevState, error: null, pending: true }));
      try {
        const { Data: data, Page: page } = await apiCallFn(...args);
        if (isMounted() && callId === lastCallId.current) {
          const { Total: total, Size: size } = page ?? {};
          const pageCount = total && size ? Math.ceil(total / size) : 0;
          const totalCount = total ?? 0;

          setState({ data, pageCount, totalCount, pending: false, fetched: true, error: null });
        }
      } catch (error: any) {
        if (error?.response?.status === 403) {
          redirectToApiAuthorizationPath(ApplicationPaths.IdentityManagePath);
        }
        if (isMounted() && callId === lastCallId.current) {
          // console.error(error);
          setState((prevState) => ({ ...prevState, pending: false, fetched: true, error }));
        }
      }
    },
    [apiCallFn, isMounted]
  );

  return {
    data: state.data,
    pageCount: state.pageCount,
    totalCount: state.totalCount,
    pending: state.pending,
    fetched: state.fetched,
    error: state.error,
    fetch,
  };
};
