import React from 'react';
import { Collapse } from 'antd';
import styled from 'styled-components';

// Components
const { Panel } = Collapse;

// Styles
const StyledCollapse = styled(Collapse)`
  &.ant-collapse > .ant-collapse-item {
    & > .ant-collapse-header {
      color: white;
      padding: 0;
    }
    & > .ant-collapse-content {
      color: white;

      & > .ant-collapse-content-box {
        padding: 0;
        padding-top: 12px;
      }
    }
  }
`;

// Props
interface NotificationCollapseProps {
  header: React.ReactNode;
  content: string;
}

// Component
export const NotificationCollapse = ({ header, content }: NotificationCollapseProps) => (
  <StyledCollapse ghost>
    <Panel header={header} showArrow={false} key="1">
      <p>{content}</p>
    </Panel>
  </StyledCollapse>
);
