/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */

import {createSlice, PayloadAction} from '@reduxjs/toolkit';

// Models
import {SignalRAction} from '../../../models/SignalRAction';

// State
interface EmployeesImportState {
  updating: boolean;
  error: boolean;
  importResultBase64: string | null;
}

const initialState: EmployeesImportState = {
  updating: false,
  error: false,
  importResultBase64: null,
};

// Name
const storeName = '@EMPLOYEE_IMPORT';

// Redux Actions|Reducers
const employeeImportSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    // Download
    downloadEmployeeTemplate: (state) => {
      state.updating = true;
      state.error = false;
    },
    employeeTemplateDownloaded: (state, action: PayloadAction<{ data: string }>) => {
      state.updating = false;
      state.error = false;
    },
    employeeTemplateNotDownloaded: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Upload
    uploadEmployee: (state, action: PayloadAction<{ formData: FormData }>) => {
      state.updating = true;
      state.error = false;
    },
    employeeUploaded: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    setResultCsvData: (state, action: PayloadAction<{ data: string | null }>) => {
      state.importResultBase64 = action.payload.data;
    },
    employeeNotUploaded: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },
  },
});

// Export Actions
export const {
  // Download
  downloadEmployeeTemplate,
  employeeTemplateDownloaded,
  employeeTemplateNotDownloaded,
  setResultCsvData,
  // Upload
  uploadEmployee,
  employeeUploaded,
  employeeNotUploaded,
} = employeeImportSlice.actions;

// Export Reducer
export default employeeImportSlice.reducer;
