/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Models
import { SignalRAction } from '../../models/SignalRAction';
import { Visitor } from '../../models/Visits/Visitor';
import { Visit } from '../../models/Visits/Visit';

// State
interface ReservationsState {
  lastEventId?: string;
  updating: boolean;
  error: boolean;
}

const initialState: ReservationsState = {
  lastEventId: undefined,
  updating: false,
  error: false,
};

// Name
const storeName = '@RESERVATIONS';

// Redux Actions|Reducers
const ReservationsSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    // Create Visit
    createVisit: (state, _action: PayloadAction<Visit>) => {
      state.updating = true;
      state.error = false;
    },
    visitCreated: (state, action: PayloadAction<SignalRAction>) => {
      state.lastEventId = action.payload.msg.id;
      state.updating = false;
      state.error = false;
    },
    visitNotCreated: (state, _action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Update Visit
    updateVisit: (state, _action: PayloadAction<{ visit: Visit; sendEmail: boolean }>) => {
      state.updating = true;
      state.error = false;
    },
    visitUpdated: (state, _action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    visitNotUpdated: (state, _action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Delete Visit
    deleteVisit: (state, _action: PayloadAction<Visit>) => {
      state.updating = true;
      state.error = false;
    },
    visitDeleted: (state, _action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    visitNotDeleted: (state, _action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Create Visitor
    createVisitor: (state, _action: PayloadAction<Visitor>) => {
      state.updating = true;
      state.error = false;
    },
    visitorCreated: (state, action: PayloadAction<SignalRAction>) => {
      state.lastEventId = action.payload.msg.id;
      state.updating = false;
      state.error = false;
    },
    visitorNotCreated: (state, _action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Update Visitor
    updateVisitor: (state, _action: PayloadAction<Visitor>) => {
      state.updating = true;
      state.error = false;
    },
    visitorUpdated: (state, _action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    visitorNotUpdated: (state, _action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Delete
    deleteVisitor: (state, _action: PayloadAction<Visitor>) => {
      state.updating = true;
      state.error = false;
    },
    visitorDeleted: (state, _action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    visitorNotDeleted: (state, _action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },
  },
});

// Export Actions
export const {
  // Create Visit
  createVisit,
  visitCreated,
  visitNotCreated,
  // Update Visit
  updateVisit,
  visitUpdated,
  visitNotUpdated,
  // Delete Visit
  deleteVisit,
  visitDeleted,
  visitNotDeleted,
  // Create Visitor
  createVisitor,
  visitorCreated,
  visitorNotCreated,
  // Update Visitor
  updateVisitor,
  visitorUpdated,
  visitorNotUpdated,
  // Delete Visitor
  deleteVisitor,
  visitorDeleted,
  visitorNotDeleted,
} = ReservationsSlice.actions;

// Export Reducer
export default ReservationsSlice.reducer;
