import React, { useCallback, useMemo, useState } from 'react';
import { Button, Modal, Upload } from 'antd';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { RcFile } from 'antd/lib/upload';
import { DownloadOutlined, InboxOutlined, UploadOutlined } from '@ant-design/icons';

// Components
import { StyledTooltip } from '../../../components/UI/Tooltip/StyledTooltip';
import { Translated } from '../../../components/UI/Core/Translated';
import { Spinner } from '../../../components/UI/Spinner/Spinner';

// Redux
import {
  downloadEmployeeTemplate,
  setResultCsvData,
  uploadEmployee,
} from '../../../store/Employees/EmployeeImport/EmployeeImport.redux';
import { useAppDispatch, useAppSelector } from '../../App/useRedux';

// Styled
const StyledSpan = styled.span`
  width: 14px;
  height: 14px;
  line-height: 16px;
  display: flex;
`;
const StyledButton = styled(Button)`
  padding: 5px 10px;
  margin-bottom: 0;
  display: flex;
  align-items: center;

  :focus,
  :active,
  :active:focus {
    outline: none !important;
    box-shadow: none !important;
  }

  &[disabled] {
    margin-right: 15px;
  }
`;

const { Dragger } = Upload;

export const useEmployeeImport = () => {
  // Redux
  const dispatch = useAppDispatch();
  const updating = useAppSelector(({ employeeImport }) => employeeImport?.updating ?? false);
  const resultsData = useAppSelector(({ employeeImport }) => employeeImport?.importResultBase64 ?? false);
  const resultBlob = useMemo(() => {
    if (!resultsData) return null;
    return new Blob([atob(resultsData)], { type: 'data:application/octet-stream;base64' });
  }, [resultsData]);

  // State
  const [fileList, setFileList] = useState<Array<RcFile>>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Modal Callbacks
  const showModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const hideModal = useCallback(() => {
    dispatch(setResultCsvData({ data: null }));
    setFileList([]);
    setIsModalOpen(false);
  }, [dispatch]);

  // Dragger Callback
  const handleDownload = useCallback(async () => {
    dispatch(downloadEmployeeTemplate());
    setIsModalOpen(false);
  }, [dispatch]);

  const handleUpload = useCallback(() => {
    const file = fileList && fileList[0];

    if (file) {
      const formData = new FormData();
      formData.append('formFile', file);
      formData.append('fileName', file.name);
      dispatch(uploadEmployee({ formData }));
    }

    setFileList([]);
  }, [dispatch, setFileList, fileList]);

  // Components
  const UploadButton = useCallback(
    () => (
      <StyledTooltip
        title={<Translated id="table.uploadButton" defaultMessage="Upload/Download" />}
        placement="bottomRight"
      >
        <StyledButton onClick={showModal}>
          <StyledSpan>
            <UploadOutlined />
          </StyledSpan>
        </StyledButton>
      </StyledTooltip>
    ),
    [showModal]
  );

  const UploadModal = useCallback(
    () => (
      <Modal
        title={<Translated id="table.uploadTable" />}
        open={isModalOpen}
        onCancel={hideModal}
        footer={[
          <Button
            ghost
            type="primary"
            icon={<DownloadOutlined />}
            key="downloadFile"
            disabled={updating}
            onClick={handleDownload}
          >
            Download template
          </Button>,

          resultBlob ? (
            <Button
              type="default"
              disabled={updating}
              icon={<UploadOutlined />}
              href={window.URL.createObjectURL(resultBlob)}
              download="Results.csv"
            >
              Download result
            </Button>
          ) : (
            <Button
              type="primary"
              disabled={isEmpty(fileList) || updating}
              icon={<UploadOutlined />}
              key="uploadFile"
              onClick={handleUpload}
            >
              Upload template
            </Button>
          ),
        ]}
      >
        <Spinner spinning={updating}>
          <Dragger
            multiple={false}
            accept="text/csv"
            name="uploadFile"
            fileList={fileList}
            beforeUpload={(file) => setFileList([file])}
            onRemove={() => setFileList([])}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              <Translated id="import.uploadText" />
            </p>
            <p className="ant-upload-hint">
              <Translated id="import.uploadHint" />
            </p>
          </Dragger>
        </Spinner>
      </Modal>
    ),
    [fileList, updating, isModalOpen, resultBlob, hideModal, handleDownload, handleUpload]
  );

  return useMemo(
    () => ({
      UploadButton,
      UploadModal,
      updating,
    }),
    [UploadButton, UploadModal, updating]
  );
};
