import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { uniq } from 'lodash';

// Services
import { ApiRequestType, apiService } from '../../services/ApiService/ApiService';
import { notificationService } from '../../services/Notifications/NotificationService';

// Models
import { Visit } from '../../models/Visits/Visit';
import { SignalRAction } from '../../models/SignalRAction';
import { ResendInvitationToGuest } from '../../models/Visits/ResendInvitationToGuest';
import { ResendInvitationToHost } from '../../models/Visits/ResendInvitationToHost';

// Redux
import {
  createVisit,
  deleteVisit,
  deleteVisits,
  resendInvitationToGuest,
  resendInvitationToHost,
  updateVisit,
  updateVisitStatus,
  visitCreated,
  visitDeleted,
  visitEmailStatusNotUpdated,
  visitEmailStatusUpdated,
  visitNotCreated,
  visitNotDeleted,
  visitNotUpdated,
  visitsDeleted,
  visitsNotDeleted,
  visitStatusNotUpdated,
  visitStatusUpdated,
  visitUpdated,
} from './Visits.redux';
import { GuestStatus } from '../../models/enums/GuestStatus';

// **************************************************
// ********************* CREATE *********************

// Worker Sagas
function* createVisitSaga() {
  yield takeEvery(createVisit.type, createVisitRequest);
}

function* visitCreatedSaga() {
  yield takeLatest(visitCreated.type, createVisitResponse);
}

function* visitNotCreatedSaga() {
  yield takeLatest(visitNotCreated.type, createVisitError);
}

// Request
function* createVisitRequest(action: PayloadAction<Visit>) {
  try {
    const { payload: data } = action;

    yield apiService.execute({
      url: 'Visits',
      method: ApiRequestType.POST,
      data,
    });
  } catch ({ message }) {
    yield put({ type: visitNotCreated.type, payload: { msg: { message } } });
  }
}

// Response
function createVisitResponse() {
  notificationService.showSuccess('visits.notifications.create');
}

// Error
function createVisitError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('visits.notifications.createFailed', action?.payload?.msg.message);
}

// **************************************************
// ********************* UPDATE *********************

// Worker Sagas
function* updateVisitSaga() {
  yield takeEvery(updateVisit.type, updateVisitRequest);
}

function* visitUpdatedSaga() {
  yield takeLatest(visitUpdated.type, updateVisitResponse);
}

function* visitNotUpdatedSaga() {
  yield takeLatest(visitNotUpdated.type, updateVisitError);
}

// Request
function* updateVisitRequest(action: PayloadAction<{ visit: Visit; sendEmail: boolean }>) {
  try {
    const {
      payload: { visit, sendEmail },
    } = action;

    yield apiService.execute({
      url: `Visits/${visit.Id}`,
      method: ApiRequestType.PUT,
      data: {
        ...visit,
        SendConfirmationEmail: sendEmail,
      },
    });
  } catch ({ message }) {
    yield put({ type: visitNotUpdated.type, payload: { msg: { message } } });
  }
}

// Response
function updateVisitResponse() {
  notificationService.showSuccess('visits.notifications.update');
}

// Error
function updateVisitError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('visits.notifications.updateFailed', action?.payload?.msg.message);
}

// **************************************************
// ********************* DELETE *********************

// Worker Sagas
function* deleteVisitSaga() {
  yield takeEvery(deleteVisit.type, deleteVisitRequest);
}

function* visitDeletedSaga() {
  yield takeLatest(visitDeleted.type, deleteVisitResponse);
}

function* visitNotDeletedSaga() {
  yield takeLatest(visitNotDeleted.type, deleteVisitError);
}

// Request
function* deleteVisitRequest(action: PayloadAction<Visit>) {
  try {
    const { payload: data } = action;
    yield apiService.execute({
      url: `Visits/${data.Id}`,
      method: ApiRequestType.DELETE,
      data,
    });
  } catch ({ message }) {
    yield put({ type: visitNotDeleted.type, payload: { msg: { message } } });
  }
}

// Response
function deleteVisitResponse() {
  notificationService.showSuccess('visits.notifications.delete');
}

// Error
function deleteVisitError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('visits.notifications.deleteFailed', action?.payload?.msg.message);
}

// **************************************************
// ********************* DELETE All *****************

// Worker Sagas
function* deleteVisitsSaga() {
  yield takeEvery(deleteVisits.type, deleteVisitsRequest);
}

function* visitsDeletedSaga() {
  yield takeLatest(visitsDeleted.type, deleteVisitsResponse);
}

function* visitsNotDeletedSaga() {
  yield takeLatest(visitsNotDeleted.type, deleteVisitsError);
}

// Request
function* deleteVisitsRequest(action: PayloadAction<Array<Visit>>) {
  try {
    const { payload: data } = action;
    yield apiService.execute({
      url: `Visits`,
      method: ApiRequestType.DELETE,
      data: { ids: uniq(data.map((item) => item.Id)) },
    });
  } catch ({ message }) {
    yield put({ type: visitsNotDeleted.type, payload: { msg: { message } } });
  }
}

// Response
function deleteVisitsResponse() {
  notificationService.showSuccess('visits.notifications.deleteAll');
}

// Error
function deleteVisitsError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('visits.notifications.deleteAllFailed', action?.payload?.msg.message);
}

// **************************************************
// ********************* UPDATE STATUS **************

// Worker Sagas
function* updateVisitStatusSaga() {
  yield takeEvery(updateVisitStatus.type, updateVisitStatusRequest);
}

function* visitStatusUpdatedSaga() {
  yield takeLatest(visitStatusUpdated.type, updateVisitStatusResponse);
}

function* visitStatusNotUpdatedSaga() {
  yield takeLatest(visitStatusNotUpdated.type, updateVisitStatusError);
}

// Request
function* updateVisitStatusRequest(action: PayloadAction<Visit>) {
  try {
    const { payload: data } = action;
    yield apiService.execute({
      url: `Visits/${data.Id}/Status`,
      method: ApiRequestType.PUT,
      data,
    });
  } catch ({ message }) {
    yield put({ type: visitNotUpdated.type, payload: { msg: { message } } });
  }
}

// Response
function updateVisitStatusResponse(action: PayloadAction<SignalRAction>) {
  if (action.payload?.msg?.guestStatus === null) {
    notificationService.showSuccess('visits.notifications.updateStatusHost');
    return;
  }
  const status = action.payload.msg.guestStatus as GuestStatus;
  switch (status) {
    case GuestStatus.CheckedIn:
      notificationService.showSuccess('visits.notifications.updateStatusCheckin');
      break;
    case GuestStatus.CheckedOut:
      notificationService.showSuccess('visits.notifications.updateStatusCheckout');
      break;
    default:
      notificationService.showSuccess('visits.notifications.updateStatus');
      break;
  }
}

// Error
function updateVisitStatusError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('visits.notifications.updateStatusFailed', action?.payload?.msg.message);
}

// **************************************************
// ********************* UPDATE EMAIL STATUS ********

// Worker Sagas
function* visitEmailStatusUpdatedSaga() {
  yield takeLatest(visitEmailStatusUpdated.type, updateVisitEmailStatusResponse);
}

function* visitEmailStatusNotUpdatedSaga() {
  yield takeLatest(visitEmailStatusNotUpdated.type, updateVisitEmailStatusError);
}

// Response
function updateVisitEmailStatusResponse(action: PayloadAction<SignalRAction>) {
  notificationService.showSuccess('visits.notifications.updateEmailStatus');
}

// Error
function updateVisitEmailStatusError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent(
    'visits.notifications.updateEmailStatusFailed',
    action?.payload?.msg.message
  );
}

// **************************************************
// ************ Resend invitation mails *************

// Worker Sagas
function* resendVisitorInvitationEmailSaga() {
  yield takeEvery(resendInvitationToGuest.type, resendVisitorInvitationEmailRequest);
}

// Request
function* resendVisitorInvitationEmailRequest(action: PayloadAction<ResendInvitationToGuest>) {
  try {
    const { payload: data } = action;
    yield apiService.execute({
      url: `Visits/${data.VisitId}/ResendVisitorInvitation/${data.VisitorId}`,
      method: ApiRequestType.POST,
      data,
    });
  } catch {
    notificationService.showError('visits.notifications.sendEmailFailed');
  }
}

// Worker Sagas
function* resendHostInvitationEmailSaga() {
  yield takeEvery(resendInvitationToHost.type, resendHostInvitationEmailRequest);
}

// Request
function* resendHostInvitationEmailRequest(action: PayloadAction<ResendInvitationToHost>) {
  try {
    const { payload: data } = action;
    yield apiService.execute({
      url: `Visits/${data.VisitId}/ResendHostInvitation/${data.HostId}`,
      method: ApiRequestType.POST,
      data,
    });
  } catch {
    notificationService.showError('visits.notifications.sendEmailFailed');
  }
}

// **************************************************
// ********************* EXPORT SAGAS ***************

export default function* sagas() {
  yield all([
    // Create
    createVisitSaga(),
    visitCreatedSaga(),
    visitNotCreatedSaga(),
    // Update
    updateVisitSaga(),
    visitUpdatedSaga(),
    visitNotUpdatedSaga(),
    // Delete
    deleteVisitSaga(),
    visitDeletedSaga(),
    visitNotDeletedSaga(),
    // Delete All
    deleteVisitsSaga(),
    visitsDeletedSaga(),
    visitsNotDeletedSaga(),
    // Update Status
    updateVisitStatusSaga(),
    visitStatusUpdatedSaga(),
    visitStatusNotUpdatedSaga(),
    // Update email status
    visitEmailStatusUpdatedSaga(),
    visitEmailStatusNotUpdatedSaga(),
    // Resend invitation mail
    resendVisitorInvitationEmailSaga(),
    resendHostInvitationEmailSaga(),
  ]);
}
