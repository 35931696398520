import React from 'react';
import { Col, Row } from 'antd';

// Hooks
import { useEmployeesList } from '../../hooks/Employees/useEmployeesList';

// Components
import { Container } from '../../components/UI/Base';
import { Table } from '../../components/UI/Table/Table';
import { FormDrawer } from '../../components/Employees';
import { Spinner } from '../../components/UI/Spinner/Spinner';
import { BreadcrumbMenu } from '../../components/UI/Breadcrumb/BreadcrumbMenu';

export const EmployeesPage = () => {
  const {
    UploadModal,
    updating,
    getBreadcrumbMenuProps,
    getEmployeesDataProps,
    getEmployeesTableProps,
    getEmployeesCrudProps,
    getFormDrawerProps,
  } = useEmployeesList();

  return (
    <Spinner spinning={updating}>
      <BreadcrumbMenu {...getBreadcrumbMenuProps()} />

      <Container>
        <Row>
          <Col span={24}>
            <Table {...getEmployeesDataProps()} {...getEmployeesTableProps()} {...getEmployeesCrudProps()} />
          </Col>
        </Row>
      </Container>

      <UploadModal />

      <FormDrawer {...getFormDrawerProps()} />
    </Spinner>
  );
};
