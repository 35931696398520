import React from 'react';
import Tooltip from 'antd/lib/tooltip';
import { Space, Tag } from 'antd';
import { useIntl } from 'react-intl';
import { AppColors } from '../../../constants/Utils/Colors';

interface ValidityTagProps {
  always?: boolean;
  isAssigned: boolean;
  isDefaultForCheckIn: boolean;
  hasVisits: boolean;
  isCheckedIn: boolean;
}

export const ValidityTag = ({
  always = false,
  isAssigned,
  isDefaultForCheckIn,
  hasVisits,
  isCheckedIn,
}: ValidityTagProps) => {
  // Intl
  const intl = useIntl();

  // Helpers
  const alwaysTag = {
    color: 'green',
    title: intl.formatMessage({ id: 'identifiers.validity.always' }),
    tooltipHeader: null,
    tooltipWarning: null,
  };
  const visitTag = {
    color: 'green',
    title: intl.formatMessage({ id: 'identifiers.validity.visit' }),
    tooltipHeader: intl.formatMessage({ id: 'identifiers.validity.visit.header' }),
    tooltipWarning: intl.formatMessage({ id: 'identifiers.validity.visit.warning' }),
  };
  const checkInTag = {
    color: 'cyan',
    title: intl.formatMessage({ id: 'identifiers.validity.checkIn' }),
    tooltipHeader: intl.formatMessage({ id: 'identifiers.validity.checkIn.header' }),
    tooltipWarning: intl.formatMessage({ id: 'identifiers.validity.checkIn.warning' }),
  };
  const inUseTag = {
    ...checkInTag,
    color: 'orange',
    title: intl.formatMessage({ id: 'identifiers.validity.inUse' }),
  };
  const noVisitsTag = {
    color: 'volcano',
    title: intl.formatMessage({ id: 'identifiers.validity.noVisits' }),
    tooltipHeader: null,
    tooltipWarning: null,
  };
  const notAssignedTag = {
    color: 'volcano',
    title: intl.formatMessage({ id: 'identifiers.validity.notAssigned' }),
    tooltipHeader: null,
    tooltipWarning: null,
  };

  const alwaysProps = always ? alwaysTag : visitTag;
  const checkInProps = isCheckedIn ? inUseTag : checkInTag;
  const assignedProps = isDefaultForCheckIn ? checkInProps : alwaysProps;
  const requiredProps = !hasVisits ? noVisitsTag : assignedProps;
  const tagProps = !isAssigned ? notAssignedTag : requiredProps;
  const { title, color, tooltipHeader, tooltipWarning } = tagProps;

  return (
    <Tooltip
      title={
        tooltipHeader &&
        tooltipWarning && (
          <Space direction="vertical">
            <span>{tooltipHeader}</span>
            <span style={{ fontSize: 12, color: AppColors.gold }}>{tooltipWarning}</span>
          </Space>
        )
      }
    >
      <Tag style={{ textAlign: 'center', minWidth: 150 }} className="gx-mb-0" color={color}>
        {title}
      </Tag>
    </Tooltip>
  );
};
