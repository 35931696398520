import { all, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

// Services
import { notificationService } from '../../services/Notifications/NotificationService';

// Models
import { SignalRAction } from '../../models/SignalRAction';

// Redux
import { validationFailed } from './Generic.redux';

// **************************************************
// ***************** VALIDATION *********************

// Worker Sagas
function* validationFailedSaga() {
  yield takeLatest(validationFailed.type, validationFailedError);
}

// Request
function validationFailedError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('app.notifications.validationError', action?.payload?.msg.message);
}

// **************************************************
// ********************* EXPORT SAGAS ***************

export default function* sagas() {
  yield all([validationFailedSaga()]);
}
