import React from 'react';
import { Avatar, Menu, MenuProps, Popover } from 'antd';
import styled from 'styled-components';

// Hooks
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../hooks/App/useAuth';
import { useAppDispatch, useAppSelector } from '../../../hooks/App/useRedux';

// Constants
import { isMobile } from '../../../constants/Settings/ThemeSettings';
import { logoutUrl, profileUrl } from '../../../constants/Utils/Urls';

// Models
import { TenantData } from '../../../models/TenantData';

// Components
import { Flex, Text } from '../../UI/Base';
import { Translated } from '../../UI/Core';
import { Spinner } from '../../UI/Spinner/Spinner';

// Redux
import { changeAccount } from '../../../store/Account/Account.redux';
import { BasicIcon } from '../../UI/Icon/BasicIcon';

// Props
interface UserMenuProps {
  tenantData: TenantData | null;
  loading: boolean;
}

// Styled
const StyledMenu = styled(Menu)`
  & .ant-menu-submenu {
    & > .ant-menu.ant-menu-sub {
      .ant-menu-item {
        padding-left: 40px !important;
      }
    }
  }
`;

export const UserMenu = ({ tenantData, loading }: UserMenuProps) => {
  // Redux
  const dispatch = useAppDispatch();
  const { width } = useAppSelector(({ appSettings }) => appSettings);
  const { updating } = useAppSelector(({ account }) => account);

  // Auth Hook
  const {
    authState: { user },
  } = useAuth();

  const navigate = useNavigate();

  const items: MenuProps['items'] = [
    tenantData?.Tenants && tenantData?.Tenants.length > 1
      ? {
          key: 'Companies',
          label: <Translated id="appMenu.tenants" />,
          icon: <BasicIcon className="icon icon-crm" style={{ marginRight: 8 }} />,
          children:
            tenantData?.Tenants.map((tenant) => ({
              key: tenant.Id,
              label: (
                <>
                  <Translated id="appMenu.goto" /> {tenant.Name}
                </>
              ),
              onClick: () =>
                dispatch(
                  changeAccount({
                    formValues: {
                      NewTenant: {
                        CompanyId: tenant.CompanyId,
                        LocationId: tenant.LocationId,
                        BusinessClientId: tenant.BusinessClientId,
                      },
                    },
                  })
                ),
            })) ?? [],
        }
      : null,

    {
      key: 'Profile',
      label: <Translated id="appMenu.account" />,
      icon: <BasicIcon className="icon icon-avatar" style={{ marginRight: 8 }} />,
      onClick: () => navigate(profileUrl),
    },

    {
      key: 'Logout',
      label: <Translated id="appMenu.logout" />,
      icon: <BasicIcon className="icon icon-signin" style={{ marginRight: 8 }} />,
      onClick: () => navigate(logoutUrl),
    },
  ];

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <Spinner spinning={updating || loading}>
        <StyledMenu
          mode="inline"
          defaultOpenKeys={[tenantData?.Id ? 'Companies' : '']}
          defaultSelectedKeys={[tenantData?.Id ?? '']}
          items={items}
        />
      </Spinner>
    </ul>
  );

  return (
    <li className="gx-user-nav">
      <Popover overlayClassName="gx-popover-horizantal" placement="topRight" content={userMenuOptions} trigger="click">
        <Flex alignItems="center">
          {!isMobile(width) && (
            <Text marginRight={8} fontSize={16} color="white">
              {user?.profile?.name}
            </Text>
          )}
          <Avatar
            src="https://as2.ftcdn.net/jpg/02/60/78/83/500_F_260788352_x5sSHM4DGvpjHj9wz8sFltzAPktQwJCj.jpg"
            className="gx-avatar gx-size-30 gx-pointer"
            alt=""
          />
        </Flex>
      </Popover>
    </li>
  );
};
