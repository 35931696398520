// Types
import { PersonType } from '../../../models/enums/PersonType';
import { SignalREvent } from '../../../models/SignalREvent';

// Actions
import { accessProfilesAssigned, accessProfilesNotAssigned } from './EmployeeAccessProfiles.redux';

// Events
const SignalREvents = {
  AccessProfilesAssigned: `AccessProfilesAssignedIntegrationEvent`,
};

// Exceptions
const SignalRExceptions = {
  AccessProfilesNotAssigned: `AccessProfilesNotAssignedIntegrationException`,
  PersonAccessProfilesNotAssigned: `PersonAccessProfilesNotAssignedIntegrationException`,
};

// Events
export const configureSignalREvents = ({ hubConnection, store, history }: SignalREvent) => {
  // Assign
  hubConnection.on(SignalREvents.AccessProfilesAssigned, (msg) => {
    if (msg.personType === PersonType.Employee) {
      store.dispatch(accessProfilesAssigned({ history, msg }));
    }
  });
  hubConnection.on(SignalRExceptions.AccessProfilesNotAssigned, (msg) => {
    if (msg.personType === PersonType.Employee) {
      store.dispatch(accessProfilesNotAssigned({ history, msg }));
    }
  });
  hubConnection.on(SignalRExceptions.PersonAccessProfilesNotAssigned, (msg) => {
    if (msg.personType === PersonType.Employee) {
      store.dispatch(accessProfilesNotAssigned({ history, msg }));
    }
  });
};
