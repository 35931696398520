import React, { useCallback, useMemo } from 'react';
import { Modal } from 'antd';
import { IdcardOutlined } from '@ant-design/icons';

import { useIntl } from 'react-intl';

import { Employee } from '../../../models/Employee';
import { AccessProfileFormValues } from '../../../models/AccessProfileFormValues';

// Hooks
import { useAppDispatch, useAppSelector } from '../../App/useRedux';
import { assignAccessProfiles } from '../../../store/Employees/EmployeeAccessProfiles/EmployeeAccessProfiles.redux';

// Props
export interface AssignConfirmProps {
  formValues: AccessProfileFormValues;
  employee: Employee;
}

// Components
const { confirm } = Modal;

// Hook
export const useEmployeeAccessProfilesReduxState = () => {
  // Intl
  const intl = useIntl();

  // Redux
  const dispatch = useAppDispatch();
  const updating = useAppSelector(({ employeeAccessProfiles }) => employeeAccessProfiles?.updating ?? false);
  const error = useAppSelector(({ employeeAccessProfiles }) => employeeAccessProfiles?.error ?? false);

  // Callbacks
  const showAssignConfirm = useCallback(
    ({ formValues, employee }: AssignConfirmProps) => {
      if (formValues) {
        confirm({
          title: intl.formatMessage({
            id: 'accessProfiles.form.confirm.assign',
          }),
          icon: <IdcardOutlined />,
          content: intl.formatMessage({
            id: 'accessProfiles.form.confirm.assignSub',
          }),
          okText: intl.formatMessage({
            id: 'app.yes',
            defaultMessage: 'Yes',
          }),
          cancelText: intl.formatMessage({
            id: 'app.no',
            defaultMessage: 'No',
          }),
          okType: 'primary',
          onOk: () => dispatch(assignAccessProfiles({ formValues, employee })),
          onCancel: () => null,
        });
      }
    },
    [dispatch, intl]
  );

  return useMemo(
    () => ({
      updating,
      error,
      showAssignConfirm,
    }),
    [updating, error, showAssignConfirm]
  );
};
