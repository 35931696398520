import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

// Services
import { ApiRequestType, apiService } from '../../../services/ApiService/ApiService';
import { notificationService } from '../../../services/Notifications/NotificationService';

// Models
import { SignalRAction } from '../../../models/SignalRAction';

// Redux
import { accessProfilesAssigned, accessProfilesNotAssigned, assignAccessProfiles } from './VisitorAccessProfiles.redux';
import { AccessProfileFormValues } from '../../../models/AccessProfileFormValues';
import { Visitor } from '../../../models/Visits/Visitor';

// **************************************************
// ********************* ASSIGN *********************

// Worker Sagas
function* assignAccessProfilesSaga() {
  yield takeEvery(assignAccessProfiles.type, assignAccessProfilesRequest);
}

function* accessProfilesAssignedSaga() {
  yield takeLatest(accessProfilesAssigned.type, assignAccessProfilesResponse);
}

function* accessProfilesNotAssignedSaga() {
  yield takeLatest(accessProfilesNotAssigned.type, assignAccessProfilesError);
}

// Request
function* assignAccessProfilesRequest(
  action: PayloadAction<{ formValues: AccessProfileFormValues; visitor: Visitor }>
) {
  try {
    const {
      payload: { visitor, formValues },
    } = action;
    yield apiService.execute({
      url: `Visitors/${visitor.Id}/AccessProfiles`,
      method: ApiRequestType.POST,
      data: formValues,
    });
  } catch ({ message }) {
    yield put({ type: accessProfilesNotAssigned.type, payload: { msg: { message } } });
  }
}

// Response
function assignAccessProfilesResponse() {
  notificationService.showSuccess('accessProfiles.notifications.assign');
}

// Error
function assignAccessProfilesError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('accessProfiles.notifications.assignFailed', action?.payload?.msg.message);
}

// **************************************************

// ********************* EXPORT SAGAS ***************

export default function* sagas() {
  yield all([
    // Assign
    assignAccessProfilesSaga(),
    accessProfilesAssignedSaga(),
    accessProfilesNotAssignedSaga(),
  ]);
}
