import React from 'react';
import { Space, Typography } from 'antd';
import { TooltipProps } from 'recharts';

// Constants
import { BaseColors } from '../../../constants/Utils/Colors';
import { toCamelCase } from '../../../constants/Utils/String';

// Components
import { Translated } from '../Core';

const { Title } = Typography;

// Props
interface VisitorMetricsProps extends TooltipProps<string, string> {
  intlId: string;
}

// Component
export const DarkTooltip = ({ active, payload, label, intlId }: VisitorMetricsProps) => {
  if (!active || !payload || !payload.length) return null;

  return (
    <Space direction="vertical" className="ant-tooltip-inner" style={{ minWidth: 120, padding: 12 }}>
      <Title level={5} className="gx-mb-0" style={{ fontWeight: 500, color: BaseColors.white }}>
        <Translated id={intlId + toCamelCase(label)} defaultMessage={label} />
      </Title>

      {[...payload].reverse().map((item) => (
        <span key={item.name}>
          <strong style={{ color: item.color }}>{item.value}</strong>{' '}
          <Translated id={intlId + toCamelCase(item.name)} defaultMessage={item.name} />
        </span>
      ))}
    </Space>
  );
};
